/* eslint-disable react/no-multi-comp */
import { setConfig } from '@elixir/fx';
import { IAuthenticationConfig } from '@elixir/auth';
import { UseQueryProvider } from '@utils';
import React from 'react';
import { StoreProvider } from '@utils/store/provider';
import { IRemoteModuleProps } from '@features/SHIntegrator';
import { ContextProvider, FlightingProvider, getEHConfig } from '@engagehub/core';
import { EngageHubOptionsProvider } from '@features/navigation/provider';
import { IEngageHubOptions } from '@features/navigation/types';
import { useLocale } from './hook';

export interface IProvidersProps extends IRemoteModuleProps {
  children: JSX.Element;
  useIntegration?: boolean;
  options?: IEngageHubOptions;
  enableLinkToEngageHubUX?: boolean;
}

const configOverrides: Partial<IAuthenticationConfig> = {
  authentication: 'none',
};

export const ContextProviderWrapper = ({
  children,
  enabled,
  customerId,
}: {
  children: JSX.Element;
  enabled?: boolean;
  customerId?: string;
}): JSX.Element =>
  enabled ? <ContextProvider customerId={customerId}>{children}</ContextProvider> : <>{children}</>;

export const Providers = ({
  locale,
  children,
  overrides,
  customerId,
  useIntegration,
  options,
  enableLinkToEngageHubUX,
}: IProvidersProps): JSX.Element => {
  const initialized = useLocale(locale);

  getEHConfig(overrides?.configBaseUrl).then(async config => {
    setConfig({
      ...config,
      authConfig: {
        ...config.authConfig,
        ...(useIntegration && configOverrides),
      },
    });
  });

  return initialized ? (
    <UseQueryProvider>
      <StoreProvider>
        <EngageHubOptionsProvider
          enableLinkToEngageHubUX={enableLinkToEngageHubUX}
          options={options}>
          <ContextProviderWrapper customerId={customerId} enabled={!enableLinkToEngageHubUX}>
            <FlightingProvider>{children}</FlightingProvider>
          </ContextProviderWrapper>
        </EngageHubOptionsProvider>
      </StoreProvider>
    </UseQueryProvider>
  ) : (
    <></>
  );
};
