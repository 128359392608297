import { IDashboardMetrics } from '@features/dashboard';

export interface IReviewDetailsResponse extends IReviewResponse {
  notes: IReviewNote[];
  metrics: IDashboardMetrics;
}

export type ICreateReviewResponse = string;
export type IValidateReviewNameResponse = boolean;

export enum ReviewState {
  Initializing = 'Initializing',
  Draft = 'Draft',
  Published = 'Published',
  Completed = 'Completed',
}

export interface IReviewResponse {
  id: string;
  templateId: string;
  name: string;
  createdBy: string;
  description: string;
  frequency: string;
  state: ReviewState;
  startDate: Date;
  endDate: Date;
  meetingDate: Date;
  createdOn: Date;
  tags: Record<string, string>;
  notesCount: number;
  updatedOn: Date;
  reviewDataParameters: Record<string, IDataParamValue>;
}

export type IDataParamValue = string | number | object | undefined;

export interface IReviewNote {
  id: string;
  text: string;
  createdBy: string;
  createdOn: Date;
  modifiedOn: Date;
}

export interface IReviewTemplateMetrics {
  id: string;
  name: string;
}

export interface IReviewDataParameters {
  WorkloadId: string;
}

export interface IReviewTemplateReviewDataParameters {
  name: string;
  type: 'string' | 'number' | 'object';
  isOptional: boolean;
}

export enum TrainingState {
  MIRP = 'MIRP',
  None = 'None',
}
export type ITemplateViewEngine = 'None' | 'Legacy' | 'OpenCanvas' | 'CaseInsights';

export interface IReviewTemplate {
  id: string;
  name: string;
  description: string;
  metrics: IReviewTemplateMetrics[];
  tags: Record<string, string>;
  reviewDataParameters: IReviewTemplateReviewDataParameters[];
  templateViewEngine: ITemplateViewEngine;
  training?: TrainingState;
}

export interface ICriticalWorkload {
  id: string;
  name: string;
  classification: string;
  createdBy: string;
  createdOn: Date;
  modifiedBy: string;
  modifiedOn: Date;
  subscriptionsCount: number;
  tags: Record<string, string>;
}

export interface IRecurrencePattern {
  patternType: RecurrencePatternType;
  weekly?: IWeeklyPattern;
  monthly?: IMonthlyPattern;
}

export interface IWeeklyPattern {
  dayOfTheWeek: DayOfWeek;
  interval: number;
}

export interface IMonthlyPattern {
  dayOfTheMonth: number;
  interval: number;
}

export enum RecurrencePatternType {
  Weekly,
  Monthly,
}

export enum DayOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export const getEnumDisplayMap = (enumType: any): { [index: string]: number } => {
  const values: { [index: string]: number } = {};

  for (const key in enumType) {
    const value = enumType[key];
    if (isNaN(key as any) && !isNaN(value)) {
      values[key] = value;
    }
  }
  return values;
};

export const MaxDaysInMonth = 31;
export const MaxAllowedFrequency = 99;
export const MinAllowedValue = 1;
export const MaxAllowedOffset = 365;
export const MaxAllowedPeriod = 999;
