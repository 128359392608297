import { ElxMessageBarType, IMessage } from '@elixir/components';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

export interface IUseMessagingResult {
  standardErrorMessage: IMessage;
  onError: (error: AxiosError) => void;
}

const generateMessage = (t: TFunction, traceId: string | undefined): string =>
  traceId
    ? `${t('Main.StandardErrorMessage')} ${t('Main.TraceId')} ${traceId}`
    : t('Main.StandardErrorMessage');

export const useMessaging = (): IUseMessagingResult => {
  const { t } = useTranslation();
  const [traceId, setTraceId] = useState<string | undefined>();
  return {
    standardErrorMessage: {
      type: ElxMessageBarType.error,
      message: generateMessage(t, traceId),
    },
    onError: (error: AxiosError): void => {
      const opId = (error as AxiosError<{ operationId: string }>).response?.data?.operationId;
      setTraceId(opId ? opId.split('-')[1] : undefined);
    },
  };
};
