import { fetchAsync } from '@engagehub/core';
import { AxiosError } from 'axios';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { adminApiVersion } from '@utils/store/constants';
import { ICreateCustomerProcessFields } from './createCustomerProcess';

export const useCreateCustomerProcess = (): UseMutationResult<
  unknown,
  AxiosError,
  ICreateCustomerProcessFields,
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(
    (values: ICreateCustomerProcessFields) =>
      fetchAsync(`/br-admin/review-processes/create`, {
        method: 'POST',
        headers: {
          'x-eh-scope': values.targetScope,
        },
        params: {
          'api-version': adminApiVersion,
        },
        data: {
          ...values,
        },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(['create-customer-process']),
    },
  );
};
