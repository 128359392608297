import { useSelectCustomerId } from '@engagehub/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { AnyObject } from 'yup/lib/object';
import { useUniqueName } from './hooks';
import { selectReview } from './store/hook';
import { IDataParamValue } from './types';

/*eslint max-lines-per-function: ["error", 200]*/
export const schema = (): yup.ObjectSchema<AnyObject> => {
  const { t } = useTranslation();
  const customerId = useSelectCustomerId();
  const review = useSelector(selectReview);
  let uniqueNameTimer: NodeJS.Timeout | null = null;
  return yup.object().shape({
    name: yup
      .string()
      .required(t('ReviewLanding.ErrorReviewNameRequired'))
      .max(100, t('ReviewLanding.ErrorMaxCharAllowed'))
      .test('unique-review-name-validation', t('ReviewLanding.ErrorReviewNameNotUnique'), value => {
        if (uniqueNameTimer) {
          clearTimeout(uniqueNameTimer);
        }
        return new Promise(resolve => {
          uniqueNameTimer = setTimeout(() => {
            const isUnique = useUniqueName(value, customerId, review?.name);
            resolve(isUnique);
          }, 500); // Wait for 0.5 second before invoking useUniqueName
        });
      }),
    // type: yup.string().required(t('ReviewLanding.ErrorReviewTypeRequired')),
    startDate: yup.date().when('isRecurring', {
      is: false,
      then: yup.date().required(t('ReviewLanding.ErrorStartDateRequired')),
    }),
    endDate: yup.date().when('isRecurring', {
      is: false,
      then: yup
        .date()
        .required(t('ReviewLanding.ErrorEndDateRequired'))
        .test(
          'endDateRangeValidation',
          t('ReviewLanding.ErrorEndDateRangeValidation'),
          (value, ctx) => (value !== undefined ? value >= ctx.parent.startDate : true),
        ),
    }),
    description: yup.string().max(1000, t('ReviewLanding.ErrorMaxCharAllowed')),
    meetingDate: yup.date().when('isRecurring', {
      is: false,
      then: yup
        .date()
        .required(t('ReviewLanding.ErrorMeetingDateRequired'))
        .test(
          'meetingDateRangeValidation',
          t('ReviewLanding.ErrorMeetingDateRangeValidation'),
          (value, ctx) => (value !== undefined ? value > ctx.parent.endDate : true),
        )
        .min(
          new Date(new Date().toDateString()),
          t('ReviewLanding.ErrorMeetingTodaysDateValidation'),
        ),
    }),
    // dayOfTheWeek: yup.string().when('isRecurring', {
    //   is: true,
    //   then: yup
    //     .string()
    //     .test(
    //       'dayOfTheWeekValidation',
    //       t('ReviewLanding.ErrorDayOfTheWeekRequired'),
    //       (value, ctx) =>
    //         ctx.parent.recurrenceType === 'Weekly' ? value !== undefined && value !== '' : true,
    //     ),
    // }),
    // dayOfTheMonth: yup.number().when('isRecurring', {
    //   is: true,
    //   then: yup
    //     .number()
    //     .test('dayOfTheMonthValidation', t('ReviewLanding.ErrorDayOfTheMonth'), (value, ctx) =>
    //       ctx.parent.recurrenceType === 'Monthly'
    //         ? value != undefined && value <= 31 && value > 0
    //         : true,
    //     ),
    // }),
    // reviewPeriod: yup.number().when('isRecurring', {
    //   is: true,
    //   then: yup
    //     .number()
    //     .test(
    //       'reviewPeriodValidation',
    //       t('ReviewLanding.ErrorReviewPeriod'),
    //       value => value != undefined && value > 0,
    //     ),
    // }),
    // daysToPrepare: yup.number().when('isRecurring', {
    //   is: true,
    //   then: yup
    //     .number()
    //     .test(
    //       'daysToPrepareValidation',
    //       t('ReviewLanding.ErrorDaysToPrepare'),
    //       value => value != undefined && value >= 0,
    //     ),
    // }),
    // reviewStartDateOffset: yup.number().when('isRecurring', {
    //   is: true,
    //   then: yup
    //     .number()
    //     .test(
    //       'reviewStartDateOffsetValidation',
    //       t('ReviewLanding.ErrorReviewStartDateOffset'),
    //       value => value != undefined && value >= 0,
    //     ),
    // }),
  });
};

export interface IEditReviewFields {
  id: string;
  name: string;
  frequency: string;
  startDate: string;
  endDate: string;
  description: string;
  meetingDate: string;
  attendees: string;
  isRecurring: boolean;
  recurrenceType: string;
  dayOfTheWeek: string;
  weeklyInterval: number;
  monthlyInterval: number;
  monthlyDaysMode: string;
  dayOfTheMonth: number;
  reviewStartDateOffset: number;
  reviewPeriod: number;
  daysToPrepare: number;
  templateId: string;
  workloadId?: string;
  ReviewDataParameters?: Record<string, IDataParamValue>;
}
