import React from 'react';
import { EHDialog } from '@components/EHDialog';
import { useTranslation } from 'react-i18next';
import { useReviewId } from '@features/reviews/hooks';
import { clickTracking } from '@utils';
import { usePublishReview } from '../hooks';

interface IDialogPublish {
  open: boolean;
  onClick: (value: boolean) => void;
}

export const DialogPublish = ({ open, onClick }: IDialogPublish): JSX.Element => {
  const { t } = useTranslation();
  const id = useReviewId();
  const mutate = usePublishReview();

  return (
    <EHDialog
      cancelButtonProps={{
        text: t('ReviewLanding.Cancel'),
        onClick: (): void => {
          clickTracking('businessreview-review-publishcanceldialog', 'Cancel');
          onClick(false);
        },
      }}
      contentTitle={t('ReviewLanding.PublishReview')}
      hidden={!open}
      primaryButtonProps={{
        text: t('ReviewLanding.PublishReview'),
        onClick: (): void => {
          clickTracking('businessreview-review-publishdialog', 'Publish');
          !!id && mutate(id);
          onClick(false);
        },
      }}>
      <div>
        <div>{t('ReviewLanding.PublishLine1')}</div>
      </div>
    </EHDialog>
  );
};
