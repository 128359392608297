/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useId } from '@elixir/components';
import { useField } from 'formik';

interface IFieldWrapper {
  readonly name: string;
  readonly children?: any;
}

export const EHFieldWrapper = ({ name, children }: IFieldWrapper): JSX.Element => {
  const [field, meta, helpers] = useField(name);
  const id = useId();

  return <div className={'sh-input'}>{children({ id, meta, helpers, ...field })}</div>;
};
