import React from 'react';
import { IVerticalStackedChartProps, VerticalStackedBarChart } from '@fluentui/react-charting';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { useWindowWidth } from '@utils';
import { IMetricProps } from '.';

export const VerticalBar = ({ data, getLabelColor }: IMetricProps): JSX.Element => {
  const chartData: IVerticalStackedChartProps[] = [];
  data?.forEach(outageResult => {
    const column: IVerticalStackedChartProps = {
      xAxisPoint: outageResult.label,
      chartData: [],
    };
    outageResult.outagesResultItems.forEach(item => {
      column.chartData.push({
        color: getLabelColor?.(item.label) ?? DefaultPalette.blue,
        data: item.value,
        legend: item.label,
      });
    });
    chartData.push(column);
  });

  const resize = useWindowWidth();
  return <VerticalStackedBarChart data={chartData} showXAxisLablesTooltip={true} width={resize} />;
};
