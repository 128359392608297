/* eslint-disable max-lines-per-function */
import { EHPanel } from '@components/EHPanel';
import { MessageBarType, PanelSize } from '@elixir/components';
import React, { Dispatch, createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMessaging } from '@utils/messaging';
import { AxiosError } from 'axios';
import { useEditTask } from './hooks';
import { ICreateTaskFields, ISeverityField, TaskForm } from '../form';
import { ActionItemsContext, IActionItemsContext } from '../component';
import { IWorkItemResponse } from '../api';

export interface IEditTaskContext {
  setError: Dispatch<boolean>;
}

export const EditTaskContext = createContext<IEditTaskContext>({} as IEditTaskContext);

export const EditTask = ({
  data,
  setEditItem,
}: {
  data: IWorkItemResponse | undefined;
  setEditItem: Dispatch<IWorkItemResponse | undefined>;
}): JSX.Element => {
  const { t } = useTranslation();
  const { mutate, isLoading, isError } = useEditTask();
  const [isOpen, setIsOpen] = useState(false);
  const { setMessage } = useContext<IActionItemsContext>(ActionItemsContext);
  const [error, setError] = useState(false);
  const context: IEditTaskContext = { setError };
  const { standardErrorMessage, onError } = useMessaging();
  const message = { ...standardErrorMessage };

  useEffect(() => {
    setIsOpen(!!data);
  }, [data]);

  useEffect(() => {
    setError(isError);
  }, [isError]);

  const onSubmit = (values: ICreateTaskFields): void => {
    mutate(values, {
      onSuccess: () => {
        setMessage({
          message: t('ActionItems.TaskUpdatedSuccessfully'),
          type: MessageBarType.success,
        });
        close();
      },
      onError: error => {
        onError(error as AxiosError);
      },
    });
  };

  const close = (): void => {
    setIsOpen(false);
    setEditItem(undefined);
  };

  const item: ICreateTaskFields = {
    id: data?.id ?? '',
    title: data?.title ?? '',
    description: data?.description ?? '',
    severity: (data?.severity ?? '') as ISeverityField,
    estimatedCompletionDate: '',
    assignedTo: data?.assignedTo ?? '',
    workItemType: data?.type ?? '',
    state: data?.status ?? '',
  };

  return (
    <EditTaskContext.Provider value={context}>
      <EHPanel
        headerText={t('ActionItems.EditTask')}
        isLoading={isLoading}
        isOpen={isOpen}
        message={error ? message : undefined}
        onDismiss={(): void => close()}
        size={PanelSize.medium}>
        {
          <TaskForm
            fieldValues={item}
            onCancel={(): void => {
              setIsOpen(false);
              setEditItem(undefined);
            }}
            onSubmit={onSubmit}
            setError={setError}
          />
        }
      </EHPanel>
    </EditTaskContext.Provider>
  );
};
