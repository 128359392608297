/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-lines-per-function */
import { ICultureItem } from '@serviceshub/x-types';

export const getLanguageCultures = (defaultCulture: string): Record<string, ICultureItem> => {
  const languageData: Record<string, ICultureItem> = {
    'en-US': {
      name: 'English (United States)',
      description: 'United States - English',
      resourceCenterCode: 'en-US',
      englishDescription: 'United States - English',
    },
    'de-DE': {
      name: 'Deutsch (Deutschland)‎',
      description: 'Deutschland - Deutsch',
      resourceCenterCode: 'de-DE',
      englishDescription: 'Germany - German',
    },
    'fr-FR': {
      name: 'Français (France)‎',
      description: 'France - Français',
      resourceCenterCode: 'fr-FR',
      englishDescription: 'France - French',
    },
    'es-ES': {
      name: 'Español (España)‎',
      description: 'España - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Spain - Spanish',
    },
    'it-IT': {
      name: 'Italiano (Italia)‎',
      description: 'Italia - Italiano',
      resourceCenterCode: 'it-IT',
      englishDescription: 'Italy - Italian',
    },
    'ja-JP': {
      name: '日本語 (日本)',
      description: '日本 - 日本語',
      resourceCenterCode: 'ja-JP',
      englishDescription: 'Japan - Japanese',
    },
    'ko-KR': {
      name: '한국어 (대한민국)',
      description: '대한민국 - 한국어',
      resourceCenterCode: 'ko-KR',
      englishDescription: 'Korea - Korean',
    },
    'pt-BR': {
      name: 'Português (Brasil)',
      description: 'Brasil - Português',
      resourceCenterCode: 'pt-BR',
      englishDescription: 'Brazil - Portuguese',
    },
    'ru-RU': {
      name: 'Русский (Россия)',
      description: 'Россия - Русский',
      resourceCenterCode: 'ru-RU',
      englishDescription: 'Russia - Russian',
    },
    'zh-HK': {
      name: '繁體中文 (香港特別行政區)',
      description: '香港特別行政區 - 繁體中文',
      resourceCenterCode: 'zh-HK',
      englishDescription: 'Hong Kong SAR - Chinese',
    },
    'zh-TW': {
      name: '繁體中文 (台灣)',
      description: '台灣 - 繁體中文',
      resourceCenterCode: 'zh-TW',
      englishDescription: 'Taiwan - Chinese',
    },
    'zh-CN': {
      name: '简体中文 (中国)‎',
      description: '中国 - 简体中文',
      resourceCenterCode: 'zh-CN',
      englishDescription: 'China - Chinese',
    },
    'lt-LT': {
      name: 'Lietuvių (Lietuva)',
      description: 'Lietuva - Lietuvių',
      resourceCenterCode: 'lt-LT',
      englishDescription: 'Lithuania - Lithuanian',
    },
    'lv-LV': {
      name: 'Latviešu (Latvija)',
      description: 'Latvija – Latviešu',
      resourceCenterCode: 'lv-LV',
      englishDescription: 'Latvia - Latvian',
    },
    'bg-BG': {
      name: 'Български (България)',
      description: 'България - Български',
      resourceCenterCode: 'bg-BG',
      englishDescription: 'Bulgaria - Bulgarian',
    },
    'hr-HR': {
      name: 'Hrvatski (Hrvatska)',
      description: 'Hrvatska - Hrvatski',
      resourceCenterCode: 'hr-HR',
      englishDescription: 'Croatia - Croatian',
    },
    'ro-RO': {
      name: 'Română (România)',
      description: 'România - Română',
      resourceCenterCode: 'ro-RO',
      englishDescription: 'Romanian',
    },
    sr_Latn_RS: {
      name: 'Srpski (Srbija)',
      description: 'Srbija - Srpski',
      resourceCenterCode: 'en-US',
      englishDescription: 'Serbia - Serbian',
    },
    'sl-SI': {
      name: 'Slovenščina (Slovenija)',
      description: 'Slovenija - Slovenščina',
      resourceCenterCode: 'sl-SI',
      englishDescription: 'Slovenia - Slovenian',
    },
    'si-LK': {
      name: 'සිංහල (ශ්‍රී ලංකා)',
      description: 'ශ්‍රී ලංකා - සිංහල',
      resourceCenterCode: 'en-US',
      englishDescription: 'Sri Lanka - Sinhala',
    },
    'id-ID': {
      name: 'Bahasa Indonesia (Indonesia)',
      description: 'Indonesia - Bahasa Indonesia',
      resourceCenterCode: 'id-ID',
      englishDescription: 'Indonesia - Indonesian',
    },
    'vi-VN': {
      name: 'Tiếng việt (Việt Nam)',
      description: 'Việt Nam - Tiếng việt',
      resourceCenterCode: 'vi-VN',
      englishDescription: 'Vietnam - Vietnamese',
    },
    // Arabic (Saudi Arabia)
    'ar-SA': {
      name: '(العربية (المملكة العربية السعودية',
      description: 'المملكة العربية السعودية - العربية',
      resourceCenterCode: 'ar-SA',
      englishDescription: 'Saudi Arabia - Arabic',
    },
    // Czech (Czech Republic)
    'cs-CZ': {
      name: 'Čeština (Česká Republika)',
      description: 'Česká Republika - Čeština',
      resourceCenterCode: 'cs-CZ',
      englishDescription: 'Czech Republic - Czech',
    },
    // Greek (Greece)
    'el-GR': {
      name: 'Ελληνικά (Ελλάδα)',
      description: 'Ελλάδα - Ελληνικά',
      resourceCenterCode: 'el-GR',
      englishDescription: 'Greece - Greek',
    },
    // Filipino (Philippines)
    'fil-PH': {
      name: 'Pilipino (Pilipinas)',
      description: 'Pilipinas - Pilipino',
      resourceCenterCode: 'en-US',
      englishDescription: 'Philippines - Filipino',
    },
    // Hebrew (Israel)
    'he-IL': {
      name: 'עברית (ישראל)',
      description: 'ישראל - עברית',
      resourceCenterCode: 'he-IL',
      englishDescription: 'Israel - Hebrew',
    },
    // Hungarian (Hungary)
    'hu-HU': {
      name: 'Magyar (Magyarország)',
      description: 'Magyarország - Magyar',
      resourceCenterCode: 'hu-HU',
      englishDescription: 'Hungary - Hungarian',
    },
    // Malay (Malaysia)
    'ms-MY': {
      name: 'Bahasa Melayu (Malaysia)',
      description: 'Malaysia - Bahasa Melayu',
      resourceCenterCode: 'ms-MY',
      englishDescription: 'Malaysia - Malay',
    },
    // Dutch (Netherlands)
    'nl-NL': {
      name: 'Nederlands (Nederland)',
      description: 'Nederland - Nederlands',
      resourceCenterCode: 'nl-NL',
      englishDescription: 'Netherlands - Dutch',
    },
    // Polish (Poland)
    'pl-PL': {
      name: 'Polski (Polska)',
      description: 'Polska - Polski',
      resourceCenterCode: 'pl-PL',
      englishDescription: 'Poland - Polish',
    },
    // Swedish (Sweden)
    'sv-SE': {
      name: 'Svenska (Sverige)',
      description: 'Sverige - Svenska',
      resourceCenterCode: 'sv-SE',
      englishDescription: 'Sweden - Swedish',
    },
    // 	Thai (Thailand)
    'th-TH': {
      name: 'ไทย (ไทย)',
      description: 'ไทย - ไทย',
      resourceCenterCode: 'th-TH',
      englishDescription: 'Thailand - Thai',
    },
    // Turkish
    'tr-TR': {
      name: 'Türkçe (Türkiye)',
      description: 'Türkiye - Türkçe',
      resourceCenterCode: 'tr-TR',
      englishDescription: 'Turkey - Turkish',
    },
    // Arabic (Egypt)
    'ar-EG': {
      name: 'العربية (مصر)',
      description: 'مصر - العربية',
      resourceCenterCode: 'ar-SA',
      englishDescription: 'Egypt - Arabic',
    },
    // Arabic (Libya)
    'ar-LY': {
      name: 'العربية (ليبيا)',
      description: 'ليبيا - العربية',
      resourceCenterCode: 'ar-SA',
      englishDescription: 'Libya - Arabic',
    },
    // Dutch (Belgium)
    'nl-BE': {
      name: 'Nederlands (België )',
      description: 'België - Nederlands',
      resourceCenterCode: 'nl-BE',
      englishDescription: 'Belgium - Dutch',
    },
    // English (Australia)
    'en-AU': {
      name: 'English (Australia)',
      description: 'Australia - English',
      resourceCenterCode: 'en-AU',
      englishDescription: 'Australia - English',
    },
    // English (Canada)
    'en-CA': {
      name: 'English (Canada)',
      description: 'Canada - English',
      resourceCenterCode: 'en-CA',
      englishDescription: 'Canada - English',
    },
    // English (UK)
    'en-GB': {
      name: 'English (United Kingdom)',
      description: 'United Kingdom - English',
      resourceCenterCode: 'en-GB',
      englishDescription: 'United Kingdom - English',
    },
    // English (New Zealand)
    'en-NZ': {
      name: 'English (New Zealand)',
      description: 'New Zealand - English',
      resourceCenterCode: 'en-NZ',
      englishDescription: 'New Zealand - English',
    },
    // French (Belgium)
    'fr-BE': {
      name: 'Français (Belgique)',
      description: 'Belgique - Français',
      resourceCenterCode: 'fr-BE',
      englishDescription: 'Belgium - French',
    },
    // French (Canada)
    'fr-CA': {
      name: 'Français (Canada)',
      description: 'Canada - Français',
      resourceCenterCode: 'fr-CA',
      englishDescription: 'Canada - French',
    },
    // French (Switzerland)
    'fr-CH': {
      name: 'Français (Suisse)',
      description: 'Suisse - Français',
      resourceCenterCode: 'fr-CH',
      englishDescription: 'Switzerland - French',
    },
    // French (Algeria)
    'fr-DZ': {
      name: 'Français (Algeria)',
      description: 'Algeria - Français',
      resourceCenterCode: 'fr-FR',
      englishDescription: 'Algeria - French',
    },
    // French (Morocco)
    'fr-MA': {
      name: 'Français (Maroc)',
      description: 'Maroc - Français',
      resourceCenterCode: 'fr-FR',
      englishDescription: 'Morocco - French',
    },
    // French (French Polynesia)
    'fr-PF': {
      name: 'Français (Polynésie Français)',
      description: 'Polynésie Français-Français',
      resourceCenterCode: 'fr-FR',
      englishDescription: 'Polynesia - French',
    },
    // French (Tunisia)
    'fr-TN': {
      name: 'Français (Tunisie)',
      description: 'Tunisie - Français',
      resourceCenterCode: 'fr-FR',
      englishDescription: 'Tunisia - French',
    },
    // German (Austria)
    'de-AT': {
      name: 'Deutsch (Österreich)',
      description: 'Österreich - Deutsch',
      resourceCenterCode: 'de-AT',
      englishDescription: 'Austria - German',
    },
    // German (Switzerland)
    'de-CH': {
      name: 'Deutsch (Suisse)',
      description: 'Suisse - Deutsch',
      resourceCenterCode: 'de-CH',
      englishDescription: 'Switzerland - German',
    },
    // Portuguese (Portugal)
    'pt-PT': {
      name: 'Português (Portugal)',
      description: 'Portugal - Português',
      resourceCenterCode: 'pt-PT',
      englishDescription: 'Portugal - Portuguese',
    },
    // Spanish (Argentina)
    'es-AR': {
      name: 'Español (Argentina)',
      description: 'Argentina - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Argentina - Spanish',
    },
    // Spanish (Bolivia)
    'es-BO': {
      name: 'Español (Bolivia)',
      description: 'Bolivia - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Bolivia - Spanish',
    },
    // Spanish (Chile)
    'es-CL': {
      name: 'Español (Chile)',
      description: 'Chile - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Chile - Spanish',
    },
    // Spanish (Colombia)
    'es-CO': {
      name: 'Español (Colombia)',
      description: 'Colombia - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Colombia - Spanish',
    },
    // Spanish (Costa Rica)
    'es-CR': {
      name: 'Español (Costa Rica)',
      description: 'Costa Rica - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Costa Rica - Spanish',
    },
    // Spanish (Dominican Republic)
    'es-DO': {
      name: 'Español (República Dominicana)',
      description: 'República Dominicana - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Dominican Republic - Spanish',
    },
    // Spanish (Ecuador)
    'es-EC': {
      name: 'Español (Ecuador)',
      description: 'Ecuador - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Ecuador - Spanish',
    },
    // Spanish (Guatemala)
    'es-GT': {
      name: 'Español (Guatemala)',
      description: 'Guatemala - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Guatemala - Spanish',
    },
    // Spanish (Honduras)
    'es-HN': {
      name: 'Español (Honduras)',
      description: 'Honduras - Español',
      resourceCenterCode: defaultCulture,
      englishDescription: 'Honduras - Spanish',
    },
    // Spanish (Mexico)
    'es-MX': {
      name: 'Español (México)',
      description: 'México - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Mexico - Spanish',
    },
    // Spanish (Nicaragua)
    'es-NI': {
      name: 'Español (Nicaragua)',
      description: 'Nicaragua - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Nicaragua - Spanish',
    },
    // Spanish (Panama)
    'es-PA': {
      name: 'Español (Panamá)',
      description: 'Panamá - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Panama - Spanish',
    },
    // Spanish (Peru)
    'es-PE': {
      name: 'Español (Perú)',
      description: 'Perú - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Peru - Spanish',
    },
    // Spanish (Puerto Rico)
    'es-PR': {
      name: 'Español (Puerto Rico)',
      description: 'Puerto Rico - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Puerto Rico - Spanish',
    },
    // Spanish (Uruguay)
    'es-UY': {
      name: 'Español (Uruguay)',
      description: 'Uruguay - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Uruguay - Spanish',
    },
    // Spanish (Venezuela)
    'es-VE': {
      name: 'Español (Venezuela)',
      description: 'Venezuela - Español',
      resourceCenterCode: 'es-ES',
      englishDescription: 'Venezuela - Spanish',
    },
    // Afrikaans (South Africa)
    'af-ZA': {
      name: 'Afrikaans (Suid-Afrika)',
      description: 'Suid-Afrika - Afrikaans',
      resourceCenterCode: defaultCulture,
      englishDescription: 'South Africa - Afrikaans',
    },
    // Bangla (Bangladesh)
    'bn-BD': {
      name: 'বাংলা (বাংলাদেশ)',
      description: 'বাংলাদেশ - বাংলা',
      resourceCenterCode: defaultCulture,
      englishDescription: 'Bangladesh - Bengali',
    },
    // Bangla (India)
    'bn-IN': {
      name: 'বাংলা (ভারত)',
      description: 'ভারত - বাংলা',
      resourceCenterCode: defaultCulture,
      englishDescription: 'India - Bengali',
    },
    // Bosnian (Bosnia)
    'bs-Latn-BA': {
      name: 'Bosanski (Bosna i Hercegovina)',
      description: 'Bosna i Hercegovina – Bosanski',
      resourceCenterCode: 'bs-Latn-BA',
      englishDescription: 'Bosnia - Bosnian',
    },
    // Catalan (Andorra)
    'ca-AD': {
      name: 'Català (Andorra)',
      description: 'Andorra - Català',
      resourceCenterCode: 'ca-ES',
      englishDescription: 'Andorra - Catalan',
    },
    // Welsh (United Kingdom)
    'cy-GB': {
      name: 'Cymraeg (Y Deyrnas Unedig)',
      description: 'Y Deyrnas Unedig - Cymraeg',
      resourceCenterCode: defaultCulture,
      englishDescription: 'United Kingdom - Welsh',
    },
    // Danish (Denmark)
    'da-DK': {
      name: 'Dansk (Danmark)',
      description: 'Danmark - Dansk',
      resourceCenterCode: 'da-DK',
      englishDescription: 'Denmark - Danish',
    },
    // Estonian (Estonia)
    'et-EE': {
      name: 'Eesti (Eesti)',
      description: 'Eesti - Eesti',
      resourceCenterCode: 'et-EE',
      englishDescription: 'Estonia - Estonian',
    },
    // Persian (Iran)
    'fa-IR': {
      name: 'فارسی (پرشیا)‏',
      description: 'پرشیا -فارسی',
      resourceCenterCode: defaultCulture,
      englishDescription: 'Iran - Farsi',
    },
    // Finnish (Finland)
    'fi-FI': {
      name: 'Suomi (Suomi)',
      description: 'Suomi - Suomi',
      resourceCenterCode: 'fi-FI',
      englishDescription: 'Finland - Finnish',
    },
    // Hindi (India)
    'hi-IN': {
      name: 'हिंदी (इंडिया)',
      description: 'इंडिया - हिंदी',
      resourceCenterCode: 'hi-IN',
      englishDescription: 'India - Hindi',
    },
    // Icelandic (Iceland)
    'is-IS': {
      name: 'Íslenska (Ísland)',
      description: 'Ísland - Íslenska',
      resourceCenterCode: 'is-IS',
      englishDescription: 'Iceland - Icelandic',
    },
    // Maltese (Malta)
    'mt-MT': {
      name: 'Malti (Malta)',
      description: 'Malta - Malti',
      resourceCenterCode: 'mt-MT',
      englishDescription: 'Malta - Maltese',
    },
    // Norwegian (Norway)
    'nb-NO': {
      name: 'Bokmål (Norge)',
      description: 'Norge - Norsk',
      resourceCenterCode: 'nb-NO',
      englishDescription: 'Norway - Norwegian',
    },
    // Slovak (Slovakia)
    'sk-SK': {
      name: 'Slovenčina (Slovensko)',
      description: 'Slovensko - Slovenčina',
      resourceCenterCode: 'sk-SK',
      englishDescription: 'Slovakia - Slovak',
    },
    // Kiswahili (Tanzania)
    'sw-TZ': {
      name: 'Kiswahili (Tanzania)',
      description: 'Tanzania - Kiswahili',
      resourceCenterCode: defaultCulture,
      englishDescription: 'Tanzania - Kiswahili',
    },
    // Tamil (India)
    'ta-IN': {
      name: 'தமிழ் (இந்தியா)',
      description: 'இந்தியா - தமிழ்',
      resourceCenterCode: defaultCulture,
      englishDescription: 'India - Tamil',
    },
    // Telugu (India)
    'te-IN': {
      name: 'తెలుగు (భారతదేశం)',
      description: 'భారతదేశం - తెలుగు',
      resourceCenterCode: defaultCulture,
      englishDescription: 'India - Telugu',
    },
    // Ukrainian (Ukraine)
    'uk-UA': {
      name: 'Українська (Україна)',
      description: 'Україна - Українська',
      resourceCenterCode: 'uk-UA',
      englishDescription: 'Ukraine - Ukrainian',
    },
    // Urdu (India)
    'ur-IN': {
      name: 'اردو (ہندوستان)‏',
      description: 'ہندوستان - اردو',
      resourceCenterCode: defaultCulture,
      englishDescription: 'India - Urdu',
    },
    // Malagasy (Madagascar)
    'mg-MG': {
      name: 'Nomenay (Madagasikara)',
      description: 'Madagasikara - Nomenay',
      resourceCenterCode: defaultCulture,
      englishDescription: 'Madagascar - Malagasy',
    },
    // Tongan (Tonga)
    'to-TO': {
      name: 'Lea faka-Tonga (Tonga)',
      description: 'Tonga - Lea faka-Tonga',
      resourceCenterCode: defaultCulture,
      englishDescription: 'Tonga - Tongan',
    },
  };

  return languageData;
};
