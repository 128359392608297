import {
  IRawStyle,
  ScreenWidthMinLarge,
  ScreenWidthMinMedium,
  ScreenWidthMinSmall,
  ScreenWidthMinXLarge,
  ScreenWidthMinXXLarge,
  ScreenWidthMinXXXLarge,
} from '@fluentui/react';
import { DeepReadonly } from '@serviceshub/x-types';
import { IResponsiveBreakpointsConfig } from './types';

export const ScreenWidthMinXLargePlus = 1200;

const getMinWidthMediaSelector = (minWidth: number): string => `@media (min-width: ${minWidth}px)`;
const getMaxWidthMediaSelector = (maxWidth: number): string => `@media (max-width: ${maxWidth}px)`;

export const getResponsiveBreakPointsStyle = (
  config: DeepReadonly<IResponsiveBreakpointsConfig>,
): IRawStyle => ({
  selectors: {
    [getMinWidthMediaSelector(ScreenWidthMinSmall)]: config.minWidthSmall,
    [getMinWidthMediaSelector(ScreenWidthMinMedium)]: config.minWidthMedium,
    [getMinWidthMediaSelector(ScreenWidthMinLarge)]: config.minWidthLarge,
    [getMinWidthMediaSelector(ScreenWidthMinXLarge)]: config.minWidthXLarge,
    [getMinWidthMediaSelector(ScreenWidthMinXXLarge)]: config.minWidthXXLarge,
    [getMinWidthMediaSelector(ScreenWidthMinXXXLarge)]: config.minWidthXXXLarge,
    [getMaxWidthMediaSelector(ScreenWidthMinSmall)]: config.maxWidthSmall,
    [getMaxWidthMediaSelector(ScreenWidthMinMedium)]: config.maxWidthMedium,
    [getMaxWidthMediaSelector(ScreenWidthMinLarge)]: config.maxWidthLarge,
    [getMaxWidthMediaSelector(ScreenWidthMinXLarge)]: config.maxWidthXLarge,
    [getMaxWidthMediaSelector(ScreenWidthMinXXLarge)]: config.maxWidthXXLarge,
    [getMaxWidthMediaSelector(ScreenWidthMinXXXLarge)]: config.maxWidthXXXLarge,
  },
});
