import React, { createContext, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { translationNamespace } from '@locales';
import {
  //ActionItemsViewer,
  OperationalReviewTable,
  useQueryReviewFilters,
  useSelectContext,
  useSelectCustomerName,
  useSelectWriteOperationEnabled,
} from '@features';
import { EHContainer, IContainerContext, breadCrumbStyle, useContainerScope } from '@components';
import { NavigationHeader } from '@features/navigation/header';
import { NoAccessTryNewUX } from '@features/navigation/noAccess';
import { useSelector } from 'react-redux';
import { selectEnableLinkToEngageHubUX } from '@features/navigation/store';
import { usePush } from '@utils/routing/hooks';
import { IContainerAction } from '@elixir/components';
import { EHRoutes } from '@utils/routing/routes';

export * from './config';

export enum TabStatus {
  REVIEWS = '1',
  ACTION_ITEMS = '2',
  DASHBOARD = '3',
}

export const OperationalReviewContext = createContext<IContainerContext>({} as IContainerContext);

export const OperationalReviewPage = memo((): JSX.Element => {
  const { t } = useTranslation(translationNamespace);
  const containerCtx = useContainerScope();
  const customerName = useSelectCustomerName();
  const context = useSelectContext();
  const enableRedirection = useSelector(selectEnableLinkToEngageHubUX);
  const { data: filters, isLoading: isFiltersLoading } = useQueryReviewFilters();

  const count = enableRedirection && !context?.isLoading ? 0 : filters?.count ?? '__';
  const push = usePush();
  const isWriteOperationEnabled = useSelectWriteOperationEnabled();

  const createReviewButton: IContainerAction = {
    key: '1',
    text: t('OperationalReview.CreateReview'),
    primary: true,
    onClick: () => push(EHRoutes.createReview),
    disabled: !isWriteOperationEnabled ?? true,
    id: 'businessreview-review-createreview',
  };

  useEffect(() => {
    const actions = enableRedirection ? [] : [createReviewButton];

    containerCtx.setActions(actions);
  }, [isWriteOperationEnabled, enableRedirection]);

  useEffect(
    () => containerCtx.setLoading(isFiltersLoading && !enableRedirection, 'filters'),
    [isFiltersLoading, enableRedirection],
  );

  const items = [
    {
      itemKey: TabStatus.REVIEWS,
      headerText: t('OperationalReview.Reviews', { countTxt: count }),
    },
    // {
    //   itemKey: TabStatus.ACTION_ITEMS,
    //   headerText: t('ActionItems.OpenActionItems'),
    // },
  ];

  return (
    <>
      <NavigationHeader />
      <EHContainer
        actions={containerCtx.actions}
        headerText={customerName ?? ' '}
        pivotProps={{
          items,
          //onItemSelect: (selectedKey: string): void => setTab(selectedKey as TabStatus),
        }}
        scope={containerCtx.scope}
        styles={breadCrumbStyle}>
        <OperationalReviewContext.Provider value={containerCtx}>
          {!enableRedirection && context?.token && <OperationalReviewTable />}
          {enableRedirection && !context?.isLoading && <NoAccessTryNewUX />}
        </OperationalReviewContext.Provider>
      </EHContainer>
    </>
  );
});

OperationalReviewPage.displayName = 'OperationalReview';
