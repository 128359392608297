/* eslint-disable max-lines-per-function */
import { EHNote } from '@components';
import { NoteState } from '@components/EHNote/types';
import React from 'react';
import { useReviewId } from '@features/reviews';
import { useTaskActivity } from './hook';

export const TaskDetailsActivity = ({
  workItemId,
}: {
  workItemId: string | undefined;
}): JSX.Element => {
  const reviewId = useReviewId();
  const data = useTaskActivity(workItemId, reviewId);

  return (
    <>
      {data?.map((activity, i) => (
        <EHNote
          createdBy={activity.updatedBy}
          createdOn={activity.updatedOn}
          initialState={NoteState.VIEW}
          key={i}
          role="form"
          text={activity.text}
        />
      ))}
    </>
  );
};
