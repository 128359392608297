import { FormikHelpers } from 'formik';

export enum NoteState {
  CREATE = '1',
  EDIT = '2',
  VIEW = '3',
}

export interface INoteComment {
  readonly text: string;
}

export interface IFormProps {
  isValid: boolean;
  dirty: boolean;
}

export interface INote {
  readonly id?: string;
  readonly text?: string;
  readonly createdBy?: string;
  readonly createdOn?: string | Date;
  readonly modifiedOn?: Date;
  readonly role?: React.AriaRole;
  readonly currentUser?: string;
  readonly initialState?: NoteState;
  readonly useRTEditor?: boolean;
  readonly onDelete?: (id: string) => void;
  readonly onSubmit?: (
    values: INoteComment,
    formikHelpers: FormikHelpers<INoteComment>,
  ) => void | Promise<INoteComment>;
}
