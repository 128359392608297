import React, { memo, useContext } from 'react';
import { EHTable } from '@components/EHTable';
import { IContainerContext } from '@components';
import { ReviewDetailsPanel } from '@features';
import { OperationalReviewContext } from '@pages';
import { actions, columns } from './columns';
import { useQueryReviewFilters, useQueryReviews, useUpdateReviews } from './hooks';

export * from './hooks';

export const OperationalReviewTable = memo((): JSX.Element => {
  const data = useQueryReviews();
  const { data: filters } = useQueryReviewFilters();
  const updateReviews = useUpdateReviews();
  const mutate = updateReviews;
  const ctx = useContext<IContainerContext>(OperationalReviewContext);

  return (
    <>
      <EHTable
        actions={actions()}
        columns={columns()}
        enableServerSideSorting={true}
        initialSort={{
          fieldName: 'updatedOn',
          isSortedDescending: true,
        }}
        isFixedHeader={true}
        items={data || []}
        maxHeight={600}
        maxListSize={filters?.count}
        onChange={(p): void => mutate(p)}
        pageSize={5}
      />
      <ReviewDetailsPanel ctx={ctx} />
    </>
  );
});

OperationalReviewTable.displayName = 'OperationalReviewTable';
