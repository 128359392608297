import { Stack } from '@fluentui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RedirectToEngageHub } from './redirect';
import { RenderBreadcrumbHeader } from './renderBreadcrumbHeader';
import { selectEnableLinkToEngageHubUX } from './store';

export const NavigationHeader = (): JSX.Element => {
  const enableRedirection = useSelector(selectEnableLinkToEngageHubUX);
  return (
    <Stack horizontal>
      <RenderBreadcrumbHeader />
      {enableRedirection && <RedirectToEngageHub />}
    </Stack>
  );
};
