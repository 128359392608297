import { useSelector } from 'react-redux';
import { Dispatch, useEffect } from 'react';
import { IElixirState } from '@elixir/fx';
import { businessReviewSelector } from '@utils/store/selector';
import { IReviewState } from './store';
import { IReviewDetailsResponse } from '../types';

export const selectReviewState = (state: IElixirState): IReviewState | undefined =>
  businessReviewSelector(state).reviews;

export const selectReview = (state: IElixirState): IReviewDetailsResponse | undefined =>
  selectReviewState(state)?.review;

export const useReviewSelector = (): IReviewDetailsResponse | undefined =>
  useSelector(selectReview);

export const selectReviewLoading = (state: IElixirState): boolean =>
  !!selectReviewState(state)?.isLoading;

export const selectReviewError = (state: IElixirState): Error | undefined =>
  selectReviewState(state)?.error;

export const selectReviewDetails = (state: IElixirState): boolean =>
  !!selectReviewState(state)?.isReviewDetailsPanelOpen;

export const reviewDetailsStateSelector = (): boolean => useSelector(selectReviewDetails);

export interface ICombinedReviewContext {
  readonly setLoading: Dispatch<boolean>;
  readonly setStandardError?: (isError: boolean, name?: string) => void;
  readonly setError?: Dispatch<boolean>;
}

export const useReview = (ctx: ICombinedReviewContext): IReviewDetailsResponse | undefined => {
  const review = useSelector(selectReview);
  const isLoading = useSelector(selectReviewLoading);
  const isError = useSelector(selectReviewError);

  useEffect(() => {
    ctx.setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    isError && ctx.setStandardError && ctx?.setStandardError(true, 'Review:useReviewError');
    isError && ctx.setError && ctx?.setError(!!isError);
  }, [isError]);

  return review;
};
