/* istanbul ignore file */
/* eslint-disable max-lines-per-function */
import { EHCommandBar, EHForm, EHTextField } from '@components';
import { ICommandBarItemProps } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { EHTextEditor } from '@components/EHForms/EHRichTextEditor';
import { clickTracking } from '@utils';
import { useInsertLinkFix } from '@components/EHRichTextEditor/hook';
import { IFormProps, INote, INoteComment, NoteState } from '../types';

interface IUpdateNote extends Partial<INote> {
  readonly state: NoteState;
  readonly onReset: () => void;
  readonly onCancel: () => void;
}

export const UpdateNote = ({
  state,
  text,
  id,
  useRTEditor,
  onSubmit,
  onCancel,
  onReset,
}: IUpdateNote): JSX.Element => {
  const { t } = useTranslation();

  const validationSchema = (): yup.ObjectSchema<AnyObject> =>
    yup.object().shape({
      text: yup
        .string()
        .required(t('Main.Required'))
        .max(50000, t('ReviewLanding.ErrorMaxCharAllowed')),
    });

  const initialValues = {
    text: state === NoteState.CREATE ? '' : text,
    ...(state === NoteState.EDIT ? { id } : {}),
  };

  const sendButton = (dirty: boolean, isValid: boolean): ICommandBarItemProps[] => [
    {
      ariaLabel: t('Main.Submit'),
      key: 'submit',
      iconProps: { iconName: 'Send' },
      text: t('Main.Submit'),
      disabled: !dirty || !isValid,
      onClick: (): void => clickTracking('businessreview-review-notecreate', 'Send'),
      type: 'submit',
    },
  ];

  const updateGroup = (dirty: boolean, isValid: boolean): ICommandBarItemProps[] => [
    {
      ariaLabel: t('Main.Cancel'),
      key: 'Cancel',
      text: t('Main.Cancel'),
      type: 'submit',
      onClick: (): void => {
        clickTracking('businessreview-review-notecancelupdate', 'Cancel');
        onCancel();
      },
    },
    {
      ariaLabel: t('Main.Update'),
      key: 'update',
      text: t('Main.Update'),
      disabled: !dirty || !isValid,
      onClick: (): void => clickTracking('businessreview-review-noteupdate', 'Update'),
      type: 'submit',
    },
  ];

  useInsertLinkFix();

  return (
    <EHForm
      initialValues={initialValues}
      onReset={onReset}
      onSubmit={onSubmit as (values: INoteComment) => Promise<INoteComment>}
      validationSchema={validationSchema}>
      {({ isValid, dirty }: IFormProps): JSX.Element => (
        <>
          {useRTEditor ? (
            <EHTextEditor ariaLabel={t('OperationalReview.UpdateNote')} name="text" />
          ) : (
            <EHTextField maxLength={2000} multiline={true} name="text" rows={3} />
          )}
          <EHCommandBar
            farItems={
              state === NoteState.CREATE ? sendButton(dirty, isValid) : updateGroup(dirty, isValid)
            }
            items={[]}
          />
        </>
      )}
    </EHForm>
  );
};
