import React from 'react';
import { ITheme, ScreenWidthMinUhfMobile, Stack, mergeStyleSets, useTheme } from '@fluentui/react';
import classnames from 'classnames';

interface IStyles {
  container?: string;
  item?: string;
  component?: string;
}

const getLayoutStyles = (
  theme: ITheme,
  internal: boolean,
  responsiveBrakePoint: number,
): IStyles => {
  return mergeStyleSets({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      selectors: {
        [`@media(max-width: ${responsiveBrakePoint}px)`]: {
          justifyContent: 'none',
        },
      },
    },
    item: {
      paddingTop: internal ? 0 : theme.spacing.s1,
      width: `calc(50% - ${theme.spacing.s1})`,
      selectors: {
        [`@media(max-width: ${responsiveBrakePoint}px)`]: {
          width: '100%',
        },
      },
    },
    component: {
      width: '100%',
    },
  });
};

interface IEHLayout {
  /** Components */
  readonly items: Array<JSX.Element | null>;
  /** Layout used inside another Layout */
  readonly internal?: boolean;
  /** Max screen width to brake the line */
  readonly responsiveBrakePoint?: number;
  /** classNames={{ container: mergeStyles({ marginTop: '50px' }) }} */
  readonly classNames?: IStyles;
}

export const EHLayout = ({
  items,
  internal = false,
  responsiveBrakePoint = ScreenWidthMinUhfMobile,
  classNames,
}: IEHLayout): JSX.Element => {
  const layoutStyles = getLayoutStyles(useTheme(), internal, responsiveBrakePoint);

  return (
    <div className={classnames(layoutStyles.container, classNames?.container)}>
      {items.filter(Boolean).map((comp: JSX.Element | null, i: number) => (
        <Stack className={classnames(layoutStyles.item, classNames?.item)} horizontal key={i}>
          <div className={classnames(layoutStyles.component, classNames?.component)}>{comp}</div>
        </Stack>
      ))}
    </div>
  );
};
