import React from 'react';
import { Shimmer, mergeStyles } from '@fluentui/react';

const wrapperClass = mergeStyles({
  color: 'transparent',
  selectors: {
    '& > .ms-Shimmer-container': {
      margin: '4px 0',
    },
    '.ms-ShimmerLine-root': {
      height: '22px',
    },
  },
});

export const EHFieldShimmer = (): JSX.Element => {
  return (
    <div className={wrapperClass}>
      <Shimmer width="20%" />
      <Shimmer />
    </div>
  );
};
