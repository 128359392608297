/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { EHTable } from '@components';
import { Stack } from '@fluentui/react';
import { IElxColumn, ITableAction } from '@elixir/components';
import { IPreconfiguredActionItem, useQueryPreconfiguredActionItems } from '../hook';
import { ActionItemContext, IActionItemContext } from '../actionItems';

export const ActionItemList = (): JSX.Element => {
  const { items, setItems, setEditIndex } = React.useContext<IActionItemContext>(ActionItemContext);
  const actionItems = useQueryPreconfiguredActionItems();

  useEffect(() => {
    actionItems && setItems(actionItems);
  }, [actionItems]);

  const columns = (): IElxColumn[] => {
    return [
      {
        key: 'title',
        name: 'Title',
        fieldName: 'title',
        minWidth: 100,
        isResizable: true,
      },
      {
        key: 'description',
        name: 'Description',
        fieldName: 'description',
        minWidth: 100,
        isResizable: true,
      },
      {
        key: 'severity',
        name: 'Severity',
        fieldName: 'severity',
        minWidth: 100,
        isResizable: true,
      },
      {
        key: 'assignedTo',
        name: 'Assigned To',
        fieldName: 'assignedTo',
        minWidth: 100,
        isResizable: true,
      },
      {
        key: 'workItemType',
        name: 'Work Item Type',
        fieldName: 'workItemType',
        minWidth: 150,
        isResizable: true,
      },
    ];
  };

  const actions = (): ITableAction[] => {
    return [
      {
        key: 'edit',
        text: 'Edit',
        onAction: (item: IPreconfiguredActionItem): void => setEditIndex(items.indexOf(item)),
        iconProps: { iconName: 'Edit' },
      },
      {
        key: 'delete',
        text: 'Delete',
        onAction: (item: IPreconfiguredActionItem): void => {
          const index = items.indexOf(item);
          if (index !== -1) {
            setItems(items.filter(i => i != items[index]));
          }
        },
        iconProps: { iconName: 'Trash' },
      },
    ];
  };

  return (
    <Stack tokens={{ childrenGap: 16, padding: 24 }}>
      <EHTable
        actions={actions()}
        columns={columns()}
        isFixedHeader={true}
        items={items}
        maxListSize={0}
      />
    </Stack>
  );
};
