import { IRawStyle, ITheme, mergeStyleSets, useTheme } from '@fluentui/react';
import { IElxContainerStyles } from '@elixir/components';
import { DeepPartial } from '@reduxjs/toolkit';

export const getHeaderStyleForLevel = (headingLevel: number | undefined): IRawStyle => {
  const theme = useTheme();
  switch (headingLevel) {
    case undefined:
    case 1:
      return {
        fontSize: `${theme.fonts.xxLargePlus.fontSize} !important`,
        fontWeight: '400 !important',
      };
    case 2:
      return { fontSize: '16px !important' };
  }
  return {};
};

interface IContainerStyles {
  subContainer: DeepPartial<IElxContainerStyles>;
}

const getContainerStyles = (): IContainerStyles => ({
  subContainer: {
    root: {
      position: 'relative',
    },
    headerComponent: {
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      marginTop: 0,
    },
    body: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
});

export const containerStyles = getContainerStyles();

interface IStyles {
  message?: string;
}

export const getMessageStyles = (theme: ITheme): IStyles => {
  return mergeStyleSets({
    message: {
      selectors: {
        ['@media (forced-colors: active)']: {
          color: `${theme.palette.white} !important`,
        },
      },
    },
  });
};
