import React from 'react';
import { IToggleProps, mergeStyles } from '@fluentui/react';
import classnames from 'classnames';
import { EHToggle } from '@components/EHToggle';

const themeToggleStyles = mergeStyles({
  marginBottom: 0,
});

export const ActionToggle = (props: IToggleProps): JSX.Element => {
  return <EHToggle {...props} className={classnames(props.className, themeToggleStyles)} />;
};
