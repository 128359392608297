import React from 'react';
import { Stack, Text } from '@fluentui/react';
import { dayjs } from '@utils';

interface IReviewsDates {
  readonly date: Date;
  readonly userName?: string;
}

export const CreatedInfoColumn = ({ date }: IReviewsDates): JSX.Element => {
  return (
    <Stack>
      <Text variant="medium">{dayjs(date).format('L')}</Text>
      {/* <Text variant="smallPlus">{userName}</Text> */}
    </Stack>
  );
};
