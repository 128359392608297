import { IBreadcrumbItem, IBreadcrumbStyles, ITheme, useTheme } from '@fluentui/react';
import React from 'react';
import { EHBreadcrumb } from '@components';
import { useTranslation } from 'react-i18next';
import * as routing from '@utils/routing';
import { useLocation } from 'react-router-dom';
import { useReviewId } from '@features/reviews/hooks';
import { useReviewSelector } from '@features/reviews/store/hook';

export interface IEHBreadcrumbItem extends IBreadcrumbItem {
  'aria-label': string;
  key: 'home' | 'engagement' | 'reviews' | 'createnewreview' | 'reviewlandingpage';
}

const useDefaultBreadCrumbs = (enableOperationalReviewLink: boolean): IEHBreadcrumbItem[] => {
  const { t } = useTranslation();
  const replace = routing.useReplace();

  return [
    { 'aria-label': t('Main.Home'), text: t('Main.Home'), key: 'home' },
    { 'aria-label': t('Main.Engagement'), text: t('Main.Engagement'), key: 'engagement' },
    {
      'aria-label': t('Main.Reviews'),
      id: 'businessreview-breadcrumb-reviews',
      text: t('Main.Reviews'),
      key: 'reviews',
      onClick: !enableOperationalReviewLink
        ? undefined
        : (): void => replace(routing.EHRoutes.operationalReview),
    },
  ];
};
const useBreadCrumbItems = (): IEHBreadcrumbItem[] => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const items = useDefaultBreadCrumbs(pathname !== routing.EHRoutes.operationalReview);

  const reviewId = useReviewId();
  const review = useReviewSelector();

  switch (pathname) {
    case routing.EHRoutes.createReview:
      items.push({
        'aria-label': t('ReviewLanding.CreateNewReview'),
        text: t('ReviewLanding.CreateNewReview'),
        key: 'createnewreview',
      });
      break;
    case routing.getReviewLandingRoute(reviewId ?? ''):
      if (review)
        items.push({
          'aria-label': review.name,
          text: review.name,
          key: 'reviewlandingpage',
        });
      break;
    default:
      break;
  }

  return items;
};

const getStyles = (theme: ITheme): Partial<IBreadcrumbStyles> => ({
  list: {
    height: '18px',
  },
  root: {
    margin: 0,
    padding: `${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.s1}`,
    backgroundColor: theme.semanticColors.menuBackground,
  },
});

export const RenderBreadcrumbHeader = (): JSX.Element => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const items = useBreadCrumbItems();
  return <EHBreadcrumb items={items} styles={styles} />;
};
