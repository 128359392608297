import { useEHConfig } from '@engagehub/core';
import { IOneDSContent, IOverrideEventDataValues } from '@serviceshub/x-types';
import { OneDSHelper } from '@serviceshub/x-web-core';

type ICapturePageActionType = (
  element: Element,
  overrideActionData?: IOverrideEventDataValues,
) => void;

type ICaptureContentUpdateType = (oneDSContent: IOneDSContent) => void;

export const useOneDS = (): [ICapturePageActionType, ICaptureContentUpdateType] => {
  const config = useEHConfig();

  if (!OneDSHelper.isInitialized()) {
    // initialize the helper
    const helper = new OneDSHelper();

    // TODO: Add extra props to provide context i.e. userID
    if (config && config.OneDsInstrumentationKey)
      helper.setupOneDS(config.OneDsInstrumentationKey, {});
    else {
      // Session will initially be undefined until it's loaded.
      // Shouldn't affect real scenario, since actions shouldn't be used until app is loaded and actions can be taken by user.
      // In future we could improve this by using RxJs
      const warningFn = (): void => {
        console.warn('OneDS is not initialized!');
      };
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return [warningFn, warningFn];
    }
  }

  return [
    OneDSHelper.capturePageAction.bind(OneDSHelper),
    OneDSHelper.captureContentUpdate.bind(OneDSHelper),
  ];
};

export const clickTracking = (id: string, cN: string): void => {
  try {
    OneDSHelper.capturePageAction(document.createElement('div'), {
      content: { id, cN },
    });
  } catch {
    console.warn('OneDS is not initialized!');
  }
};
