import React from 'react';
import { CommandBar, ICommandBarProps } from '@fluentui/react';

const commandBarStyles = {
  root: {
    padding: 'unset',
    background: 'transparent',
  },
};

export const EHCommandBar = (props: ICommandBarProps): JSX.Element => {
  return <CommandBar {...props} styles={Object.assign(commandBarStyles, props.styles)} />;
};
