import { EHNote } from '@components';
import { INoteComment, NoteState } from '@components/EHNote/types';
import { IComment } from '@features/actionItems/api';
import {
  useSelectMaskedUser,
  useSelectUserContext,
  useSelectWriteOperationEnabled,
} from '@features/context';
import { useReviewId } from '@features/reviews';
import { FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { useCreateWorkItemComments, useGetWorkItemComments } from './hooks';

export interface IWorkItemDetailNotes {
  readonly workItemId: string | undefined;
  readonly onNotesLoaded?: (notes: IComment[]) => void;
}

export const WorkItemDetailNotes = (props: IWorkItemDetailNotes): JSX.Element => {
  const reviewId = useReviewId();
  const mutate = useCreateWorkItemComments(props.workItemId, reviewId);
  const { data: notes, isLoading } = useGetWorkItemComments(props.workItemId, reviewId);
  const userCtx = useSelectUserContext();
  const user = useSelectMaskedUser();
  const isWriteEnabled = useSelectWriteOperationEnabled();

  const onSubmit = (comment: INoteComment, { resetForm }: FormikHelpers<INoteComment>): void => {
    /* istanbul ignore next */
    mutate({ request: { content: comment.text, createdBy: userCtx?.upn ?? '' }, resetForm });
  };

  useEffect(() => {
    if (!isLoading) {
      props.onNotesLoaded?.(notes ?? []);
    }
  }, [isLoading]);

  return (
    <>
      {isWriteEnabled && (
        <EHNote
          createdBy={user}
          initialState={NoteState.CREATE}
          onSubmit={onSubmit}
          role="form"
          useRTEditor={true}
        />
      )}
      {notes?.map((note, i) => (
        <EHNote
          createdBy={note.createdBy}
          createdOn={note.createdOn}
          initialState={NoteState.VIEW}
          key={i}
          role="form"
          text={note.text}
          useRTEditor={true}
        />
      ))}
    </>
  );
};
