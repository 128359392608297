import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';
import { i18n } from '@locales';
import { ActionToggle } from './Toggle';

interface IShellActions {
  darkMode: boolean;
  setDarkMode: (prop: boolean) => void;
}

const actionStyles = { root: { display: 'inline-block' } };

export const ShellActions = ({ darkMode, setDarkMode }: IShellActions): JSX.Element => {
  const [locale, setLocale] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Stack styles={actionStyles}>
      <Stack horizontal>
        <ActionToggle
          checked={darkMode}
          offText={t('Main.LightTheme')}
          onChange={(): void => setDarkMode(!darkMode)}
          onText={t('Main.DarkTheme')}
        />
        <ActionToggle
          checked={locale}
          offText="EN"
          onChange={(): void => {
            setLocale(!locale);
            i18n.changeLocale(locale ? 'en-US' : 'es-ES');
          }}
          onText="ES"
        />
      </Stack>
    </Stack>
  );
};
