import React from 'react';
import {
  ElxTagContainer,
  IElxTagContainerProps,
  TagContainerOrientation,
} from '@elixir/components';

interface IEHTagContainer extends Omit<IElxTagContainerProps, 'displayOrientation'> {
  displayOrientation?: TagContainerOrientation;
}

const listItemStyle = { addButtonRoot: { margin: 0 } };

export const EHTagContainer = (props: IEHTagContainer): JSX.Element => {
  return (
    <ElxTagContainer
      displayOrientation={TagContainerOrientation.Vertical}
      {...props}
      styles={props.data.length == 1 ? Object.assign(listItemStyle, props.styles) : props.styles}
    />
  );
};
