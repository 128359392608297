import { IReviewContext, ReviewContext } from '@pages';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface IReviewEditActions {
  setCancelConfirm: (o: boolean) => void;
  submitForm: () => void;
  isValid: boolean;
  dirty: boolean;
}

export const useReviewEditActions = ({
  setCancelConfirm,
  submitForm,
  isValid,
  dirty,
}: IReviewEditActions): void => {
  const { t } = useTranslation();
  const { setActions } = useContext<IReviewContext>(ReviewContext);

  useEffect(() => {
    setActions([
      {
        id: 'businessreview-review-editsavechanges',
        key: 'editsave',
        text: t('ReviewLanding.SaveChanges'),
        type: 'submit',
        onClick: submitForm,
        disabled: !dirty || !isValid,
      },
      {
        id: 'businessreview-review-editcancelsavechanges',
        key: 'cancel',
        text: t('ReviewLanding.Cancel'),
        onClick: (): void => setCancelConfirm(true),
      },
    ]);
  }, [isValid, dirty]);
};
