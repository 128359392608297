import { EHFieldDropdown } from '@components';
import { MaskedUserValues } from '@features/context';
// import { EHPeoplePicker } from '@components/EHForms/EHPeoplePicker';
// import { useAssignedToUsers } from '@features/actionItems/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function AssignedToDropdown(): JSX.Element {
  // const [searchTerm, setSearchTem] = useState('');
  // const users = useAssignedToUsers(searchTerm, props.usePeoplePicker);
  const { t } = useTranslation();

  return (
    <EHFieldDropdown
      id="CreateTask-Assigned"
      label={t('ActionItems.Assigned')}
      name="assignedTo"
      options={[
        { key: MaskedUserValues.Customer, text: t('OperationalReview.Customer') },
        { key: MaskedUserValues.Microsoft, text: MaskedUserValues.Microsoft },
      ]}
      placeholder={t('ActionItems.SelectAssignee')}
      required
    />
  );

  // return props.usePeoplePicker ? (
  //   <EHPeoplePicker
  //     id="CreateTask-Assigned"
  //     isLoading={users.isLoading && users.fetchStatus !== 'idle'}
  //     itemLimit={1}
  //     items={users.data ?? []}
  //     label={t('ActionItems.Assigned')}
  //     name="assignedTo"
  //     onSearch={(value): void => setSearchTem(value)}
  //     placeholder={t('ActionItems.Unassigned')}
  //     required
  //   />
  // ) : (
  //   <EHFieldDropdown
  //     id="CreateTask-Assigned"
  //     label={t('ActionItems.Assigned')}
  //     name="assignedTo"
  //     options={dropdownOptions}
  //     placeholder={t('ActionItems.SelectStatus')}
  //     required
  //   />
  // );
}
