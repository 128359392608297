import { EHContainer, IContainerContext, containerStyles } from '@components';
import { IRawStyle } from '@fluentui/react';
import React, { createContext } from 'react';
import { useActionItemActions } from './actions';
import { TaskListContainer } from './taskList';

export type IActionItemsContext = IContainerContext;

export const ActionItemsContext = createContext<IActionItemsContext>({} as IActionItemsContext);

export interface IActionItemsViewerProps {
  containerCtx: IContainerContext;
}

const styles = {
  ...containerStyles.subContainer,
  root: {
    ...(containerStyles.subContainer.root as IRawStyle),
    display: 'contents',
  },
};

export const ActionItemsViewer = ({ containerCtx }: IActionItemsViewerProps): JSX.Element => {
  const defaultSubActions = useActionItemActions();

  return (
    <ActionItemsContext.Provider value={containerCtx}>
      <EHContainer styles={styles} subActions={[...defaultSubActions]}>
        <TaskListContainer />
      </EHContainer>
    </ActionItemsContext.Provider>
  );
};
