import { IPagedResult } from '@utils';
import { UseQueryResult, useQuery } from 'react-query';
import { ICriticalWorkload } from '@features';
import { useContext, useEffect } from 'react';
import { IReviewContext, ReviewContext } from '@pages';
import { fetchAsync, useSelectCustomerId } from '@engagehub/core';
import { useTranslation } from 'react-i18next';
import { ElxMessageBarType } from '@elixir/components';
import { constructCustomerScope } from '@utils/scope';

// Note: Using a large page size will work for the vast majority. In the future we may implement paging for edge cases.
// See #17725922 for discussion
const workloadQueryPageSize = 200;

export const useWorkloadOptions = (
  enableWorkloadQuery: boolean,
): Partial<UseQueryResult<ICriticalWorkload[]>> => {
  const { t } = useTranslation();
  const { setMessage } = useContext<IReviewContext>(ReviewContext);
  const customerId = useSelectCustomerId();

  const { isError, ...rest } = useQuery(
    ['workload'],
    () =>
      fetchAsync<IPagedResult<ICriticalWorkload>>(`/cw/workloads/query`, {
        method: 'POST',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
        data: {
          take: workloadQueryPageSize,
        },
      }).then(resp => resp.values),
    {
      enabled: !!customerId && enableWorkloadQuery,
      staleTime: Infinity,
    },
  );

  const standardMessage = {
    type: ElxMessageBarType.error,
    message: t('Main.StandardErrorMessage'),
    onDismiss: (): void => setMessage(undefined),
  };

  useEffect(() => {
    isError && setMessage(standardMessage);
  }, [isError]);
  return { ...rest };
};
