import React, { Dispatch, createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCustomerId } from '@engagehub/core';
import { getCustomerId } from '@utils/scope';
import { ActionItemForm } from './form';
import { IPreconfiguredActionItem } from './hook';
import { ActionItemList } from './list';
import { TemplateForm } from './template';

export interface IActionItemContext {
  targetScope: string;
  setTargetScope: Dispatch<string>;
  reviewType: string;
  setReviewType: Dispatch<string>;
  templateId: string;
  setTemplateId: Dispatch<string>;
  items: IPreconfiguredActionItem[];
  setItems: Dispatch<IPreconfiguredActionItem[]>;
  editIndex: number;
  setEditIndex: Dispatch<number>;
}

export const ActionItemContext = createContext<IActionItemContext>({} as IActionItemContext);

export const ConfigureActionItems = (): JSX.Element => {
  const [targetScope, setTargetScope] = useState('');
  const [reviewType, setReviewType] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [items, setItems] = useState<IPreconfiguredActionItem[]>([]);
  const [editIndex, setEditIndex] = useState(-1);
  const dispatch = useDispatch();

  useEffect(() => {
    if (targetScope) dispatch(setCustomerId(getCustomerId(targetScope)));
  }, [targetScope]);

  const context: IActionItemContext = {
    targetScope,
    setTargetScope,
    reviewType,
    setReviewType,
    templateId,
    setTemplateId,
    items,
    setItems,
    editIndex,
    setEditIndex,
  };

  return (
    <ActionItemContext.Provider value={context}>
      <TemplateForm />
      {templateId && (
        <>
          <ActionItemForm />
          <ActionItemList />
        </>
      )}
    </ActionItemContext.Provider>
  );
};
