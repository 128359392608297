import { ITheme, Label, Text, getTheme } from '@fluentui/react';
import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { ElxHtml } from '@elixir/fx/lib/components/Html/ElxHtml';

interface IEHFormLabelProps {
  label: string | undefined;
  value: string | undefined;
  isRichTextEditor?: boolean;
}

interface IStyles {
  headerLabel: string;
}

const getStyles = (theme: ITheme): IStyles =>
  mergeStyleSets({
    headerLabel: {
      color: theme.semanticColors.inputPlaceholderText,
      fontWeight: 600,
    },
  });

const styles = getStyles(getTheme());

export const EHFormLabel = ({
  label,
  value,
  isRichTextEditor = false,
}: IEHFormLabelProps): JSX.Element => {
  return (
    <div>
      <Label className={styles.headerLabel}>{label}</Label>
      {isRichTextEditor ? <ElxHtml content={value as string} /> : <Text>{value}</Text>}
    </div>
  );
};
