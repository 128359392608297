/* eslint-disable max-lines-per-function */
import { EHPanel } from '@components/EHPanel';
import { MessageBarType, PanelSize } from '@elixir/components';
import React, { Dispatch, createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EHActionButton } from '@components';
import { useMessaging } from '@utils/messaging';
import { useReviewId } from '@features/reviews';
import { useSelectWriteOperationEnabled } from '@features/context';
import { AxiosError } from 'axios';
import { useSubmitTask } from './hooks';
import { ICreateTaskFields, TaskForm } from '../form';
import { ActionItemsContext, IActionItemsContext } from '../component';

export interface ICreateTaskContext {
  setError: Dispatch<boolean>;
}

export const CreateTaskContext = createContext<ICreateTaskContext>({} as ICreateTaskContext);

export const CreateTask = (): JSX.Element => {
  const { t } = useTranslation();
  const reviewId = useReviewId();
  const { mutate, isLoading, isError } = useSubmitTask(reviewId);
  const [isOpen, setIsOpen] = useState(false);
  const { setMessage } = useContext<IActionItemsContext>(ActionItemsContext);
  const [error, setError] = useState(false);
  const context: ICreateTaskContext = { setError };
  const { standardErrorMessage, onError } = useMessaging();
  const message = {
    ...standardErrorMessage,
    onDismiss: (): void => setError(false),
  };
  const isEnabled = useSelectWriteOperationEnabled();

  useEffect(() => {
    setError(isError);
  }, [isError]);

  const onSubmit = (values: ICreateTaskFields): void => {
    mutate(values, {
      onSuccess: () => {
        setMessage({
          message: t('ActionItems.TaskCreatedSuccessfully'),
          type: MessageBarType.success,
        });
        setIsOpen(false);
      },
      onError: error => {
        onError(error as AxiosError);
      },
    });
  };

  return (
    <CreateTaskContext.Provider value={context}>
      <EHActionButton
        disabled={!isEnabled}
        iconProps={{ iconName: 'EditNote' }}
        id="businessreview-actionitem-createtask"
        key={1}
        onClick={(): void => setIsOpen(true)}
        text={t('ActionItems.CreateTask')}
      />
      <EHPanel
        headerText={t('ActionItems.CreateTask')}
        isLoading={isLoading}
        isOpen={isOpen}
        message={error ? message : undefined}
        onDismiss={(): void => setIsOpen(false)}
        size={PanelSize.medium}>
        {
          <TaskForm
            onCancel={(): void => setIsOpen(false)}
            onSubmit={onSubmit}
            setError={setError}
          />
        }
      </EHPanel>
    </CreateTaskContext.Provider>
  );
};
