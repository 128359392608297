import { useSelector } from 'react-redux';
import { FontWeights, Stack, Text } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ElxPrimaryButton } from '@elixir/components';
import { selectEngageHubOptions } from './store';

export const NoAccessTryNewUX = (): JSX.Element => {
  const { t } = useTranslation();
  const options = useSelector(selectEngageHubOptions);

  return (
    <Stack style={{ margin: 'auto' }}>
      <Text style={{ fontWeight: FontWeights.semibold, whiteSpace: 'pre-line' }} variant="medium">
        {t('Main.NoAccessLinkToNewExperience')}
      </Text>
      <ElxPrimaryButton
        href={options?.redirectUrl}
        style={{ margin: '20px auto', width: 'fit-content' }}
        target="_blank"
        text={t('Main.TryTheNewExperience')}
      />
    </Stack>
  );
};
