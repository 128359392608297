import { IResult } from '@utils';
import { IWorkItemActivityCount, useReviewId } from '@features';
import { useQuery } from 'react-query';
import { fetchAsync, useSelectCustomerId } from '@engagehub/core';
import { constructCustomerScope } from '@utils/scope';

export const useWorkItemDetailsCounts = (
  workItemId: string | undefined,
): IWorkItemActivityCount | undefined => {
  const customerId = useSelectCustomerId();
  const reviewId = useReviewId();
  const endpointUrl = `/br/processes/reviews/${reviewId}/work-items/${workItemId}/taskDetails/count`;
  const { data } = useQuery(
    ['taskDetailsCounts', { workItemId }],
    () =>
      fetchAsync<IResult<IWorkItemActivityCount>>(endpointUrl, {
        method: 'GET',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
      }).then(resp => resp?.value),
    {
      enabled: !!workItemId,
      staleTime: Infinity,
    },
  );
  return data;
};
