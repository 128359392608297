import { EHRoutes, IResult, ISetResult, usePush } from '@utils';
import {
  UseMutateFunction,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ICreateReviewResponse, IReviewTemplate, useReviewId } from '@features';
import { ElxMessageBarType } from '@elixir/components';
import { IReviewContext, ReviewContext } from '@pages';
import { EHMessageBarButton } from '@components';
import { useDispatch } from 'react-redux';
import { fetchAsync, useSelectCustomerId } from '@engagehub/core';
import { useMessaging } from '@utils/messaging';
import { AxiosError } from 'axios';
import { constructCustomerScope } from '@utils/scope';
import { reloadReview } from '../store/store';
import { invalidateReviewQueries } from '../query';

export const usePublishReview = (): UseMutateFunction<
  IResult<string>,
  unknown,
  string,
  unknown
> => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const push = usePush();
  const customerId = useSelectCustomerId();
  const { setLoading, setMessage } = useContext<IReviewContext>(ReviewContext);
  const reviewId = useReviewId();
  const { standardErrorMessage, onError } = useMessaging();
  const message = {
    ...standardErrorMessage,
    onDismiss: (): void => setMessage(undefined),
  };

  const { isLoading, isError, mutate } = useMutation(
    async (reviewId: string) =>
      fetchAsync<IResult<ICreateReviewResponse>>(`/br/processes/reviews/${reviewId}/publish`, {
        method: 'POST',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
      }),
    {
      onSuccess: () => {
        invalidateReviewQueries(queryClient, customerId);
        dispatch(reloadReview(reviewId as string));
        setMessage({
          type: ElxMessageBarType.success,
          message: t('ReviewLanding.SuccessPublishMessage'),
          onDismiss: (): void => setMessage(undefined),
          actions: (
            <EHMessageBarButton
              id="businessreview-review-gotooperationreview"
              onClick={(): void => push(EHRoutes.operationalReview)}
              text={t('ReviewLanding.GoToOperationalReviews')}
            />
          ),
          isMultiline: false,
        });
      },
      onError: error => {
        onError(error as AxiosError);
      },
    },
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    isError && setMessage(message);
  }, [isError]);

  return mutate;
};

export const useReviewTemplates = (): Partial<UseQueryResult<IReviewTemplate[]>> => {
  const { setMessage } = useContext<IReviewContext>(ReviewContext);
  const customerId = useSelectCustomerId();
  const { standardErrorMessage, onError } = useMessaging();
  const message = {
    ...standardErrorMessage,
    onDismiss: (): void => setMessage(undefined),
  };

  const { isError, ...rest } = useQuery(
    ['template'],
    () =>
      fetchAsync<ISetResult<IReviewTemplate>>(`/br/processes/templates/query`, {
        method: 'POST',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: { customerId },
        data: {},
      }).then(resp => resp.values),
    {
      enabled: !!customerId,
      // Do not automatically update templates...
      staleTime: Infinity,
      onError: error => {
        onError(error as AxiosError);
      },
    },
  );

  useEffect(() => {
    isError && setMessage(message);
  }, [isError]);

  return { ...rest };
};
