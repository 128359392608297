import React, { useContext, useMemo } from 'react';
import { EHNote } from '@components/EHNote';
import { ReviewState, useSelectMaskedUser, useSelectWriteOperationEnabled } from '@features';
import { dayjs } from '@utils';
import { INoteComment, NoteState } from '@components/EHNote/types';
import { DetailsContext, IDetailsContext } from '@components/EHDetails';
import { useReview } from '@features/reviews/store/hook';
import { useDeleteReviewNote, useUpdateReviewNotes } from './hooks';

export const ReviewDetailNotes = (): JSX.Element => {
  const { setError, setLoading } = useContext<IDetailsContext>(DetailsContext);
  const review = useReview({ setError, setLoading });
  const mutateUpdate = useUpdateReviewNotes(review?.id as string);
  const mutateDelete = useDeleteReviewNote(review?.id as string);
  const user = useSelectMaskedUser();
  const isWriteOperationEnabled = useSelectWriteOperationEnabled();

  const onSubmit = (note: INoteComment, { resetForm }: { resetForm: () => void }): void => {
    mutateUpdate({ note, resetForm });
  };

  const onDelete = (noteId: string): void => {
    /* istanbul ignore next */
    mutateDelete(noteId);
  };

  const isCompleted = review?.state == ReviewState.Completed;

  const noteList = useMemo(
    () =>
      [...(review?.notes || [])]
        ?.sort((a, b) => dayjs(b.createdOn).diff(a.createdOn))
        .map(
          (note, i): JSX.Element => (
            <EHNote
              {...note}
              currentUser={(!isCompleted && user) || ''}
              key={i}
              onDelete={onDelete}
              onSubmit={onSubmit}
              useRTEditor={true}
            />
          ),
        ),
    [review?.notes, isCompleted, user],
  );

  return (
    <>
      {!isCompleted && isWriteOperationEnabled && (
        <EHNote
          createdBy={user}
          initialState={NoteState.CREATE}
          onSubmit={onSubmit}
          role="form"
          useRTEditor={true}
        />
      )}
      <div role="list">{noteList}</div>
    </>
  );
};
