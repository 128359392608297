import { fetchAsync } from '@engagehub/core';
import { Maybe } from '@serviceshub/x-types';
import { EHRoutes, IResult } from '@utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useRouteMatch } from 'react-router-dom';
import { constructCustomerScope } from '@utils/scope';
import { selectReview } from './store/hook';
import { ITemplateViewEngine, IValidateReviewNameResponse, TrainingState } from './types';
import { useReviewTemplates } from './view/hooks';

export const useReviewId = (): string | undefined => {
  const match = useRouteMatch<{ id: string | undefined }>(EHRoutes.reviewLanding);

  return match?.params.id;
};

export interface IReviewTypeInfo {
  isDashboardSupported: boolean;
  viewEngine: ITemplateViewEngine;
  name: string;
  isTraining: boolean;
  tags: Record<string, string>;
}

export const useReviewTypeInfo = (): IReviewTypeInfo | undefined => {
  const review = useSelector(selectReview);
  const { data } = useReviewTemplates();

  return useMemo(() => {
    const templateSelected = data?.find(i => i.id == review?.templateId);

    if (!templateSelected) return undefined;

    return {
      isDashboardSupported: templateSelected.templateViewEngine !== 'None',
      name: templateSelected.name,
      viewEngine: templateSelected.templateViewEngine,
      isTraining: templateSelected.training == TrainingState.MIRP,
      tags: templateSelected.tags,
    };
  }, [data, review?.id]);
};

let prevReviewName = '';
let prevValidationResult = false;
export const useUniqueName = async (
  value: Maybe<string>,
  customerId: Maybe<string>,
  name: Maybe<string>,
): Promise<boolean> => {
  try {
    if (
      value == name ||
      (prevReviewName != '' && prevValidationResult && value == prevReviewName)
    ) {
      return true;
    }

    const result = await fetchAsync<IResult<IValidateReviewNameResponse>>(
      `/br/processes/reviews/validate`,
      {
        method: 'POST',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
        data: {
          name: value,
        },
      },
    ).then(resp => resp.value);
    prevReviewName = value || '';
    prevValidationResult = result;

    return result;
  } catch (error) {
    return false;
  }
};
