import React from 'react';
import { ElxContainer, IElxContainerProps, IElxContainerStyles } from '@elixir/components';
import { ElxScopedContainer, IElxScopedContainerProps } from '@elixir/fx';
import { IRawStyle } from '@fluentui/react';
import * as styles from './styles';

export * from './context';
export * from './styles';
export const breadCrumbStyle = {
  root: {
    top: '42px',
  },
  headerComponent: {
    paddingTop: '0 !important',
    margin: 0,
  },
  body: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
};

export const EHContainer = (props: IElxContainerProps | IElxScopedContainerProps): JSX.Element => {
  props = {
    ...props,
    styles: {
      root: {
        minWidth: '320px',
      },
      header: {
        ...(((props.styles as Partial<IElxContainerStyles> | undefined)?.header as IRawStyle) ??
          {}),
        ...styles.getHeaderStyleForLevel(props.headingLevel),
      },
      ...props.styles,
    },
  };

  if ((props as IElxScopedContainerProps).scope) return <ElxScopedContainer {...props} />;

  return <ElxContainer {...props} />;
};
