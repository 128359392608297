import { initializeElixirFramework } from '@elixir/fx';
import { BusinessReviewModule } from '@pages';
import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { CoreModule } from '@engagehub/core';
import { useSagaContext } from './hooks';

export const StoreProvider = (props: { children: JSX.Element }): JSX.Element => {
  const context = useSagaContext();
  const { history, store } = initializeElixirFramework(
    [BusinessReviewModule(), CoreModule],
    undefined,
    { context },
  );

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>{props.children}</ConnectedRouter>
    </Provider>
  );
};
