import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react';
import { EHForm } from '@components/EHForms/EHForm';
import * as routing from '@utils/routing';
import { FormikContextType } from 'formik';
import { useCreateReview } from './hooks';
import { useReviewCreateActions } from './actions';
import { IEditReviewFields, schema } from '../helper';
import { ReviewForm } from '../form';
import { DialogCancel } from '../dialogCancel';
import { DialogSaveAsDraft } from '../dialogSaveAsDraft';

export const ReviewCreate = (): JSX.Element => {
  const { t } = useTranslation();
  const push = routing.usePush();
  const [draftConfirm, setDraftConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const mutate = useCreateReview();
  // const recurringReviewMutate = useCreateRecurringReview();

  const initialValues: IEditReviewFields = {
    id: '',
    name: '',
    frequency: '',
    startDate: '',
    endDate: '',
    description: '',
    meetingDate: '',
    attendees: '',
    isRecurring: false,
    recurrenceType: 'Weekly',
    weeklyInterval: 1,
    dayOfTheWeek: '',
    monthlyInterval: 1,
    dayOfTheMonth: 1,
    monthlyDaysMode: 'OnDay',
    reviewStartDateOffset: 0,
    reviewPeriod: 0,
    daysToPrepare: 0,
    templateId: '',
  };

  return (
    <EHForm
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(r: IEditReviewFields): void => {
        mutate(r); //r.isRecurring ? recurringReviewMutate(r) : mutate(r);
      }}
      validationSchema={schema()}>
      {({ submitForm, resetForm, isValid, dirty }: FormikContextType<unknown>): JSX.Element => (
        <>
          {useReviewCreateActions({ setDraftConfirm, setCancelConfirm, isValid, dirty })}
          <DialogSaveAsDraft
            onCancel={(): void => setDraftConfirm(false)}
            onConfirm={(): void => {
              setDraftConfirm(false);
              submitForm();
            }}
            open={draftConfirm}
          />
          <DialogCancel
            onCancel={(): void => setCancelConfirm(false)}
            onConfirm={(): void => {
              resetForm();
              setCancelConfirm(false);
              push(routing.EHRoutes.operationalReview);
            }}
            open={cancelConfirm}
          />
          <Text>{t('ReviewLanding.ReviewSummary')}</Text>
          <ReviewForm />
        </>
      )}
    </EHForm>
  );
};
