import { IPagedResult /*ISetResult,*/, ISetResult } from '@utils';
import {
  FetchNextPageOptions,
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from 'react-query';
import {
  IWorkItemResponse,
  IWorkItemTypes,
  IWorkItemUserQueryResponse,
  useReviewId,
  /*IWorkItemUserQueryResponse,*/
} from '@features';
// import { IEHPeoplePickerItem } from '@components/EHForms/EHPeoplePicker';
import { GetNextPageParamFn, IPaginationFilter } from '@utils/query/pagination';
import { Dispatch, useEffect } from 'react';
import { fetchAsync, useSelectCustomerId } from '@engagehub/core';
import { IEHPeoplePickerItem } from '@components/EHForms/EHPeoplePicker';
import { constructCustomerScope } from '@utils/scope';
import { IActionItemFilter, defaultActionItemFilter } from './taskList/hooks';
import { actionItemQueryKey } from './constants';

export const useAssignedToUsers = (
  searchTerm: string,
  enabled = false,
): UseQueryResult<IEHPeoplePickerItem[]> => {
  const customerId = useSelectCustomerId();
  const reviewId = useReviewId();
  return useQuery(
    ['task-assigned-to-users', searchTerm],
    () =>
      fetchAsync<ISetResult<IWorkItemUserQueryResponse>>(
        `/br/processes/reviews/${reviewId}/work-items/users/query`,
        {
          method: 'POST',
          headers: {
            'x-eh-scope': constructCustomerScope(customerId!),
          },
          params: {
            customerId,
          },
          data: {
            searchTerm,
          },
        },
      ),
    {
      select: data => data.values,
      enabled: enabled && !!searchTerm.length,
      staleTime: Infinity,
    },
  );
};

export function useActionItemList(
  actionItemFilter: IActionItemFilter = defaultActionItemFilter,
  pageSize = 5,
): UseInfiniteQueryResult<IPagedResult<IWorkItemResponse>> {
  const customerId = useSelectCustomerId();
  const reviewId = useReviewId();

  const executeFetch = ({
    pageParam,
  }: FetchNextPageOptions): Promise<IPagedResult<IWorkItemResponse>> => {
    const paginationFilter = (pageParam as IPaginationFilter) ?? { skip: 0, take: pageSize };
    return fetchAsync<IPagedResult<IWorkItemResponse>>(
      `/br/processes/reviews/${reviewId}/work-items/query`,
      {
        method: 'POST',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
        data: {
          ...actionItemFilter,
          ...paginationFilter,
        },
      },
    );
  };

  return useInfiniteQuery([...actionItemQueryKey, actionItemFilter], executeFetch, {
    getNextPageParam: (lastPage, allPages) => GetNextPageParamFn(lastPage, allPages, pageSize),
    enabled: !!customerId,
    staleTime: Infinity,
  });
}

export const useTaskTypes = (
  setError?: Dispatch<boolean>,
  cid?: string,
): {
  data: IWorkItemTypes[] | undefined;
  isLoading: boolean;
} => {
  const customerId = useSelectCustomerId() || cid;
  const reviewId = useReviewId();

  const { isLoading, isError, data } = useQuery(
    ['taskTypes'],
    () =>
      fetchAsync<IPagedResult<IWorkItemTypes>>(
        `/br/processes/reviews/${reviewId}/work-items/types`,
        {
          method: 'GET',
          headers: {
            'x-eh-scope': constructCustomerScope(customerId!),
          },
          params: {
            customerId,
          },
        },
      ).then(resp => resp.values),
    {
      staleTime: Infinity,
    },
  );
  useEffect(() => {
    setError && setError(isError);
  }, [isError]);
  return { data, isLoading };
};

export const useTaskTypeFilter = (
  taskTypes: IWorkItemTypes[] | undefined,
  filter?: string[],
): { severities: string[]; states: string[] } => {
  const getUniqueEntryInTask = (entries: string[] | undefined): string[] => {
    if (entries === undefined) return [];

    const unique = new Set<string>();
    for (const entry of entries) {
      unique.add(entry);
    }
    return unique.size == 0 ? [] : Array.from(unique);
  };

  const items = filter ? taskTypes?.filter(x => filter.includes(x.name)) : taskTypes;
  return {
    severities: getUniqueEntryInTask(items?.flatMap(x => x.severityValues)),
    states: getUniqueEntryInTask(items?.flatMap(x => x.states)),
  };
};
