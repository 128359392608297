import * as yup from 'yup';
import { Stack } from '@fluentui/react';
import React, { MutableRefObject, useEffect } from 'react';
import { EHFieldDropdown, EHForm, EHTextField } from '@components';
import { FormikProps } from 'formik';
import { ElxMessageBarType } from '@elixir/components';
import { getErrorFromAxiosError, successMessage } from '@features';
import { AdminPageContext, IAdminPageContext } from '@pages/Admin';
import { reviewTypeOptions } from '@utils/store/constants';
import { useCreateCustomerProcess } from './hooks';

export interface ICreateCustomerProcessFields {
  targetScope: string;
  reviewType: string;
  name: string;
}

const initialValues: ICreateCustomerProcessFields = {
  targetScope: '',
  reviewType: '',
  name: '',
};

const schema = yup.object().shape({
  targetScope: yup.string().required('Target Scope is required'),
  reviewType: yup.string().required('Review Type is required'),
  name: yup.string().required('Customer name is required'),
});

export const CreateCustomerProcess = (): JSX.Element => {
  const { mutate: create, isLoading, error, isSuccess } = useCreateCustomerProcess();

  const { createCustomerProcess, ...adminCtx } =
    React.useContext<IAdminPageContext>(AdminPageContext);

  useEffect(() => adminCtx.setIsLoading(isLoading), [isLoading]);
  useEffect(() => {
    if (isSuccess) createCustomerProcess.setMessage(successMessage);
  }, [isSuccess]);
  useEffect(() => {
    if (error) {
      createCustomerProcess.setMessage({
        message: getErrorFromAxiosError(error),
        type: ElxMessageBarType.error,
      });
    } else createCustomerProcess.setMessage(undefined);
  }, [error]);

  return (
    <EHForm
      initialValues={initialValues}
      innerRef={
        createCustomerProcess.innerRef as MutableRefObject<FormikProps<ICreateCustomerProcessFields> | null>
      }
      onSubmit={(values): void => create(values)}
      style={{ height: '100%' }}
      validationSchema={schema}>
      {(): JSX.Element => {
        return (
          <Stack tokens={{ childrenGap: 16, padding: 24 }}>
            <EHTextField
              aria-label={'targetScope'}
              id="targetScope-field"
              label="Target Scope"
              name="targetScope"
              placeholder="Enter Target Scope"
              required
            />
            <EHFieldDropdown
              aria-label={'reviewType'}
              id="reviewType-field"
              label="Review Type"
              name="reviewType"
              options={reviewTypeOptions}
              placeholder="Select Review Type"
            />
            <EHTextField
              aria-label={'name'}
              id="customer-name-field"
              label="Customer Name"
              name="name"
              placeholder="Enter Customer Name"
            />
          </Stack>
        );
      }}
    </EHForm>
  );
};
