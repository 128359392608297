import React, { Dispatch, createContext, memo, useState } from 'react';
import { EHPanel } from '@components/EHPanel';
import {
  ElxMessageBarType,
  IElxPanelStyles,
  IElxTagContainerStyles,
  PanelSize,
  TagSeverity,
} from '@elixir/components';
import { useTranslation } from 'react-i18next';
import { ITheme, mergeStyleSets, useTheme } from '@fluentui/react';
import { EHItems, IEHItem } from '@components/EHItem';
import { EHTagContainer } from '@components/EHTagContainer';
import { ElxHtml } from '@elixir/fx/lib/components/Html/ElxHtml';

interface IStyles {
  panel: string;
  description: string;
}

const getStyles = (theme: ITheme): IStyles => {
  return mergeStyleSets({
    panel: {
      paddingLeft: theme.spacing.l2,
      paddingRight: theme.spacing.l1,
    },
    description: {
      paddingTop: theme.spacing.l1,
      font: theme.fonts.medium,
      display: 'inline-block',
    },
  });
};

export interface IEHDetails {
  readonly headerText: string;
  readonly subHeaderText?: string;
  readonly description: string;
  readonly items: IEHItem[];
  readonly open: boolean;
  readonly onDismiss: () => void;
  readonly headerContent: JSX.Element | undefined;
  readonly children: JSX.Element;
}

const customPanelStyles = (theme: ITheme): Partial<IElxPanelStyles> => ({
  header: {
    ...theme.fonts.xLarge,
    lineHeight: '28px',
  },
});

const tagContainerStyles = (theme: ITheme): Partial<IElxTagContainerStyles> => ({
  root: {
    marginTop: theme.spacing.m,
  },
});

export interface IDetailsContext {
  setError: Dispatch<boolean>;
  setLoading: Dispatch<boolean>;
}

export const DetailsContext = createContext<IDetailsContext>({} as IDetailsContext);

export const EHDetails = memo(
  ({
    open,
    headerText,
    subHeaderText,
    description,
    items,
    headerContent,
    onDismiss,
    children,
  }: IEHDetails): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const styles = getStyles(theme);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const context: IDetailsContext = { setError, setLoading };

    const message = {
      type: ElxMessageBarType.error,
      message: t('Main.StandardErrorMessage'),
      onDismiss: (): void => setError(false),
    };

    return (
      <EHPanel
        headerContent={headerContent}
        headerText={headerText}
        isLoading={loading}
        isOpen={open}
        message={error ? message : undefined}
        onDismiss={onDismiss}
        size={PanelSize.large}
        styles={customPanelStyles(theme)}
        subHeaderText={subHeaderText}>
        <DetailsContext.Provider value={context}>
          <div className={styles.panel}>
            <EHItems items={items} />
            <b className={styles.description}>{t('OperationalReview.Description')}</b>
            <ElxHtml
              aria-label={t('OperationalReview.Description')}
              content={description as string}
            />
            <EHTagContainer
              data={[
                {
                  key: '1',
                  text: t('OperationalReview.DisclaimerText'),
                  severity: TagSeverity.Warning,
                },
              ]}
              styles={tagContainerStyles(theme)}
            />
            {children}
          </div>
        </DetailsContext.Provider>
      </EHPanel>
    );
  },
);

EHDetails.displayName = 'EHDetails';
