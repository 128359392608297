import * as yup from 'yup';
import { Stack } from '@fluentui/react';
import React, { MutableRefObject, useEffect } from 'react';
import { EHFieldDropdown, EHForm, EHTextField } from '@components';
import { FormikProps } from 'formik';
import { ElxMessageBarType } from '@elixir/components';
import { getErrorFromAxiosError, successMessage } from '@features';
import { AdminPageContext, IAdminPageContext } from '@pages/Admin';
import { reviewTypeOptions } from '@utils/store/constants';
import { useConfigureCustomerInsights } from './hooks';

export interface IConfigureInsightsFields {
  targetScope: string;
  reviewType: string;
  aceCustomerId: string;
}
const initialValues: IConfigureInsightsFields = {
  targetScope: '',
  reviewType: '',
  aceCustomerId: '',
};

const schema = yup.object().shape({
  targetScope: yup.string().required('Target Scope is required'),
  reviewType: yup.string().required('Review Type is required'),
  aceCustomerId: yup.string().required('Ace Customer Id is required'),
});

export const ConfigureInsights = (): JSX.Element => {
  const { mutate: create, isLoading, isSuccess, error } = useConfigureCustomerInsights();
  const { configureInsights, ...adminCtx } = React.useContext<IAdminPageContext>(AdminPageContext);
  useEffect(() => adminCtx.setIsLoading(isLoading), [isLoading]);
  useEffect(() => {
    if (isSuccess) configureInsights.setMessage(successMessage);
  }, [isSuccess]);
  useEffect(() => {
    if (error) {
      configureInsights.setMessage({
        message: getErrorFromAxiosError(error),
        type: ElxMessageBarType.error,
      });
    } else configureInsights.setMessage(undefined);
  }, [error]);

  return (
    <EHForm
      initialValues={initialValues}
      innerRef={
        configureInsights.innerRef as MutableRefObject<FormikProps<IConfigureInsightsFields> | null>
      }
      onSubmit={(values): void => create(values)}
      style={{ height: '100%' }}
      validationSchema={schema}>
      {(): JSX.Element => {
        return (
          <Stack tokens={{ childrenGap: 16, padding: 24 }}>
            <EHTextField
              aria-label={'targetScope'}
              id="targetScope-field"
              label="Target Scope"
              name="targetScope"
              placeholder="Enter Target Scope"
              required
            />
            <EHFieldDropdown
              aria-label={'reviewType'}
              id="reviewType-field"
              label="Review Type"
              name="reviewType"
              options={reviewTypeOptions}
              placeholder="Select Review Type"
            />
            <EHTextField
              aria-label={'aceCustomerId'}
              id="ace-customer-id-field"
              label="Ace Customer Id"
              name="aceCustomerId"
              placeholder="Enter Ace Customer Id"
            />
          </Stack>
        );
      }}
    </EHForm>
  );
};
