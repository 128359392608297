/* istanbul ignore file */
import { useEffect } from 'react';

export interface IEvent extends FocusEvent {
  target: FocusEvent['target'] & {
    id: string;
  };
}

export const useInsertLinkFix = (): void => {
  // "Insert link" workaround! Only needed when EHTextEditor is inside EHPanel!
  // autofocus in the Panel is creating infinite loop.
  useEffect(() => {
    let used = '';
    const focusout = (ev: FocusEvent): void => {
      const id = (ev as IEvent).target?.id;
      if (!!id) {
        if (!used || used != id) {
          used = id;
          document.getElementById(id)?.focus();
        }
      } else {
        used = '';
      }
    };
    setTimeout(() => {
      window.addEventListener('focusout', focusout);
    });
    return () => {
      window.removeEventListener('focusout', focusout);
    };
  }, []);
};
