import { useSelectWriteOperationEnabled } from '@features/context';
import { useReviewSelector } from '@features/reviews/store/hook';
import { ReviewState } from '@features/reviews/types';
import { IReviewContext, ReviewContext, ReviewStatus } from '@pages';
import { Dispatch, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useReviewViewActions = (setOpenPublishConfirm: Dispatch<boolean>): void => {
  const { t } = useTranslation();
  const review = useReviewSelector();
  const { setActions, setStatus } = useContext<IReviewContext>(ReviewContext);
  const isWriteOperationEnabled = useSelectWriteOperationEnabled();

  useEffect(() => {
    setActions([
      {
        key: 'publish',
        text: t('ReviewLanding.Publish'),
        type: 'submit',
        onClick: (): void => setOpenPublishConfirm(true),
        disabled: !isWriteOperationEnabled || ReviewState.Draft != review?.state,
        id: 'businessreview-review-publish',
      },
      {
        key: 'edit',
        text: t('ReviewLanding.Edit'),
        onClick: (): void => setStatus?.(ReviewStatus.EDIT),
        type: 'submit',
        disabled: !isWriteOperationEnabled || ReviewState.Draft != review?.state,
        id: 'businessreview-review-edit',
      },
    ]);
  }, [review?.state]);
};
