/* eslint-disable @typescript-eslint/no-explicit-any */
import { IFilterResult, IPagedResult, IResult } from '@utils';
import {
  UseMutateFunction,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { IReviewResponse, useDoesUserHaveAnyAccess } from '@features';
import { useContext, useEffect } from 'react';
import { IContainerContext, defaultPaginationFilter } from '@components';
import { OperationalReviewContext } from '@pages';
import { IPaginationFilter } from '@utils/query/pagination';
import { fetchAsync, useSelectCustomerId } from '@engagehub/core';
import { constructCustomerScope } from '@utils/scope';
import { selectEnableLinkToEngageHubUX } from '@features/navigation/store';
import { useSelector } from 'react-redux';
import { getReviewFiltersQueryKey, getReviewsQueryKey } from '../query';

const getQueryReviewsFetchAsync = (
  customerId: string,
  tableFilter?: IPaginationFilter,
): Promise<IReviewResponse[]> =>
  fetchAsync<IPagedResult<IReviewResponse>>('/br/processes/reviews/query', {
    method: 'POST',
    headers: {
      'x-eh-scope': constructCustomerScope(customerId),
    },
    params: {
      customerId,
    },
    data: {
      ...(tableFilter ?? defaultPaginationFilter),
    },
  }).then(pagedResult => pagedResult?.values);

export const useQueryReviewFilters = (): UseQueryResult<IFilterResult> => {
  const customerId = useSelectCustomerId();
  const hasAnyAccess = useDoesUserHaveAnyAccess();
  const isRedirectionEnabled = useSelector(selectEnableLinkToEngageHubUX);

  return useQuery(
    getReviewFiltersQueryKey(customerId),
    async () =>
      await fetchAsync<IResult<IFilterResult>>('/br/processes/reviews/query/counts', {
        method: 'POST',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
        data: {
          ...defaultPaginationFilter,
        },
      }).then(result => result?.value),
    {
      enabled: hasAnyAccess && !!customerId && !isRedirectionEnabled,
      // Do not auto refetch
      staleTime: Infinity,
    },
  );
};

export const useQueryReviews = (): IReviewResponse[] | undefined => {
  const customerId = useSelectCustomerId();
  const { setLoading, setStandardError } = useContext<IContainerContext>(OperationalReviewContext);
  const isRedirectionEnabled = useSelector(selectEnableLinkToEngageHubUX);

  console.log('useQueryReviews', isRedirectionEnabled);
  const { isLoading, isError, data } = useQuery(
    getReviewsQueryKey(customerId),
    async () => await getQueryReviewsFetchAsync(customerId ?? ''),
    {
      enabled: !!customerId && !isRedirectionEnabled,
      // Do not auto refetch
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    setLoading(isLoading, 'reviews/query');
  }, [isLoading]);

  useEffect(() => {
    if (isError) setStandardError(isError, 'OperationalReviewTable:isError');
  }, [isError]);

  return data;
};

export function useUpdateReviews(): UseMutateFunction<
  IReviewResponse[],
  unknown,
  IPaginationFilter,
  unknown
> {
  const customerId = useSelectCustomerId();
  const queryClient = useQueryClient();
  const { setLoading, setStandardError } = useContext<IContainerContext>(OperationalReviewContext);
  let requestOptions: IPaginationFilter;

  const { isLoading, isError, mutate } = useMutation(
    async (tableFilter: IPaginationFilter) => {
      requestOptions = tableFilter;
      return await getQueryReviewsFetchAsync(customerId ?? '', tableFilter);
    },
    {
      onSuccess: (data: IReviewResponse[] = []) =>
        queryClient.setQueryData<any>(
          getReviewsQueryKey(customerId),
          (cache: IReviewResponse[] = []) => {
            if (requestOptions && requestOptions.skip != 0) {
              return [...cache.filter(i => !data.find(a => a.id == i.id)), ...data];
            }
            return [...data];
          },
        ),
    },
  );

  useEffect(() => {
    setLoading(isLoading, 'reviews/query/update');
  }, [isLoading]);

  useEffect(() => {
    if (isError) setStandardError(isError, 'OperationalReviewTable:isUpdateError');
  }, [isError]);

  return mutate;
}
