import { ReviewDetailsButton, useReviewId } from '@features/reviews';
import React from 'react';
import { CreateTask } from './createTask';

export const useActionItemActions = (): JSX.Element[] => {
  const reviewId = useReviewId();
  const actions = [<CreateTask key={2} />];
  if (reviewId) {
    actions.unshift(<ReviewDetailsButton key={1} />);
  }
  return actions;
};
