import React from 'react';
import { Stack, Text } from '@fluentui/react';
import { useReviewTemplates } from '@features/reviews/view/hooks';

interface IReviewWorkload {
  readonly templateId: string;
}

export const ShowTypeColumn = ({ templateId }: IReviewWorkload): JSX.Element => {
  const { data: templateData } = useReviewTemplates();

  return (
    <Stack>
      <Text variant="medium">
        {templateData?.find(i => i.id === templateId)?.name?.split(' ')[0]}
      </Text>
    </Stack>
  );
};
