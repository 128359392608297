import { IAppContextState } from '@engagehub/core';
import {
  evaluateReviewState,
  fetchReview,
  fetchReviewDetails,
} from '@features/reviews/store/sagas';
import {
  IReviewState,
  getReview,
  getReviewSuccess,
  openReviewDetails,
  reloadReview,
  reviewReducer,
} from '@features/reviews/store/store';
import { CombinedState, Reducer, combineReducers } from '@reduxjs/toolkit';
import { debounce, takeLatest } from 'redux-saga/effects';
import { INavigationState, navigationReducer } from '@features/navigation/store';
import { debounceTime } from './constants';

export interface IRootState {
  context: IAppContextState;
  reviews: IReviewState;
  navigation: INavigationState;
}
export * from './hooks';

export type IReducers = CombinedState<IRootState>;

export function getBRReducers(): Reducer {
  return combineReducers({ reviews: reviewReducer, navigation: navigationReducer });
}

export function* getBRSagas(): Generator {
  yield debounce(debounceTime, getReview.type, fetchReview);
  yield takeLatest(reloadReview.type, fetchReview);
  yield takeLatest(openReviewDetails.type, fetchReviewDetails);
  yield takeLatest(getReviewSuccess.type, evaluateReviewState);
}
