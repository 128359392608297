import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { EHPivot } from '@components/EHPivot';
import { useSelectCustomerName } from '@features';
import { dayjs } from '@utils';
import { EHDetails } from '@components/EHDetails';
import { useDispatch } from 'react-redux';
import {
  ICombinedReviewContext,
  reviewDetailsStateSelector,
  useReview,
} from '@features/reviews/store/hook';
import { closeReviewDetailsPanel } from '@features/reviews/store/store';
import { ReviewDetailNotes } from '../notes';

export enum TabStatus {
  NOTES = '1',
  DOCUMENTS = '2',
  ACTIVITIES = '3',
}
export interface IReviewDetailsPanel {
  ctx: ICombinedReviewContext;
}

export const ReviewDetailsPanel = memo(({ ctx }: IReviewDetailsPanel): JSX.Element => {
  const dispatch = useDispatch();
  const review = useReview(ctx);
  const customerName = useSelectCustomerName();
  const { t } = useTranslation();
  const tab = TabStatus.NOTES;
  const open = reviewDetailsStateSelector();

  const headerContent = (
    // <EHActionButton
    //   disabled
    //   iconProps={{ iconName: 'Trash' }}
    //   key="1"
    //   text={t('OperationalReview.Delete')}
    // />
    <></>
  );

  const pivotItems = [
    {
      itemKey: TabStatus.NOTES,
      headerText: t('ActionItems.Notes', { countTxt: review?.notes?.length || 0 }),
    },
  ];

  const items = [
    { label: t('OperationalReview.Owner'), text: review?.createdBy || '' },
    {
      label: t('OperationalReview.LastUpdate'),
      text: dayjs(review?.updatedOn).format('L LTS'),
    },
    { label: t('OperationalReview.Customer'), text: customerName || '' },
    {
      label: t('OperationalReview.Frequency'),
      text: review?.frequency,
    },
    { label: t('OperationalReview.Created'), text: dayjs(review?.createdOn).format('L') },
  ];

  return (
    <EHDetails
      description={review?.description as string}
      headerContent={headerContent}
      headerText={review?.name as string}
      items={items}
      onDismiss={(): void => {
        dispatch(closeReviewDetailsPanel());
      }}
      open={open}>
      <>
        <EHPivot items={pivotItems} />
        {tab === TabStatus.NOTES && <ReviewDetailNotes />}
      </>
    </EHDetails>
  );
});

ReviewDetailsPanel.displayName = 'ReviewDetails';
