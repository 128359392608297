import { fetchAsync } from '@engagehub/core';
import { AxiosError } from 'axios';
import { UseMutationResult, useMutation } from 'react-query';
import { adminApiVersion } from '@utils/store/constants';
import { IConfigureInsightsFields } from './configureInsights';

export const useConfigureCustomerInsights = (): UseMutationResult<
  unknown,
  AxiosError,
  IConfigureInsightsFields,
  unknown
> => {
  return useMutation((values: IConfigureInsightsFields) =>
    fetchAsync(`/br-admin/datasource/insights`, {
      method: 'POST',
      headers: {
        'x-eh-scope': values.targetScope,
      },
      params: {
        'api-version': adminApiVersion,
      },
      data: {
        ...values,
        createdBy: 'Microsoft@microsoft.com',
      },
    }),
  );
};
