/* eslint-disable react/no-multi-comp */
import { IModule } from '@elixir/fx';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { getBRReducers, getBRSagas } from '@utils/store';
import { EHRoutes } from '@utils';
import { CreateReviewPage } from '@pages/CreateReview';
import { ReviewLandingPage } from '@pages/ReviewLanding';
import { OperationalReviewPage } from '..';

export const BusinessReviewModule = (): IModule => {
  const { t } = useTranslation();

  return {
    name: 'BusinessReview',
    env: 'development',
    reducer: getBRReducers(),
    saga: getBRSagas,
    route: {
      index: 'business',
      name: t('Main.Reviews'),
      iconProps: {
        iconName: 'Financial',
      },
      path: EHRoutes.operationalReview,
      isRouteable: true,
      exact: true,
      inSidebar: true,
      component: (): JSX.Element => <OperationalReviewPage />,
      subRoutes: [
        {
          inSidebar: false,
          exact: true,
          index: 'CreateReview',
          name: 'Create Review',
          path: EHRoutes.createReview,
          component: (): JSX.Element => <CreateReviewPage />,
        },
        {
          inSidebar: false,
          exact: false,
          index: 'reviewLanding',
          name: 'Review',
          path: EHRoutes.reviewLanding,
          component: (): JSX.Element => <ReviewLandingPage />,
        },
      ],
    },
  };
};
