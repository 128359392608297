import { dayjs } from '@utils';
import React, { CSSProperties } from 'react';

const propertyNameStyles: CSSProperties = { paddingRight: '4px' };

export const ActivityColumn = (props: { propertyChanged: string; date: Date }): JSX.Element => (
  <span>
    <b style={propertyNameStyles}>{props.propertyChanged}</b>
    {dayjs(props.date).format('ll, ddd')}
  </span>
);
