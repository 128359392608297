import React from 'react';
import { FieldHelperProps, FieldInputProps } from 'formik';
import { ElxTagPicker, IElxTagPickerProps, ITagData } from '@elixir/components';
import { EHFieldWrapper } from '../EHFieldWrapper';
import { FormContext, IFormContext, IName } from '../EHForm';

type IBaseEHFieldTagPickerProps = Omit<IElxTagPickerProps, 'onAddTag' | 'onRemoveTag'> & IName;

export type IEHTagPickerProps = IBaseEHFieldTagPickerProps;

interface IEHFieldTagPickerProps extends Omit<IElxTagPickerProps, 'onAddTag' | 'onRemoveTag'> {
  readonly helpers: FieldHelperProps<unknown>;
}

export const EHTagPicker = ({ name, ...props }: IEHTagPickerProps): JSX.Element => {
  const { fieldsConfiguration } = React.useContext<IFormContext>(FormContext);

  return (
    <EHFieldWrapper name={name}>
      {({ helpers, ...field }: IEHFieldTagPickerProps & FieldInputProps<string[]>): JSX.Element => {
        return (
          <ElxTagPicker
            {...props}
            items={field.value.map(e => ({ key: e, text: e }))}
            onAddTag={(tag: ITagData): void => {
              helpers.setValue([...field.value, tag.text]);
            }}
            onRemoveTag={(tag: ITagData): void => {
              helpers.setValue([...field.value.filter((item: string) => item !== tag.text)]);
            }}
            {...fieldsConfiguration?.[name]}
          />
        );
      }}
    </EHFieldWrapper>
  );
};
