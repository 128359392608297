import React from 'react';
import { FieldHelperProps, FieldMetaProps } from 'formik';
import { EHFieldWrapper } from '@components/EHFieldWrapper';
import { FormContext, IFormContext, IName } from '@components/EHForm';
import { ElxTextField, IElxTextFieldProps, InputMessageTypes } from '@elixir/components';

type IEHTextField = IElxTextFieldProps & IName;

interface IEHTextFieldProps extends IElxTextFieldProps {
  readonly meta: FieldMetaProps<unknown>;
  readonly helpers: FieldHelperProps<unknown>;
}

export const EHTextField = ({ name, message, ...props }: IEHTextField): JSX.Element => {
  const { fieldsConfiguration } = React.useContext<IFormContext>(FormContext);

  return (
    <EHFieldWrapper name={name}>
      {({ meta, ...field }: IEHTextFieldProps): JSX.Element => {
        return (
          <ElxTextField
            {...props}
            {...field}
            message={
              message ||
              (meta.error && { content: meta.error as string, type: InputMessageTypes.Error }) ||
              undefined
            }
            {...fieldsConfiguration?.[name]}
          />
        );
      }}
    </EHFieldWrapper>
  );
};
