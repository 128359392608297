import { EHDateTimePicker } from '@components/EHForms/EHDateTimePicker';
import { EHLayout } from '@components/EHLayout';
import { ElxDateTimePickerDisplayMode } from '@elixir/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { timeZones } from '..';

export const DateGroup = (): JSX.Element => {
  const { t } = useTranslation();
  const fields = [
    <EHDateTimePicker
      aria-label={t('ReviewLanding.StartDate')}
      displayMode={ElxDateTimePickerDisplayMode.DateOnly}
      id="EditReview-Edit-StartDate"
      key="start-date"
      label={t('ReviewLanding.StartDate')}
      name="startDate"
      required
      timeZones={timeZones}
    />,
    <EHDateTimePicker
      aria-label={t('ReviewLanding.EndDate')}
      displayMode={ElxDateTimePickerDisplayMode.DateOnly}
      id="EditReview-Edit-EndDate"
      key="end-date"
      label={t('ReviewLanding.EndDate')}
      name="endDate"
      required
      timeZones={timeZones}
    />,
  ];
  return <EHLayout internal={true} items={fields} />;
};
