/* istanbul ignore file */

import { EHActionButton } from '@components';
import { clickTracking } from '@utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IMoreButton {
  readonly onEdit: () => void;
  readonly onDelete: () => void;
}

export const MoreButton = ({ onEdit, onDelete }: IMoreButton): JSX.Element => {
  const { t } = useTranslation();

  return (
    <EHActionButton
      aria-label={t('Main.SeeMore')}
      key="1"
      menuIconProps={{ iconName: 'More' }}
      menuProps={{
        items: [
          {
            ariaLabel: t('OperationalReview.EditNote'),
            key: 'editNote',
            text: t('OperationalReview.EditNote'),
            iconProps: { iconName: 'Edit' },
            onClick: (): void => {
              clickTracking('businessreview-review-notemoreedit', 'Edit Note');
              onEdit();
            },
          },
          {
            ariaLabel: t('OperationalReview.Delete'),
            key: 'deleteNote',
            text: t('OperationalReview.Delete'),
            iconProps: { iconName: 'Trash' },
            onClick: (): void => {
              clickTracking('businessreview-review-notemoredelete', 'Delete Note');
              onDelete();
            },
          },
        ],
      }}
    />
  );
};
