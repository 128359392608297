import './styles.scss';
import { IReviewDetailsResponse } from '@features';
import { Stack } from '@fluentui/react';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import React, { useMemo } from 'react';
import { EHLocalization } from '@locales';
import { useTranslation } from 'react-i18next';
import { useReviewSelector } from '@features/reviews/store/hook';
import { EHLayout } from '@components';
import { VerticalBar } from './verticalBarMetric';
import { HorizontalBar } from './horizontalBarMetric';
import { CardMetric } from './cardMetric';
import { RefreshMetrics } from './refreshMetrics';
import { IOutagesResult, IOutagesResultItem } from '../types';

export * from './noMetricsToDisplay';

export interface IMetricProps {
  data?: IOutagesResult[];
  getLabelColor?: (s: string) => string;
}

const ehLoc = new EHLocalization();

const isNullOrWhiteSpace = function (s: string): boolean {
  return s?.trim() === '';
};

// This stacks 'High Impact' above 'Others' in each column of a vertical bar chart. Not sure
// yet if there are additional values possible for this metric.
const getOrderingForOutagesByImpact = function (
  x: IOutagesResultItem,
  y: IOutagesResultItem,
): number {
  return x.label < y.label ? 1 : x.label > y.label ? -1 : 0;
};

const getDataForOutagesByImpact = function (outagesResults: IOutagesResult[]): IOutagesResult[] {
  return useMemo(
    () =>
      outagesResults
        ?.map(result => ({
          date: new Date(result.label),
          outagesResultItems: result.outagesResultItems,
        }))
        ?.sort((curr, next) => curr.date.getTime() - next.date.getTime())
        ?.map(result => ({
          label: result.date.toLocaleDateString(ehLoc.getCurrentLocale()),
          outagesResultItems: result.outagesResultItems
            // Without a value for label this data point is meaningless
            .filter(item => !isNullOrWhiteSpace(item.label))
            .sort((curr, next) => getOrderingForOutagesByImpact(curr, next)),
        })),
    [outagesResults],
  );
};

const getLabelColorForOutagesByImpact = function (label: string): string {
  switch (label) {
    case 'High Impact':
      return DefaultPalette.redDark;
    case 'Others':
      return DefaultPalette.blue;
    default:
      return DefaultPalette.orangeLighter;
  }
};

export const DashboardMetrics = (): JSX.Element => {
  const { t } = useTranslation();
  const review = useReviewSelector() as IReviewDetailsResponse;

  const items = [];
  if (review.metrics?.outagesByImpact?.length) {
    items.push(
      <CardMetric
        body={
          <VerticalBar
            data={getDataForOutagesByImpact(review.metrics.outagesByImpact)}
            getLabelColor={getLabelColorForOutagesByImpact}
          />
        }
        id="metric-outages-by-impact"
        title={t('Dashboard.OutagesByImpact')}
      />,
    );
  }
  if (review.metrics?.outagesByImpactedService?.length) {
    items.push(
      <CardMetric
        body={<HorizontalBar data={review.metrics.outagesByImpactedService} />}
        id="metric-outages-by-impacted-service"
        title={t('Dashboard.OutagesByImpactedService')}
      />,
    );
  }
  if (review.metrics?.outagesByOwningService?.length) {
    items.push(
      <CardMetric
        body={<HorizontalBar data={review.metrics.outagesByOwningService} />}
        id="metric-outages-by-owning-service"
        title={t('Dashboard.OutagesByOwningService')}
      />,
    );
  }

  return (
    <Stack tokens={{ childrenGap: 12 }}>
      <RefreshMetrics lastUpdated={review?.metrics.lastUpdated} />
      <div className={'br-horizontal-chart'}>
        <EHLayout items={items} />
      </div>
    </Stack>
  );
};
