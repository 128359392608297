import { ITheme, ImageIcon, Label, Stack, mergeStyles, useTheme } from '@fluentui/react';
import { Text } from '@fluentui/react/lib/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import metricsLogo from './img/metrics.svg';

const getLabelStyles = (theme: ITheme): string =>
  mergeStyles({ fontSize: theme.fonts.xLarge.fontSize });

const styles = {
  stack: { margin: 'auto' },
};

export const NoMetricsToDisplay = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack horizontalAlign="center" style={styles.stack} tokens={{ childrenGap: 30 }}>
      <ImageIcon
        imageProps={{
          src: metricsLogo,
          height: '110px',
          width: '110px',
        }}
      />
      <Label className={getLabelStyles(theme)}>{t('Dashboard.NoMetricsToDisplay')}</Label>
      <Text>{t('Dashboard.MetricsAvailableAfterSave')}</Text>
    </Stack>
  );
};
