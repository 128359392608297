/* eslint-disable max-lines-per-function */
import React from 'react';
import { IElxColumn, ITableAction } from '@elixir/components';
import { useTranslation } from 'react-i18next';
import { EHActionButton } from '@components/EHActionButton';
import { ITheme, mergeStyles, useTheme } from '@fluentui/react';
import { IReviewResponse } from '@features';
import * as routing from '@utils/routing';
import { useDispatch } from 'react-redux';
import { clickTracking } from '@utils';
import { CreatedInfoColumn } from './createdInfoColumn';
import { ShowWorkloadColumn } from './workloadColumn';
import { ShowTypeColumn } from './typeColumn';
import { openReviewDetails } from '../store/store';

const getButtonStyles = (theme: ITheme): string => {
  return mergeStyles({
    color: theme.semanticColors.inputIcon,
  });
};

export const columns = (): IElxColumn[] => {
  const { t } = useTranslation();
  const theme = useTheme();
  const push = routing.usePush();

  return [
    {
      key: 'name',
      name: t('OperationalReview.ReviewName'),
      fieldName: 'name',
      minWidth: 200,
      isResizable: true,
      disableSort: false,
      onRender: (item: IReviewResponse) => (
        <EHActionButton
          className={getButtonStyles(theme)}
          id={`businessreview-review-${item.id}`}
          onClick={(): void => push(routing.getReviewLandingRoute(item.id), undefined, true)}
          role={'link'}
          text={item.name}
        />
      ),
      sortBy: () => () => 1,
    },
    {
      key: 'status',
      name: t('OperationalReview.Status'),
      fieldName: 'state',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      disableSort: false,
      sortBy: () => () => 1,
    },
    {
      key: 'type',
      name: t('ReviewLanding.Type'),
      fieldName: 'type',
      onRender: (item: IReviewResponse) => <ShowTypeColumn templateId={item.templateId} />,
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      disableSort: true,
    },
    {
      key: 'workload',
      name: t('ReviewLanding.Workload'),
      fieldName: 'workload',
      onRender: (item: IReviewResponse) => (
        <ShowWorkloadColumn reviewDataParameters={item.reviewDataParameters} />
      ),
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      disableSort: true,
    },
    {
      key: 'notes',
      name: t('OperationalReview.Notes'),
      fieldName: 'notesCount',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      disableSort: true,
    },
    {
      key: 'created',
      name: t('OperationalReview.Created'),
      onRender: (item: IReviewResponse) => <CreatedInfoColumn date={item.createdOn} />,
      fieldName: 'createdOn',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      disableSort: false,
      sortBy: () => () => 1,
    },
    {
      key: 'updated',
      name: t('OperationalReview.LastUpdated'),
      onRender: (item: IReviewResponse) => <CreatedInfoColumn date={item.updatedOn} />,
      fieldName: 'updatedOn',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      disableSort: false,
      /* disable client sorting not needed for server side sorting */
      sortBy: () => () => 1,
    },
  ];
};

export const actions = (): ITableAction[] => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return [
    {
      key: 'viewdetails',
      text: t('OperationalReview.ViewDetails'),
      iconProps: { iconName: 'EditNote' },
      onAction: (review: IReviewResponse): void => {
        clickTracking(`businessreview-review-${review.id}`, 'Edit Note');
        dispatch(openReviewDetails(review.id));
      },
    },
  ];
};
