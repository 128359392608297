import React from 'react';
import { EHFieldDropdown } from '@components/EHForms';
import { useTranslation } from 'react-i18next';
import { useReviewTemplates } from '@features/reviews/view/hooks';

export const ReviewTypeDropdown = (): JSX.Element => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isLoading, data } = useReviewTemplates();

  return (
    <EHFieldDropdown
      aria-label={'type'}
      id="EditReview-Edit-ReviewType"
      isLoading={isLoading}
      label={t('ReviewLanding.ReviewType')}
      name="templateId"
      options={data?.map(i => ({ key: i.id, text: i.name?.split(' ')[0] })) || []}
      placeholder={t('ReviewLanding.SelectReviewType')}
      required
    />
  );
};
