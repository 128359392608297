import React from 'react';
import { EHContainer } from '@components';
//import { EHLink } from '@components/EHLink';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
//import resources from './resources/resources.png';
import { content } from './incidentReadiness';

// const videoStyles = mergeStyleSets({
//   iFrame: {
//     border: 'none',
//   },
// });

const trainingStyles = {
  container: {
    body: {
      maxHeight: 'auto',
    },
  },
  videoContainer: {
    root: {
      paddingTop: '24px',
      paddingBottom: '24px',
    },
  },
  gap: {
    childrenGap: 24,
  },
  resourcesContainer: {
    root: {
      paddingTop: '24px',
    },
  },
  card: {
    root: {
      padding: '8px',
    },
  },
  spinner: {
    root: {
      position: 'relative',
      left: '310px',
    },
  },
};

export const Training = (): JSX.Element => {
  // const { t } = useTranslation();
  // const [loading, setLoading] = useState(true);

  return (
    <EHContainer compact={true} styles={trainingStyles.container}>
      {content()}
      {/* <Text variant="xxLarge">{t('Training.Title')}</Text>
      <p>{t('Training.CaptionLine1')}</p>
      <p>{t('Training.CaptionLine2')}</p>
      <p>{t('Training.Note')}</p>
      <Text variant="xLarge">{t('Training.Video')}</Text>
      <Stack horizontal styles={trainingStyles.videoContainer} tokens={trainingStyles.gap} wrap>
        {loading && (
          <Spinner size={SpinnerSize.large} styles={trainingStyles.spinner as ISpinnerStyles} />
        )}
        <iframe
          allowFullScreen
          className={videoStyles.iFrame}
          height="393"
          id="businessreview-mirp-training"
          onLoad={(): void => setLoading(false)}
          sandbox="allow-scripts allow-same-origin allow-top-navigation allow-forms allow-popups allow-popups-to-escape-sandbox allow-downloads"
          src="https://aka.ms/rc-reactive"
          title="Training MIRP video"
          width="673"
        />
        <Stack tokens={trainingStyles.gap}>
          <EHFormLabel label={'Description:'} value={'Services Hub Overview - Unified'} />
          <EHFormLabel label={'Duration'} value={'1m 32s'} />
          <EHFormLabel label={'Published:'} value={'09/27/2022'} />
        </Stack>
      </Stack>
      <Text variant="xLarge">{t('Training.Resources')}</Text>
      <Stack styles={trainingStyles.resourcesContainer}>
        <DocumentCard>
          <DocumentCardImage height={200} imageFit={ImageFit.cover} imageSrc={resources} />
          <DocumentCardDetails>
            <Stack styles={trainingStyles.card}>
              <h2>{t('Training.Azure')}</h2>
              <Stack horizontal horizontalAlign="space-between">
                <EHLink
                  href="https://dev.serviceshub.microsoft.com/catalog/search/566259dd-371d-4de9-9af5-3a40a296e86c/detail/PhoenixCatalog2755?serviceId=PhoenixCatalog2755&workspaceId=566259dd-371d-4de9-9af5-3a40a296e86c"
                  target="_blank">
                  {t('Training.Link')}
                </EHLink>
                <EHIconButton
                  href="https://datasheetdev.azureedge.net/offerings-datasheets/2755%2FEN.pdf"
                  iconProps={{ iconName: 'Download' }}
                  label="Download"
                  text="Download"
                />
              </Stack>
            </Stack>
          </DocumentCardDetails>
        </DocumentCard>
      </Stack> */}
    </EHContainer>
  );
};
