import { EHContainer } from '@components/EHContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoMetricsToDisplay } from './metrics';

export const DashboardPreview = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <EHContainer
      headerText={t('Dashboard.DashboardPreview')}
      headingLevel={2}
      styles={{ root: { position: 'relative' } }}>
      <NoMetricsToDisplay />
    </EHContainer>
  );
};
