import { FontWeights, Icon, Link, Stack, Text } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectEngageHubOptions } from './store';

const styles: React.CSSProperties = {
  marginLeft: 'auto',
  marginTop: '16px',
};

export const RedirectToEngageHub = (): JSX.Element => {
  const { t } = useTranslation();
  const options = useSelector(selectEngageHubOptions);

  return (
    <Stack style={styles} tokens={{ childrenGap: 6 }}>
      <Text style={{ fontWeight: FontWeights.semibold }} variant="medium">
        {t('Main.LookingToCreate')}
      </Text>
      <Link href={options?.redirectUrl} target="_blank">
        <Icon iconName="Switch" style={{ marginRight: '8px' }} />
        <Text variant="medium">{t('Main.TryTheNewExperience')}</Text>
      </Link>
    </Stack>
  );
};
