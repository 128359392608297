import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IReviewDetailsResponse } from '../types';

export interface IReviewState {
  review: IReviewDetailsResponse | undefined;
  error: Error | undefined;
  isLoading: boolean;
  isReviewDetailsPanelOpen: boolean;
}

export const initialReviewState: IReviewState = {
  review: undefined,
  error: undefined,
  isLoading: false,
  isReviewDetailsPanelOpen: false,
};

const reviewSlice = createSlice({
  name: 'review',
  initialState: initialReviewState,
  reducers: {
    getReview: (state: IReviewState, _id: PayloadAction<string>) => ({
      ...state,
      review: undefined,
      error: undefined,
      isLoading: true,
    }),
    reloadReview: (state: IReviewState, _id: PayloadAction<string>) => ({
      ...state,
      error: undefined,
      isLoading: true,
    }),
    getReviewSuccess: (state: IReviewState, action: PayloadAction<IReviewDetailsResponse>) => ({
      ...state,
      review: action.payload,
      error: undefined,
      isLoading: false,
    }),
    getReviewFailed: (state: IReviewState, action: PayloadAction<Error>) => ({
      ...state,
      review: undefined,
      error: action.payload,
      isLoading: false,
    }),
    openReviewDetails: (state: IReviewState, _id: PayloadAction<string>) => ({
      ...state,
      review: undefined,
      error: undefined,
      isLoading: true,
    }),
    openReviewDetailsSuccess: (
      state: IReviewState,
      action: PayloadAction<IReviewDetailsResponse>,
    ) => ({
      ...state,
      isReviewDetailsPanelOpen: true,
      review: action.payload,
      error: undefined,
      isLoading: false,
    }),
    openReviewDetailsPanel: (state: IReviewState) => ({
      ...state,
      isReviewDetailsPanelOpen: true,
    }),
    closeReviewDetailsPanel: (state: IReviewState) => ({
      ...state,
      isReviewDetailsPanelOpen: false,
    }),
  },
});

const { actions, reducer } = reviewSlice;

export const {
  getReview,
  reloadReview,
  getReviewFailed,
  getReviewSuccess,
  openReviewDetails,
  openReviewDetailsSuccess,
  openReviewDetailsPanel,
  closeReviewDetailsPanel,
} = actions;

export const reviewReducer = reducer;
