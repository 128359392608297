import React from 'react';
import { Link, Stack, Text } from '@fluentui/react';
import { useEHConfig } from '@engagehub/core';
import { useTranslation } from 'react-i18next';

const linkStyles = { root: { fontSize: '16px' } };

export const CaseInsightsDashboard = (): JSX.Element => {
  const { t } = useTranslation();
  const config = useEHConfig();
  const aceUrl = config?.serviceshubUrl + '/ace';

  return (
    <Stack>
      <Link href={aceUrl} styles={linkStyles} target="_blank">
        {t('Dashboard.CaseInsightsDashboardLink')}
      </Link>
      <Text variant="medium">{t('Dashboard.CaseInsightsDashboardMessage')}</Text>
    </Stack>
  );
};
