import React from 'react';
import { FieldHelperProps, FieldMetaProps } from 'formik';
import { EHFieldWrapper } from '@components/EHFieldWrapper';
import { FormContext, IFormContext, IName } from '@components/EHForm';
import { IElxTextFieldProps, InputMessageTypes } from '@elixir/components';
import { EHRichTextEditor, isRTEditorEmpty } from '@components/EHRichTextEditor';
import { ITextFieldStyles, Theme } from '@fluentui/react';

type IEHTextField = IElxTextFieldProps & IName;

interface IEHTextFieldProps extends IElxTextFieldProps {
  readonly meta: FieldMetaProps<unknown>;
  readonly helpers: FieldHelperProps<unknown>;
}

export const editorWarningStyles = (theme: Theme): Partial<ITextFieldStyles> => ({
  description: {
    color: theme.semanticColors.warningText,
    backgroundColor: theme.semanticColors.warningBackground,
    padding: theme.spacing.s2,
  },
});

export const EHTextEditor = ({ name, message, ...props }: IEHTextField): JSX.Element => {
  const { fieldsConfiguration } = React.useContext<IFormContext>(FormContext);

  return (
    <EHFieldWrapper name={name}>
      {({ meta, value, helpers, ...field }: IEHTextFieldProps): JSX.Element => {
        if (isRTEditorEmpty(value || '')) {
          const el = document.querySelector('.elx-richtext-editor > *:first-child');
          if (el) {
            el.remove();
            setTimeout(() => {
              helpers.setValue('');
            });
          }
        }
        return (
          <EHRichTextEditor
            {...props}
            {...field}
            message={
              message ||
              (meta.error && { content: meta.error as string, type: InputMessageTypes.Error }) ||
              undefined
            }
            onChange={(value: string): void => {
              /* istanbul ignore next */
              helpers.setValue(value || '');
            }}
            value={value}
            {...fieldsConfiguration?.[name]}
          />
        );
      }}
    </EHFieldWrapper>
  );
};
