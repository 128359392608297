/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import {
  ElxTable,
  FilterProps,
  IElxColumn,
  IElxTableProps,
  SelectionMode,
} from '@elixir/components';
import { EHCommandBar } from '@components/EHCommandBar';
import { useTranslation } from 'react-i18next';
import { FontWeights, IButtonStyles, ITheme, mergeStyles, useTheme } from '@fluentui/react';
import classnames from 'classnames';
import { EHFilter } from '@components/EHFilter';
import { IPaginationFilter, ITableSort } from '@utils/query/pagination';
import { Maybe } from '@serviceshub/x-types';

export const defaultPaginationFilter: IPaginationFilter = { take: 5, skip: 0 };

export interface IEHTable extends IElxTableProps {
  readonly pageSize?: number;
  readonly maxListSize?: number;
  readonly onChange?: (o: IPaginationFilter) => void;
  readonly ehFilterProps?: FilterProps;
  readonly tableSpacing?: JSX.Element;
  /* disable client side sorting and send to the server "orderBy" and "desc" for mapped column */
  readonly enableServerSideSorting?: boolean;
  /* expected object { fieldName: 'column fieldName', isSortedDescending: true } */
  readonly initialSort?: ITableSort;
  readonly isFixedHeader?: boolean;
  readonly maxHeight?: number;
}

const getButtonStyles = (theme: ITheme): IButtonStyles => {
  return {
    label: {
      color: theme.semanticColors.inputIcon,
    },
  };
};

const getTableStyles = (theme: ITheme): string => {
  return mergeStyles({
    selectors: {
      '[class^="ms-DetailsHeader-cellName"]': {
        fontWeight: `${FontWeights.bold} !important`,
      },
      '.ms-List [role="row"]': {
        borderBottom: `1px solid ${theme.semanticColors.bodyFrameDivider} !important`,
        minHeight: '50px',
      },
      '[role="gridcell"]': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  });
};

const getTableWrapperStyles = (maxHeight: Maybe<number>): string => {
  return mergeStyles({
    overflow: 'auto',
    maxHeight: maxHeight ? `${maxHeight}px` : 'auto',
    selectors: {
      '[class^="ms-DetailsList-headerWrapper"]': {
        position: 'sticky',
        top: 0,
        zIndex: 1000,
      },
    },
  });
};

export const EHTable = ({
  pageSize = 5,
  maxListSize = 2000,
  onChange,
  ehFilterProps,
  tableSpacing,
  enableServerSideSorting,
  initialSort,
  isFixedHeader,
  maxHeight,
  ...props
}: IEHTable): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const initialPage: IPaginationFilter = {
    take: pageSize,
    skip: 0,
  };
  const [page, setPage] = useState<IPaginationFilter>(initialPage);
  const [sort, setSort] = useState<ITableSort>({ ...initialSort, isSorted: true });

  useEffect(() => {
    if (enableServerSideSorting) {
      const column = props.columns.find(c => c.fieldName == sort.fieldName);
      /* set initial sorting and disable client side sorting */
      !!column && Object.assign(column, sort, { sortBy: () => () => 1 });
    }
  }, [props.columns]);

  useEffect(() => {
    if (page.skip + page.take != pageSize) {
      !!onChange &&
        onChange({
          ...page,
          ...(enableServerSideSorting
            ? { orderBy: capitalize(sort.fieldName), desc: sort.isSortedDescending }
            : {}),
        });
    }
  }, [page]);

  const capitalize = (s: Maybe<string>): string => {
    return (!!s && s[0].toUpperCase() + s.slice(1)) || '';
  };

  const onSortedByColumns = (columns: IElxColumn[]): void => {
    if (enableServerSideSorting && columns.length) {
      const { fieldName, isSorted, isSortedDescending } = columns[0];
      /* Cannot update a component (`EHTable`) while rendering a different component fix */
      setTimeout(() => {
        setSort({
          fieldName,
          isSorted,
          isSortedDescending,
        });
        setPage(initialPage);
        !!onChange &&
          onChange({
            ...initialPage,
            orderBy: capitalize(fieldName),
            desc: isSortedDescending,
          });
      });
    }
  };

  return (
    <>
      {ehFilterProps && <EHFilter {...ehFilterProps} />}
      {tableSpacing}
      <div className={isFixedHeader ? getTableWrapperStyles(maxHeight) : ''}>
        <ElxTable
          className={classnames(getTableStyles(theme), props.className)}
          onSortedByColumns={onSortedByColumns}
          selectionMode={SelectionMode.none}
          {...props}
        />
      </div>
      {maxListSize > props.items.length && (
        <EHCommandBar
          items={[
            {
              key: 'seeMore',
              text: t('Main.SeeMore'),
              buttonStyles: getButtonStyles(theme),
              onClick: () =>
                !!page.take &&
                setPage({ ...initialPage, take: page.take, skip: page.skip + pageSize }),
              id: 'businessreview-review-seemorepagination',
              role: 'menuitem',
            },
          ]}
        />
      )}
    </>
  );
};
