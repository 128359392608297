import { fetchAsync, useSelectCustomerId } from '@engagehub/core';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useReviewId, useSelectUserContext } from '@features';
import { ElxMessageBarType } from '@elixir/components';
import { IReviewContext, ReviewContext, ReviewStatus } from '@pages';
import { useMessaging } from '@utils/messaging';
import { useDispatch } from 'react-redux';

import { AxiosError } from 'axios';
import { constructCustomerScope } from '@utils/scope';
import { IEditReviewFields } from '../helper';
import { reloadReview } from '../store/store';
import { invalidateReviewQueries } from '../query';

export const useEditReview = (): UseMutateFunction<
  unknown,
  unknown,
  IEditReviewFields,
  unknown
> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { setLoading, setMessage, setStatus } = useContext<IReviewContext>(ReviewContext);
  const customerId = useSelectCustomerId();
  const user = useSelectUserContext();
  const { standardErrorMessage, onError } = useMessaging();
  const message = {
    ...standardErrorMessage,
    onDismiss: (): void => setMessage(undefined),
  };
  const reviewId = useReviewId();

  const { isLoading, isError, mutate } = useMutation(
    async ({ id, name, description }: IEditReviewFields) =>
      fetchAsync(`/br/processes/reviews/${id}/info`, {
        method: 'PUT',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
        data: {
          name,
          description,
          updatedBy: user?.upn,
        },
      }),
    {
      onSuccess: () => {
        invalidateReviewQueries(queryClient, customerId);
        dispatch(reloadReview(reviewId as string));
        setStatus?.(ReviewStatus.VIEW);
        setMessage({
          type: ElxMessageBarType.success,
          message: t('ReviewLanding.SuccessSaveChangesMessage'),
          onDismiss: (): void => setMessage(undefined),
          isMultiline: false,
        });
      },
      onError: error => {
        onError(error as AxiosError);
      },
    },
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    isError && setMessage(message);
  }, [isError]);

  return mutate;
};
