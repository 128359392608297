import { OpenCanvasDashboard } from '@features/opencanvas';
import { useReviewTypeInfo } from '@features/reviews';
import React from 'react';
import { DashboardMetrics } from './metrics';
import { CaseInsightsDashboard } from './caseInsights';

export const DashboardViewEngineSwitch = (): JSX.Element | null => {
  const { viewEngine } = useReviewTypeInfo() ?? {};

  switch (viewEngine) {
    case 'OpenCanvas':
      return <OpenCanvasDashboard />;
    case 'Legacy':
      return <DashboardMetrics />;
    case 'CaseInsights':
      return <CaseInsightsDashboard />;
    case 'None':
    default:
      return null;
  }
};
