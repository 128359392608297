import React from 'react';
import { ElxPanel, ElxTooltip, IElxPanelProps } from '@elixir/components';
import { Text } from '@fluentui/react';

const headerTextStyle = { display: 'grid' };

export const EHPanel = (props: IElxPanelProps): JSX.Element => {
  return (
    <ElxPanel
      onRenderHeader={({ headerText }): JSX.Element => {
        return (
          <ElxTooltip content={headerText}>
            <div style={headerTextStyle}>
              <Text block={true} nowrap={true} variant={'xLarge'}>
                {headerText}
              </Text>
            </div>
          </ElxTooltip>
        );
      }}
      {...props}
    />
  );
};
