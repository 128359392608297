export enum Module {
  BusinessReview = 'BusinessReview',
}

export const debounceTime = 500;
export const adminApiVersion = '2.0';

export const reviewTypeOptions = [
  { key: 'default', text: 'Default' },
  { key: 'mirp', text: 'MIRP' },
  { key: 'ace', text: 'ACE' },
  { key: 'resiliency', text: 'Resiliency' },
];
