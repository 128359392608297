import React from 'react';
import { ITextFieldProps, TextField } from '@fluentui/react';
import { FieldHelperProps, FieldMetaProps } from 'formik';
import { EHFieldWrapper } from '../EHFieldWrapper';
import { FormContext, IFormContext, IName } from '../EHForm';

type IEHFieldNumber = ITextFieldProps & IName;

interface ISHFieldNumberProps extends ITextFieldProps {
  readonly meta: FieldMetaProps<unknown>;
  readonly helpers: FieldHelperProps<unknown>;
  readonly value: string;
}

const DecimalBase = 10;
const validateInput = (input: number, minLimit: number, maxLimit: number): boolean =>
  !isNaN(input) && input >= minLimit && input <= maxLimit;

export const EHFieldNumber = ({ name, min, max, ...props }: IEHFieldNumber): JSX.Element => {
  const { fieldsConfiguration } = React.useContext<IFormContext>(FormContext);

  return (
    <EHFieldWrapper name={name}>
      {({ value, meta, helpers, ...field }: ISHFieldNumberProps): JSX.Element => {
        return (
          <TextField
            max={max}
            min={min}
            type="number"
            {...props}
            {...field}
            errorMessage={meta.error}
            onBlur={(_e: React.FocusEvent<HTMLTextAreaElement>): void => {
              const newValue = parseInt(_e.target.value, DecimalBase);
              const updatedValue = validateInput(newValue, Number(min), Number(max))
                ? newValue
                : value;
              helpers.setValue(Number(updatedValue));
            }}
            onChange={(
              _e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              target: string,
            ): void => {
              if (target === '') return;
              const newValue = parseInt(target, DecimalBase);
              if (!isNaN(newValue)) {
                const updatedValue = validateInput(newValue, Number(min), Number(max))
                  ? newValue
                  : value;
                helpers.setValue(Number(updatedValue));
              }
            }}
            value={value}
            {...fieldsConfiguration?.[name]}
          />
        );
      }}
    </EHFieldWrapper>
  );
};
