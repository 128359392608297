/* eslint-disable max-lines-per-function */
import { Stack } from '@fluentui/react';
import React, { useMemo } from 'react';
import { EHCheckbox, EHFieldDropdown, EHTagPicker, EHTextField } from '@components';
import { TagContainerOrientation } from '@elixir/components';
import { useFormikContext } from 'formik';
import { IChildDetailContext } from '@pages/Admin';
import { reviewTypeOptions } from '@utils/store/constants';
import { IConfigureAdoFields, IConfigureTemplateFields, useTemplates } from '../hooks';

const styles = {
  label: {
    fontWeight: 600,
  },
  form: {
    height: '100%',
  },
};

export interface IAdoFormProps {
  ctx: IChildDetailContext<IConfigureTemplateFields | IConfigureAdoFields>;
  isConfigureTemplate?: boolean;
}

export const AdoForm = ({
  isConfigureTemplate,
}: {
  isConfigureTemplate?: boolean;
}): JSX.Element => {
  const { values } = useFormikContext<IConfigureTemplateFields>();
  const { data, isLoading } = useTemplates(values.targetScope);

  const options = useMemo(
    () => data?.map(i => ({ key: i.id, text: i.name?.split(' ')[0] })) || [],
    [data],
  );

  return (
    <Stack tokens={{ childrenGap: 16, padding: 24 }}>
      <EHTextField
        aria-label={'targetScope'}
        id="target-scope-field"
        label="Target Scope"
        name="targetScope"
        placeholder="Enter Target Scope"
        required
      />
      <EHFieldDropdown
        aria-label={'reviewType'}
        id="reviewType-field"
        label="Review Type"
        name="reviewType"
        options={reviewTypeOptions}
        placeholder="Select Review Type"
      />
      {isConfigureTemplate && (
        <EHFieldDropdown
          aria-label={'template'}
          id="ado-template-field"
          isLoading={isLoading}
          label="Template"
          name="templateId"
          options={options}
          placeholder="Select Template Type"
        />
      )}
      <EHTextField
        id="ado-organization-field"
        label="ADO Organization"
        name="organizationId"
        placeholder="Enter ADO Organization"
      />
      <EHTextField
        id="ado-project-field"
        label="ADO Project"
        name="projectId"
        placeholder="Enter ADO Project"
      />
      <EHTextField
        id="ado-area-path-field"
        label="ADO Area Path"
        name="areaPath"
        placeholder="Enter Area Path"
      />
      <EHTagPicker
        id="ado-work-item-types-field"
        label="ADO Work Item Types"
        name="workItemTypes"
        orientation={TagContainerOrientation.Horizontal}
        placeholder="Enter ADO Work Item Types"
      />
      <Stack tokens={{ childrenGap: 4 }}>
        <label
          htmlFor="is-process-exclusive-checkbox"
          id="is-process-exclusive-checkbox-label"
          style={styles.label}>
          {'Is Process Exclusive'}
        </label>
        <EHCheckbox
          aria-label="is process exclusive"
          id="is-process-exclusive-checkbox"
          name="isProcessExclusive"
        />
      </Stack>
    </Stack>
  );
};
