/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseMutateFunction, useMutation } from 'react-query';
import { IReviewNote } from '@features';
import { fetchAsync, useSelectCustomerId } from '@engagehub/core';
import { INote } from '@components/EHNote/types';
import { useDispatch } from 'react-redux';
import { reloadReview } from '@features/reviews/store/store';
import { useContext, useEffect } from 'react';
import { DetailsContext, IDetailsContext } from '@components/EHDetails';
import { constructCustomerScope } from '@utils/scope';

export function useUpdateReviewNotes(
  reviewId: string,
): UseMutateFunction<IReviewNote, unknown, unknown, unknown> {
  const dispatch = useDispatch();
  const customerId = useSelectCustomerId();
  const { setError, setLoading } = useContext<IDetailsContext>(DetailsContext);

  const create = (note: INote): Promise<IReviewNote> => {
    return fetchAsync<IReviewNote>(`/br/processes/reviews/${reviewId}/notes`, {
      method: 'POST',
      headers: {
        'x-eh-scope': constructCustomerScope(customerId!),
      },
      params: {
        customerId,
      },
      data: note,
    });
  };

  const update = (note: INote): Promise<IReviewNote> => {
    return fetchAsync<IReviewNote>(`/br/processes/reviews/${reviewId}/notes/${note.id}`, {
      method: 'PUT',
      headers: {
        'x-eh-scope': constructCustomerScope(customerId!),
      },
      params: {
        customerId,
      },
      data: note,
    });
  };

  const { isLoading, isError, mutate } = useMutation(
    ({ note, resetForm }: any) => {
      const data = note.id ? update(note) : create(note);
      data.then(
        () => resetForm(),
        error => error,
      );
      return data;
    },
    {
      onSuccess: () => {
        setLoading(false);
        dispatch(reloadReview(reviewId));
      },
      onError: () => setLoading(false),
    },
  );

  useEffect(() => {
    isLoading && setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setError(isError);
  }, [isError]);

  return mutate;
}

export function useDeleteReviewNote(
  reviewId: string,
): UseMutateFunction<IReviewNote, unknown, unknown, unknown> {
  const dispatch = useDispatch();
  const customerId = useSelectCustomerId();
  const { setError, setLoading } = useContext<IDetailsContext>(DetailsContext);

  const { isLoading, isError, mutate } = useMutation(
    (noteId: any) => {
      const data = fetchAsync<IReviewNote>(`/br/processes/reviews/${reviewId}/notes/${noteId}`, {
        method: 'DELETE',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
      });
      data.then(
        () => true,
        error => error,
      );
      return data;
    },
    {
      onSuccess: () => {
        setLoading(false);
        dispatch(reloadReview(reviewId));
      },
      onError: () => setLoading(false),
    },
  );

  useEffect(() => {
    isLoading && setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setError(isError);
  }, [isError]);

  return mutate;
}
