import { IWorkItemActivity, IWorkItemActivityField, IWorkItemActivityModel } from '@features';
import { TFunction } from 'react-i18next';

const isCreated = (activity: IWorkItemActivityModel): boolean =>
  activity?.fields?.some(f => f.property == 'System.CreatedDate');

const isComment = (field: IWorkItemActivityField): boolean =>
  field?.property == 'System.CommentCount' || field?.property == 'System.History';

const isDescription = (field: IWorkItemActivityField): boolean =>
  field?.property == 'System.Description';

const isReproSteps = (field: IWorkItemActivityField): boolean =>
  field?.property == 'Microsoft.VSTS.TCM.ReproSteps';

const isAssignedTo = (field: IWorkItemActivityField): boolean =>
  field?.property == 'System.AssignedTo';

const created = (activity: IWorkItemActivityModel, t: TFunction): string =>
  t('ActionItems.CreatedItem', {
    item: activity?.fields?.find(f => f.property == 'System.WorkItemType')?.newValue,
  });

const assignedTo = (field: IWorkItemActivityField, t: TFunction): string =>
  t('ActionItems.ChangedField', {
    property: 'AssignedTo',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // Returning 'Microsoft' for 'assignedTo' due to privacy issues
    newValue: 'Microsoft',
    // newValue: (field?.newValue as any)?.displayName,
  });

const commentText = (field: IWorkItemActivityField, t: TFunction): string =>
  t('ActionItems.Comment', {
    action:
      field?.property == 'System.History'
        ? t('ActionItems.EditedProperty')
        : Number(field?.newValue) > Number(field?.oldValue)
        ? t('ActionItems.AddedProperty')
        : t('ActionItems.RemovedProperty'),
  });

const fieldText = (field: IWorkItemActivityField, t: TFunction): string =>
  t('ActionItems.ChangedField', {
    property: field?.property.replace('System.', '').replace('Microsoft.VSTS.Common.', ''),
    newValue: field?.newValue,
  });

const relationFieldText = (rel: string, t: TFunction): string =>
  rel.includes('LinkTypes') ? t('ActionItems.Link') : rel;

const addedRelText = (rel: string, t: TFunction): string =>
  t('ActionItems.AddedProperty', { property: relationFieldText(rel, t) });

const removedRelText = (rel: string, t: TFunction): string =>
  t('ActionItems.RemovedProperty', { property: relationFieldText(rel, t) });

export const activitiesAdaptor = (
  data: IWorkItemActivityModel[] | undefined,
  t: TFunction,
): IWorkItemActivity[] => {
  const activities: IWorkItemActivity[] = [];
  data?.forEach(activity => {
    const text: string[] = [];
    if (activity?.relations) {
      activity?.relations?.added?.forEach(relation => text.push(addedRelText(relation.rel, t)));
      activity?.relations?.removed?.forEach(relation => text.push(removedRelText(relation.rel, t)));
    }
    if (isCreated(activity)) {
      text.push(created(activity, t));
    } else {
      activity?.fields?.forEach(field => {
        if (isComment(field)) {
          text.push(commentText(field, t));
        } else if (isAssignedTo(field)) {
          text.push(assignedTo(field, t));
        } else if (isDescription(field) || isReproSteps(field)) {
          text.push(t('ActionItems.ChangedDescription'));
        } else {
          text.push(fieldText(field, t));
        }
      });
    }
    activities.push({
      updatedBy: activity?.updatedBy,
      updatedOn: activity?.updatedOn,
      text: text.join(', '),
    });
  });
  return activities.reverse();
};
