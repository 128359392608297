import { requestErrorHelpers } from '@serviceshub/x-web-core';
import { IResult } from '@utils';
import { call, delay, getContext, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { fetchAsync, selectCustomerId } from '@engagehub/core';
import { QueryClient } from 'react-query';
import { actionItemQueryKey } from '@features/actionItems/constants';
import { constructCustomerScope } from '@utils/scope';
import { getReviewFailed, getReviewSuccess, openReviewDetailsSuccess, reloadReview } from './store';
import { InitializingReviewReloadPollingInterval } from './constants';
import { IReviewDetailsResponse, ReviewState } from '../types';

export function* evaluateReviewState(action: PayloadAction<IReviewDetailsResponse>): Generator {
  if (action.payload.state === ReviewState.Initializing) {
    yield delay(InitializingReviewReloadPollingInterval);
    yield put(reloadReview(action.payload.id));

    const context = (yield getContext('queryClient')) as QueryClient;
    context.invalidateQueries(actionItemQueryKey);
  }
}

export function* fetchReview(action: PayloadAction<string>): Generator {
  try {
    const customerId = yield select(selectCustomerId);
    const response = (yield call(fetchAsync, `/br/processes/reviews/${action.payload}`, {
      method: 'GET',
      headers: {
        'x-eh-scope': constructCustomerScope(customerId as string),
      },
      params: {
        customerId,
      },
    })) as IResult<IReviewDetailsResponse>;
    // local server work around - the id returned is always the same..
    if (response?.value) {
      response.value.id = action.payload;
    }
    yield put(getReviewSuccess(response?.value));
  } catch (error) {
    yield put(getReviewFailed(new Error(requestErrorHelpers.getRequestErrorMessage(error))));
  }
}

export function* fetchReviewDetails(action: PayloadAction<string>): Generator {
  try {
    const customerId = yield select(selectCustomerId);
    const response = (yield call(fetchAsync, `/br/processes/reviews/${action.payload}`, {
      method: 'GET',
      headers: {
        'x-eh-scope': constructCustomerScope(customerId as string),
      },
      params: {
        customerId,
      },
    })) as IResult<IReviewDetailsResponse>;
    yield put(openReviewDetailsSuccess(response?.value));
  } catch (error) {
    yield put(getReviewFailed(new Error(requestErrorHelpers.getRequestErrorMessage(error))));
  }
}
