import { IPagedResult } from '@utils';
import { useQuery } from 'react-query';
import { useContext, useEffect } from 'react';
import { IWorkItemActivity, IWorkItemActivityModel } from '@features';
import { DetailsContext, IDetailsContext } from '@components/EHDetails';
import { useTranslation } from 'react-i18next';
import { fetchAsync, useSelectCustomerId } from '@engagehub/core';
import { constructCustomerScope } from '@utils/scope';
import { activitiesAdaptor } from './helper';

export const useTaskActivity = (
  workItemId: string | undefined,
  reviewId: string | undefined,
): IWorkItemActivity[] | undefined => {
  const { t } = useTranslation();
  const customerId = useSelectCustomerId();
  const { setError, setLoading } = useContext<IDetailsContext>(DetailsContext);

  const { isLoading, isError, data } = useQuery(
    ['taskActivities', { workItemId }],
    () =>
      fetchAsync<IPagedResult<IWorkItemActivityModel>>(
        `/br/processes/reviews/${reviewId}/work-items/${workItemId}/updates`,
        {
          method: 'GET',
          headers: {
            'x-eh-scope': constructCustomerScope(customerId!),
          },
          params: {
            customerId,
          },
        },
      ).then(resp => resp.values),
    {
      enabled: !!workItemId,
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setError(isError);
  }, [isError]);

  return activitiesAdaptor(data, t);
};
