import { IReviewContext, ReviewContext } from '@pages';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface IReviewCreateActions {
  setDraftConfirm: (o: boolean) => void;
  setCancelConfirm: (o: boolean) => void;
  isValid: boolean;
  dirty: boolean;
}

export const useReviewCreateActions = ({
  setDraftConfirm,
  setCancelConfirm,
  isValid,
  dirty,
}: IReviewCreateActions): void => {
  const { t } = useTranslation();
  const { setActions } = useContext<IReviewContext>(ReviewContext);

  useEffect(() => {
    setActions([
      {
        id: 'businessreview-review-saveasdraftopendialog',
        key: 'draft',
        text: t('ReviewLanding.SaveAsDraft'),
        onClick: (): void => setDraftConfirm(true),
        type: 'submit',
        disabled: !dirty || !isValid,
      },
      {
        id: 'businessreview-review-saveasdraftcancel',
        key: 'cancel',
        text: t('ReviewLanding.Cancel'),
        onClick: (): void => setCancelConfirm(true),
      },
    ]);
  }, [isValid, dirty]);
};
