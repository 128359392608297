import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { EHActionButton, EHFieldDropdown, EHTextField } from '@components';
import { Stack } from '@fluentui/react';
import { AdminPageContext, IAdminPageContext } from '@pages/Admin';
import { reviewTypeOptions } from '@utils/store/constants';
import { IConfigureOpenCanvasFields } from './configureOpenCanvas';
import { useCreateOpenCanvasReport } from './hooks';
import { useTemplates } from '../hooks';

export const OpenCanvasForm = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<IConfigureOpenCanvasFields>();
  const { data: templates, isLoading: isTemplatesLoading } = useTemplates(values.targetScope);
  const {
    mutate: createReport,
    data: createReportData,
    isLoading: isCreateLoading,
  } = useCreateOpenCanvasReport();
  const { setIsLoading } = React.useContext<IAdminPageContext>(AdminPageContext);

  useEffect(() => {
    setIsLoading(isCreateLoading);
  }, [isCreateLoading]);

  useEffect(() => {
    if (createReportData?.reportId) setFieldValue('reportId', createReportData?.reportId);
  }, [createReportData]);

  const options = useMemo(
    () =>
      templates
        ?.filter(t => t.templateViewEngine === 'OpenCanvas')
        .map(t => ({ key: t.id, text: t.name?.split(' ')[0] })) || [],
    [templates],
  );

  return (
    <Stack tokens={{ childrenGap: 16, padding: 24 }}>
      <EHTextField
        aria-label={'targetScope'}
        id="targetScope-field"
        label="Target Scope"
        name="targetScope"
        placeholder="Enter Target Scope"
        required
      />
      <EHFieldDropdown
        aria-label={'reviewType'}
        id="reviewType-field"
        label="Review Type"
        name="reviewType"
        options={reviewTypeOptions}
        placeholder="Select Review Type"
      />
      <EHFieldDropdown
        aria-label={'template'}
        disabled={!values.targetScope}
        id="ado-template-field"
        isLoading={!!values.targetScope && isTemplatesLoading}
        label="Template"
        name="templateId"
        options={options}
        placeholder="Select Template Type"
      />
      <EHActionButton
        disabled={!values.targetScope}
        iconProps={{ iconName: 'NewAnalyticsQuery' }}
        onClick={(): void =>
          createReport({ targetScope: values.targetScope, templateType: 'MIRP' })
        }
        text="Create Mirp Report"
      />
      <EHTextField
        id="ace-report-id-field"
        label="Open Canvas Report Id"
        name="reportId"
        placeholder="Enter Open Canvas Report Id"
      />
    </Stack>
  );
};
