import React, { useContext, useEffect, useState } from 'react';
import { EHPivot } from '@components/EHPivot';
import { ActionItemsViewer, useReviewTypeInfo } from '@features';
import { Dashboard } from '@features/dashboard/component';
import { IReviewContext, ReviewContext } from '@pages';
import { useTranslation } from 'react-i18next';
import { EHTagContainer } from '@components/EHTagContainer';
import { TagSeverity } from '@elixir/components';
import { Training } from '@features/training';

enum TabStatus {
  ACTION_ITEMS = '1',
  DASHBOARD = '2',
  TRAINING = '3',
}

const warningStyles = { root: { marginTop: '8px' } };

export const ReviewTabs = (): JSX.Element => {
  const ctx = useContext<IReviewContext>(ReviewContext);
  const { t } = useTranslation();
  const [tab, setTab] = useState(TabStatus.DASHBOARD);
  const reviewTypeInfo = useReviewTypeInfo();

  const items = [
    {
      itemKey: TabStatus.ACTION_ITEMS,
      headerText: t('ActionItems.OpenActionItems'),
    },
  ];
  if (reviewTypeInfo?.isDashboardSupported) {
    items.unshift({
      itemKey: TabStatus.DASHBOARD,
      headerText: t('Dashboard.Dashboard'),
    });
  }
  if (reviewTypeInfo?.isTraining) {
    items.push({
      itemKey: TabStatus.TRAINING,
      headerText: t('Training.Training'),
    });
  }

  useEffect(() => {
    if (reviewTypeInfo && !reviewTypeInfo.isDashboardSupported) {
      setTab(TabStatus.ACTION_ITEMS);
    }
  }, [reviewTypeInfo?.isDashboardSupported]);

  return (
    <>
      <EHPivot
        items={items}
        onItemSelect={(selectedKey: string): void => setTab(selectedKey as TabStatus)}
        selectedKey={tab}
      />
      {reviewTypeInfo && !reviewTypeInfo.isDashboardSupported && (
        <EHTagContainer
          data={[
            {
              key: '1',
              text: t('OperationalReview.DashboardNotAvailable', { name: reviewTypeInfo.name }),
              severity: TagSeverity.Warning,
            },
          ]}
          styles={warningStyles}
        />
      )}
      {tab === TabStatus.DASHBOARD && <Dashboard containerCtx={ctx} />}
      {tab === TabStatus.ACTION_ITEMS && <ActionItemsViewer containerCtx={ctx} />}
      {tab === TabStatus.TRAINING && <Training />}
    </>
  );
};
