import React from 'react';
import { ElxDialog, IElxDialogProps } from '@elixir/components';

export interface IEHDialogProps extends IElxDialogProps {
  contentTitle: string;
  children: JSX.Element;
}

export const EHDialog = ({ children, ...props }: IEHDialogProps): JSX.Element => {
  return (
    <ElxDialog
      dialogContentProps={{ title: props.contentTitle, showCloseButton: false }}
      dismissable={false}
      {...props}>
      {children}
    </ElxDialog>
  );
};
