import React from 'react';
import { EHActionButton } from '@components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openReviewDetailsPanel } from '../store/store';

export * from './panel';

export const ReviewDetailsButton = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reviewDetailsButton = {
    key: 'edit',
    iconProps: { iconName: 'EditNote' },
    text: t('OperationalReview.ReviewDetails'),
    id: 'businessreview-review-reviewdetails',
    onClick: (): void => {
      dispatch(openReviewDetailsPanel());
    },
  };

  return <EHActionButton {...reviewDetailsButton} />;
};
