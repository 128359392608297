/* eslint-disable max-lines-per-function */
import { IElxMasterDetailTabProps } from '@elixir/components';
import React from 'react';
import { AdminPageContext, IAdminPageContext } from '@pages/Admin';
import { ConfigureAdo } from '../ado/configureAdo';
import { CreateCustomerProcess } from '../create/createCustomerProcess';
import { ConfigureInsights } from '../insights/configureInsights';
import { ConfigureTemplate } from '../templates';
import { ConfigureActionItems } from '../actionItems';
import { ConfigureOpenCanvas } from '../opencanvas';

export const useTabs = (): IElxMasterDetailTabProps[] => {
  const adminCtx = React.useContext<IAdminPageContext>(AdminPageContext);

  return [
    {
      key: '1',
      name: 'Create Customer Process',
      component: CreateCustomerProcess(),
      message: adminCtx.createCustomerProcess.message,
      primaryButton: {
        text: 'Create',
        onClick: (event: React.MouseEvent<unknown>): void => {
          event.preventDefault();
          adminCtx.createCustomerProcess.innerRef.current?.handleSubmit();
        },
      },
      cancelButton: {
        onClick: (): void => adminCtx.createCustomerProcess.innerRef.current?.resetForm(),
      },
    },
    {
      key: '2',
      name: 'Configure ADO',
      component: ConfigureAdo(),
      message: adminCtx.configureAdo.message,
      primaryButton: {
        text: 'Configure',
        onClick: (event: React.MouseEvent<unknown>): void => {
          event.preventDefault();
          adminCtx.configureAdo.innerRef.current?.handleSubmit();
        },
      },
      cancelButton: {
        onClick: (): void => adminCtx.configureAdo.innerRef.current?.resetForm(),
      },
    },
    {
      key: '3',
      name: 'Configure Template',
      component: ConfigureTemplate(),
      message: adminCtx.configureTemplate.message,
      primaryButton: {
        text: 'Configure',
        onClick: (event: React.MouseEvent<unknown>): void => {
          event.preventDefault();
          adminCtx.configureTemplate.innerRef.current?.handleSubmit();
        },
      },
      cancelButton: {
        onClick: (): void => adminCtx.configureTemplate.innerRef.current?.resetForm(),
      },
    },
    {
      key: '4',
      name: 'Configure Insights',
      component: ConfigureInsights(),
      message: adminCtx.configureInsights.message,
      primaryButton: {
        text: 'Configure',
        onClick: (event: React.MouseEvent<unknown>): void => {
          event.preventDefault();
          adminCtx.configureInsights.innerRef.current?.handleSubmit();
        },
      },
      cancelButton: {
        onClick: (): void => adminCtx.configureInsights.innerRef.current?.resetForm(),
      },
    },
    {
      key: '5',
      name: 'Configure Open Canvas',
      component: ConfigureOpenCanvas(),
      message: adminCtx.configureOpenCanvas.message,
      primaryButton: {
        text: 'Configure',
        onClick: (event: React.MouseEvent<unknown>): void => {
          event.preventDefault();
          adminCtx.configureOpenCanvas.innerRef.current?.handleSubmit();
        },
      },
      cancelButton: {
        onClick: (): void => adminCtx.configureOpenCanvas.innerRef.current?.resetForm(),
      },
    },
    {
      key: '6',
      name: 'Configure Action Items',
      component: ConfigureActionItems(),
      message: adminCtx.createActionItem.message,
      primaryButton: {
        text: 'Configure',
        onClick: (event: React.MouseEvent<unknown>): void => {
          event.preventDefault();
          adminCtx.createActionItem.innerRef.current?.handleSubmit();
        },
      },
      cancelButton: {
        onClick: (): void => adminCtx.createActionItem.innerRef.current?.resetForm(),
      },
    },
  ];
};
