import { EHActionButton } from '@components';
import { ElxTooltip, IElxColumn, ITableAction } from '@elixir/components';
import { ColumnActionsMode, ITheme, Text, mergeStyles, useTheme } from '@fluentui/react';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { clickTracking, dayjs } from '@utils';
import { useSelectWriteOperationEnabled } from '@features/context';
import { useQueryClient } from 'react-query';
import { ActivityColumn } from './activityColumn';
import { IWorkItemResponse } from '..';

const getButtonStyles = (theme: ITheme): string => {
  return mergeStyles({
    color: theme.semanticColors.inputIcon,
  });
};

export interface IActionItemsColumns {
  readonly setItem: Dispatch<IWorkItemResponse>;
}

const headerTextStyle = { display: 'grid' };

// eslint-disable-next-line max-lines-per-function
export const columns = ({ setItem }: IActionItemsColumns): IElxColumn[] => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();

  return [
    {
      key: 'name',
      name: t('ActionItems.Task'),
      fieldName: 'title',
      minWidth: 100,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IWorkItemResponse) => (
        <EHActionButton
          className={getButtonStyles(theme)}
          id={`businessreview-actionitemviewdetails-${item.id}`}
          onClick={(): void => {
            const workItemId = item.id;
            queryClient.invalidateQueries(['taskDetailsCounts', { workItemId }]);
            queryClient.invalidateQueries(['taskActivities', { workItemId }]);
            queryClient.invalidateQueries(['workItemComments', { workItemId }]);
            setItem(item);
          }}
          onRenderText={(): JSX.Element => {
            return (
              <ElxTooltip content={item.title}>
                <div style={headerTextStyle}>
                  <Text block={true}>{item.title}</Text>
                </div>
              </ElxTooltip>
            );
          }}
          role={'link'}
          text={item.title}
        />
      ),
    },
    {
      key: 'status',
      name: t('ActionItems.Status'),
      fieldName: 'status',
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'severity',
      name: t('ActionItems.Severity'),
      fieldName: 'severity',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'assignedTo',
      name: t('ActionItems.Assigned'),
      fieldName: 'assignedTo',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'type',
      name: t('ActionItems.Type'),
      fieldName: 'type',
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'createdOn',
      name: t('ActionItems.Created'),
      fieldName: 'createdOn',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onRender: item => <span>{dayjs(item.createdOn).format('ll, ddd')}</span>,
      columnActionsMode: ColumnActionsMode.disabled,
    },
    {
      key: 'activity',
      name: t('ActionItems.Activity'),
      fieldName: 'updatedOn',
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onRender: item => <ActivityColumn date={item.updatedOn} propertyChanged={''} />,
      columnActionsMode: ColumnActionsMode.disabled,
    },
  ];
};

export const actions = ({
  setEditItem,
}: {
  setEditItem: Dispatch<IWorkItemResponse>;
}): ITableAction[] => {
  const { t } = useTranslation();
  const isWriteOperationEnabled = useSelectWriteOperationEnabled();

  return [
    {
      key: 'edittask',
      text: t('ActionItems.EditTask'),
      onAction: (item: IWorkItemResponse): void => {
        clickTracking(`businessreview-actionitem-${item.id}`, 'Edit Task');
        setEditItem(item);
      },
      iconProps: { iconName: 'Edit' },
      disabled: !isWriteOperationEnabled,
    },
  ];
};
