import { IMessage } from '@elixir/components';
import { AdminContainer, IConfigureInsightsFields, ICreateCustomerProcessFields } from '@features';
import { ITemplateActionItems } from '@features/admin/actionItems/template';
import { IConfigureAdoFields, IConfigureTemplateFields } from '@features/admin/hooks';
import { IConfigureOpenCanvasFields } from '@features/admin/opencanvas';
import { FormikProps } from 'formik';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  createContext,
  useRef,
  useState,
} from 'react';

export interface IChildDetailContext<FieldType> {
  message: IMessage | undefined;
  setMessage: Dispatch<SetStateAction<IMessage | undefined>>;
  innerRef: MutableRefObject<FormikProps<FieldType> | undefined>;
}

export interface IAdminPageContext {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  createCustomerProcess: IChildDetailContext<ICreateCustomerProcessFields>;
  createActionItem: IChildDetailContext<ITemplateActionItems>;
  configureAdo: IChildDetailContext<IConfigureAdoFields>;
  configureInsights: IChildDetailContext<IConfigureInsightsFields>;
  configureTemplate: IChildDetailContext<IConfigureTemplateFields>;
  configureOpenCanvas: IChildDetailContext<IConfigureOpenCanvasFields>;
}

export const AdminPageContext = createContext<IAdminPageContext>({} as IAdminPageContext);

export const AdminPage = (): JSX.Element => {
  const createCustomerFormRef = useRef<FormikProps<ICreateCustomerProcessFields>>();
  const createActionItemRef = useRef<FormikProps<ITemplateActionItems>>();
  const configureInsightsFormRef = useRef<FormikProps<IConfigureInsightsFields>>();
  const configureAdoFormRef = useRef<FormikProps<IConfigureAdoFields>>();
  const configureTemplateFormRef = useRef<FormikProps<IConfigureTemplateFields>>();

  const configureOpenCanvas = useRef<FormikProps<IConfigureOpenCanvasFields>>();
  const [isLoading, setIsLoading] = useState(false);

  const [createProcessMessage, setCreateProcessMessage] = useState<IMessage | undefined>(undefined);
  const [createActionItemMessage, setCreateActionItemMessage] = useState<IMessage | undefined>(
    undefined,
  );
  const [configureInsightsMessage, setConfigureInsightsMessage] = useState<IMessage | undefined>(
    undefined,
  );
  const [configureAdoMessage, setConfigureAdoMessage] = useState<IMessage | undefined>(undefined);
  const [configureTemplateMessage, setConfigureTemplateMessage] = useState<IMessage | undefined>(
    undefined,
  );
  const [configureOpenCanvasMessage, setConfigureOpenCanvasMessage] = useState<
    IMessage | undefined
  >(undefined);

  const context: IAdminPageContext = {
    setIsLoading,
    isLoading,
    createCustomerProcess: {
      innerRef: createCustomerFormRef,
      message: createProcessMessage,
      setMessage: setCreateProcessMessage,
    },
    configureAdo: {
      innerRef: configureAdoFormRef,
      message: configureInsightsMessage,
      setMessage: setConfigureInsightsMessage,
    },
    configureInsights: {
      innerRef: configureInsightsFormRef,
      message: configureAdoMessage,
      setMessage: setConfigureAdoMessage,
    },
    configureTemplate: {
      innerRef: configureTemplateFormRef,
      message: configureTemplateMessage,
      setMessage: setConfigureTemplateMessage,
    },
    configureOpenCanvas: {
      innerRef: configureOpenCanvas,
      message: configureOpenCanvasMessage,
      setMessage: setConfigureOpenCanvasMessage,
    },
    createActionItem: {
      innerRef: createActionItemRef,
      message: createActionItemMessage,
      setMessage: setCreateActionItemMessage,
    },
  };

  return (
    <>
      <AdminPageContext.Provider value={context}>
        <AdminContainer />
      </AdminPageContext.Provider>
    </>
  );
};
