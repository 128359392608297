import * as yup from 'yup';
import React, { MutableRefObject, useEffect } from 'react';
import { EHForm } from '@components';
import { FormikProps } from 'formik';
import { ElxMessageBarType } from '@elixir/components';
import { getErrorFromAxiosError, successMessage } from '@features';
import { AdminPageContext, IAdminPageContext } from '@pages/Admin';
import { useConfigureAdo } from './hooks';
import { IConfigureAdoFields } from '../hooks';
import { AdoForm } from '../adoForm';

const initialValues: IConfigureAdoFields = {
  targetScope: '',
  reviewType: '',
  organizationId: '',
  projectId: '',
  workItemTypes: ['Task', 'Bug', 'Feature'],
  areaPath: '',
  isProcessExclusive: false,
};

const styles = {
  label: {
    fontWeight: 600,
  },
  form: {
    height: '100%',
  },
};

const schema = yup.object().shape({
  targetScope: yup.string().required('Target Scope is required'),
  reviewType: yup.string().required('Review Type is required'),
  organizationId: yup.string().required('ADO Organization is required'),
  projectId: yup.string().required('ADO Project is required'),
  areaPath: yup.string().required('ADO WorkItemTypes is required'),
});

// eslint-disable-next-line max-lines-per-function
export const ConfigureAdo = (): JSX.Element => {
  const { mutate: create, isLoading, error, isSuccess } = useConfigureAdo();
  const { configureAdo, ...adminCtx } = React.useContext<IAdminPageContext>(AdminPageContext);
  useEffect(() => adminCtx.setIsLoading(isLoading), [isLoading]);
  useEffect(() => {
    if (isSuccess) configureAdo.setMessage(successMessage);
  }, [isSuccess]);
  useEffect(() => {
    if (error) {
      configureAdo.setMessage({
        message: getErrorFromAxiosError(error),
        type: ElxMessageBarType.error,
      });
    } else configureAdo.setMessage(undefined);
  }, [error]);

  return (
    <EHForm
      initialValues={initialValues}
      innerRef={configureAdo.innerRef as MutableRefObject<FormikProps<IConfigureAdoFields> | null>}
      onSubmit={(values): void => create(values)}
      style={styles.form}
      validationSchema={schema}>
      {(): JSX.Element => {
        return (
          <>
            <AdoForm />
          </>
        );
      }}
    </EHForm>
  );
};
