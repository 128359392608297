/* eslint-disable max-lines-per-function */
import React, { CSSProperties, createRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IReviewDetailsResponse, ReviewState, ReviewTags } from '@features';
import { dayjs } from '@utils';
import { EHActionButton, EHFormLabel, EHLayout } from '@components';
import { IReviewContext, ReviewContext } from '@pages';
import { ElxMessageBarType } from '@elixir/components';
import { useReviewViewActions } from './actions';
import { useReviewTemplates } from './hooks';
import { DialogPublish } from './dialogPublish';
import { useWorkloadOptions } from '../form/workload/hooks';

interface IReviewView {
  readonly review: IReviewDetailsResponse;
}

const formatDate = (date: Date): string => dayjs(date).format('LL');

const propertyStyles: CSSProperties = {
  margin: '0',
  padding: '0',
};

export const ReviewView = ({ review }: IReviewView): JSX.Element => {
  const [openPublishConfirm, setOpenPublishConfirm] = useState(false);
  const [isInitializing, setIsInitializing] = useState(false);
  useReviewViewActions(setOpenPublishConfirm);
  const { t } = useTranslation();
  const { setLoading, setMessage } = useContext<IReviewContext>(ReviewContext);
  const { data: reviewData, isLoading: isTemplatesLoading } = useReviewTemplates();
  const { data: workloadData } = useWorkloadOptions(!!review.reviewDataParameters?.WorkloadId);
  const [openSummary, setOpenSummary] = useState(false);
  const workloadId = review.reviewDataParameters?.WorkloadId;
  const workload = workloadData?.find(i => i.id === workloadId);
  const reviewWorkload = !!workloadId && !!workload ? workload?.name : 'N/A';
  const ref = createRef<HTMLElement>();
  const templateSelected = reviewData?.find(i => i.id == review?.templateId);
  const hideReviewDateRange = templateSelected?.tags[ReviewTags.HideReviewDateRange] === 'true';
  const hideReviewMeetingDate = templateSelected?.tags[ReviewTags.HideReviewMeetingDate] === 'true';

  useEffect(() => {
    ref && ref.current && ref.current.setAttribute('aria-expanded', openSummary ? 'true' : 'false');
  }, [ref, openSummary]);

  useEffect(() => {
    if (review.state === ReviewState.Initializing) {
      setMessage({
        message: t('OperationalReview.ReviewInitializing'),
        type: ElxMessageBarType.warning,
      });
      setIsInitializing(true);
    } else if (isInitializing) {
      setMessage({
        message: t('OperationalReview.ReviewInitialized'),
        type: ElxMessageBarType.success,
      });
      setIsInitializing(false);
    }
  }, [review.state]);

  useEffect(() => {
    setLoading(!!isTemplatesLoading, 'templates');
  }, [isTemplatesLoading]);

  return (
    <>
      <DialogPublish onClick={setOpenPublishConfirm} open={openPublishConfirm} />
      <EHActionButton
        elementRef={ref}
        iconProps={{ iconName: openSummary ? 'ChevronUp' : 'ChevronDown' }}
        id={`businessreview-reviewsummary-${openSummary ? 'close' : 'open'}`}
        onClick={(): void => setOpenSummary(!openSummary)}
        style={propertyStyles}
        text={t('ReviewLanding.ReviewSummary')}
      />
      {openSummary && (
        <EHLayout
          items={[
            <EHFormLabel key="1" label={t('ReviewLanding.ReviewName')} value={review?.name} />,
            <EHFormLabel
              key="2"
              label={t('ReviewLanding.Created')}
              value={formatDate(review.createdOn)}
            />,
            (!hideReviewDateRange && (
              <EHFormLabel
                key="3"
                label={t('ReviewLanding.StartDate')}
                value={formatDate(review.startDate)}
              />
            )) ||
              null,
            <EHFormLabel key="4" label={t('ReviewLanding.CreatedBy')} value={review?.createdBy} />,
            (!hideReviewDateRange && (
              <EHFormLabel
                key="5"
                label={t('ReviewLanding.EndDate')}
                value={formatDate(review.endDate)}
              />
            )) ||
              null,
            <EHFormLabel
              key="6"
              label={t('ReviewLanding.ReviewType')}
              value={reviewData?.find(i => i.id === review?.templateId)?.name.split(' ')[0]}
            />,
            (!hideReviewMeetingDate && (
              <EHFormLabel
                key="7"
                label={t('ReviewLanding.MeetingDate')}
                value={formatDate(review.meetingDate)}
              />
            )) ||
              null,
            <EHFormLabel key="8" label={t('ReviewLanding.Workload')} value={reviewWorkload} />,
            <EHFormLabel
              isRichTextEditor={true}
              key="9"
              label={t('ReviewLanding.Description')}
              value={review?.description}
            />,
            // <EHFormLabel label={t('ReviewLanding.Attendees')} value={review?.attendees} />,
          ]}
        />
      )}
    </>
  );
};
