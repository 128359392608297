/* istanbul ignore file */
import React from 'react';
import { EHShell } from '@components/EHShell';
import { Providers } from '@features/providers';

const App = (): JSX.Element => {
  return (
    <Providers>
      <EHShell />
    </Providers>
  );
};

export default App;
