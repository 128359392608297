import { EHActionButton } from '@components';
import { useReviewId } from '@features/reviews';
import { reloadReview } from '@features/reviews/store/store';
import { IIconProps, IStyle, ITheme, Stack, Text, useTheme } from '@fluentui/react';
import { dayjs } from '@utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

export interface IRefreshMetricsProps {
  lastUpdated?: Date;
}

const getStyles = (
  theme: ITheme,
): Record<'refreshIcon' | 'refreshLabel' | 'container', React.CSSProperties> => ({
  refreshIcon: { fontSize: theme.fonts.large.fontSize, lineHeight: '20px' },
  refreshLabel: { fontSize: theme.fonts.mediumPlus.fontSize },
  container: { lineHeight: '30px' },
});

const getRefreshIconProps = (style: React.CSSProperties): IIconProps => ({
  iconName: 'refresh',
  style: style,
});

export const RefreshMetrics = ({ lastUpdated }: IRefreshMetricsProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);
  const refreshIconProps = useMemo(() => getRefreshIconProps(styles.refreshIcon), [styles]);
  const reviewId = useReviewId();

  const invalidateReviewQuery = (): void => {
    dispatch(reloadReview(reviewId as string));
  };

  return (
    <Stack horizontal style={styles.container}>
      <EHActionButton
        iconProps={refreshIconProps}
        id="businessreview-dashboard-refresh"
        onClick={invalidateReviewQuery}
        styles={{ label: styles.refreshLabel as IStyle }}
        text={t('Main.Refresh')}
      />
      <Text>{t('Dashboard.LastUpdated', { date: dayjs(lastUpdated).format('LLL') })}</Text>
    </Stack>
  );
};
