import React from 'react';
import { EHCard } from '@components';
import { IRawStyle } from '@fluentui/react';

export interface ICardMetricProps {
  body: JSX.Element;
  title: string;
  id: string;
}

const styles: Record<'metricCard', Record<string, IRawStyle>> = {
  metricCard: {
    root: {
      height: '460px',
    },
  },
};

export const CardMetric = ({ title, id, body }: ICardMetricProps): JSX.Element => {
  return <EHCard body={body} cardTitle={title} id={id} styles={styles.metricCard} />;
};
