import React from 'react';
import { ElxChoiceGroup, IElxChoiceGroupProps, InputMessageTypes } from '@elixir/components';
import { IChoiceGroupOption } from '@fluentui/react';
import { FieldHelperProps, FieldMetaProps } from 'formik';
import { EHFieldShimmer } from '../EHFieldShimmer';
import { EHFieldWrapper } from '../EHFieldWrapper';
import { FormContext, IFormContext, ILoading, IName } from '../EHForm';

type IEHFieldChoiceGroup = IElxChoiceGroupProps & IName & ILoading;

interface ISHFieldChoiceGroupProps extends IElxChoiceGroupProps {
  readonly meta: FieldMetaProps<unknown>;
  readonly helpers: FieldHelperProps<unknown>;
  readonly value: string;
}

export const EHFieldChoiceGroup = ({
  name,
  styles,
  isLoading,
  ...props
}: IEHFieldChoiceGroup): JSX.Element => {
  const { fieldsConfiguration } = React.useContext<IFormContext>(FormContext);

  return (
    <EHFieldWrapper name={name}>
      {({ meta, value, helpers, message, ...field }: ISHFieldChoiceGroupProps): JSX.Element => {
        return (
          (isLoading && <EHFieldShimmer />) || (
            <ElxChoiceGroup
              {...props}
              {...field}
              message={
                message ||
                (meta.error && { content: meta.error as string, type: InputMessageTypes.Error }) ||
                undefined
              }
              onChange={(_, option?: IChoiceGroupOption): void => {
                /* istanbul ignore next */
                helpers.setValue(option?.key || '');
              }}
              selectedKey={value}
              styles={styles}
              {...fieldsConfiguration?.[name]}
            />
          )
        );
      }}
    </EHFieldWrapper>
  );
};
