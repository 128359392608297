import { EHDialog } from '@components/EHDialog';
import { clickTracking } from '@utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ISaveAsDraftDialog {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DialogSaveAsDraft = ({
  open,
  onConfirm,
  onCancel,
}: ISaveAsDraftDialog): JSX.Element => {
  const { t } = useTranslation();

  return (
    <EHDialog
      cancelButtonProps={{
        text: t('ReviewLanding.Cancel'),
        onClick: (): void => {
          clickTracking('businessreview-review-saveasdraftcanceldialog', 'Cancel');
          onCancel();
        },
      }}
      contentTitle={t('ReviewLanding.SaveAsDraft')}
      hidden={!open}
      primaryButtonProps={{
        role: 'dialog',
        text: t('ReviewLanding.SaveAsDraft'),
        onClick: (): void => {
          clickTracking('businessreview-review-saveasdraftdialog', 'Save as Draft');
          onConfirm();
        },
      }}>
      <div>
        <div>{t('ReviewLanding.SaveAsDraftLine1')}</div>
        <div>{t('ReviewLanding.SaveAsDraftLine2')}</div>
      </div>
    </EHDialog>
  );
};
