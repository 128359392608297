import React from 'react';
import { FieldHelperProps } from 'formik';
import { Checkbox, ICheckboxProps } from '@fluentui/react';
import { EHFieldWrapper } from '../EHFieldWrapper';
import { FormContext, IFormContext, IName } from '../EHForm';

type IEHFieldCheckbox = ICheckboxProps & IName;

interface ISHFieldCheckboxProps extends ICheckboxProps {
  readonly helpers: FieldHelperProps<unknown>;
}

export const EHCheckbox = ({ name, ...props }: IEHFieldCheckbox): JSX.Element => {
  const { fieldsConfiguration } = React.useContext<IFormContext>(FormContext);

  return (
    <EHFieldWrapper name={name}>
      {({ helpers, ...field }: ISHFieldCheckboxProps): JSX.Element => {
        return (
          <Checkbox
            {...props}
            {...field}
            defaultChecked={props.defaultChecked}
            onChange={(): void => {
              if (props.onChange) props.onChange();
              helpers.setValue(!props.defaultChecked);
            }}
            {...fieldsConfiguration?.[name]}
          />
        );
      }}
    </EHFieldWrapper>
  );
};
