/* istanbul ignore file */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { EHDialog } from '@components/EHDialog';
import { clickTracking } from '@utils';

interface IDeleteDialog {
  readonly open: string;
  readonly setOpen: (id: string) => void;
  readonly onDelete?: (id: string) => void;
}

export const DeleteDialog = ({ open, setOpen, onDelete }: IDeleteDialog): JSX.Element => {
  const { t } = useTranslation();

  return (
    <EHDialog
      cancelButtonProps={{
        text: t('Main.Cancel'),
        onClick: (): void => {
          clickTracking('businessreview-review-notedialogcancel', 'Cancel');
          setOpen('');
        },
      }}
      contentTitle={t('OperationalReview.Delete')}
      hidden={!open}
      primaryButtonProps={{
        text: t('OperationalReview.Delete'),
        onClick: (): void => {
          clickTracking('businessreview-review-notedialogdelete', 'Delete');
          onDelete && onDelete(open);
          setOpen('');
        },
      }}>
      <div>{t('OperationalReview.DeleteNote')}</div>
    </EHDialog>
  );
};
