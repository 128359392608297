/* istanbul ignore file */

/* eslint-disable max-lines-per-function */
import React, { useMemo, useState } from 'react';
import { ElixirPersonaTypes, ElxPersona } from '@elixir/fx';
import { Stack, Text, useTheme } from '@fluentui/react';
import { dayjs } from '@utils';
import { useTranslation } from 'react-i18next';
import { ElxHtml } from '@elixir/fx/lib/components/Html/ElxHtml';
import { INote, NoteState } from './types';
import { getNoteStyles } from './styles';
import { DeleteDialog } from './DeleteDialog';
import { UpdateNote } from './UpdateNote';
import { MoreButton } from './MoreButton';

export const EHNote = ({
  id,
  text,
  createdBy = '',
  createdOn,
  modifiedOn,
  useRTEditor,
  onSubmit,
  onDelete,
  role = 'listitem',
  initialState = NoteState.VIEW,
  currentUser,
}: INote): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = getNoteStyles(theme);
  const date = modifiedOn || createdOn;
  const modifiedFormatted = useMemo(() => dayjs(date).fromNow(), [date]);
  const [state, SetState] = useState(initialState);
  const [openDialog, setOpenDialog] = React.useState('');
  currentUser = 'hideEditAndDelete';

  return (
    <>
      <DeleteDialog onDelete={onDelete} open={openDialog} setOpen={setOpenDialog} />
      <div className={styles.container} role={role}>
        <div className={styles.persona}>
          <ElxPersona
            displayName={createdBy}
            type={ElixirPersonaTypes.Image}
            userPrincipalName={createdBy}
          />
        </div>
        <div className={styles.card}>
          <Stack horizontal horizontalAlign="space-between">
            <Stack horizontal wrap>
              <b>{createdBy}</b>
              {state === NoteState.VIEW && (
                <Text className={styles.date}>{`${modifiedFormatted} ${
                  modifiedOn ? t('OperationalReview.Edited') : ''
                }`}</Text>
              )}
            </Stack>
            {state === NoteState.VIEW && currentUser == createdBy && (
              <MoreButton
                onDelete={(): void => setOpenDialog(id as string)}
                onEdit={(): void => SetState(NoteState.EDIT)}
              />
            )}
          </Stack>
          <>
            {state === NoteState.VIEW && (
              <div>{useRTEditor ? <ElxHtml content={text as string} /> : text}</div>
            )}
            {(state === NoteState.CREATE || state === NoteState.EDIT) && (
              <UpdateNote
                id={id}
                onCancel={(): void => SetState(NoteState.VIEW)}
                onReset={(): void => {
                  if (state == NoteState.EDIT) {
                    SetState(NoteState.VIEW);
                  }
                }}
                onSubmit={onSubmit}
                state={state}
                text={text}
                useRTEditor={useRTEditor}
              />
            )}
          </>
        </div>
      </div>
    </>
  );
};
