import { EHDialog } from '@components/EHDialog';
import { clickTracking } from '@utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IDialogCancel {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const DialogCancel = ({ open, onConfirm, onCancel }: IDialogCancel): JSX.Element => {
  const { t } = useTranslation();

  return (
    <EHDialog
      cancelButtonProps={{
        text: t('ReviewLanding.Cancel'),
        onClick: (): void => {
          clickTracking('businessreview-review-cancelchanges', 'Cancel');
          onConfirm();
        },
      }}
      contentTitle={t('ReviewLanding.CancelChanges')}
      hidden={!open}
      primaryButtonProps={{
        text: t('ReviewLanding.ContinueEditing'),
        onClick: (): void => {
          clickTracking('businessreview-review-continueediting', 'Continue Editing');
          onCancel();
        },
      }}>
      <div>{t('ReviewLanding.CancelChangesLine1')}</div>
    </EHDialog>
  );
};
