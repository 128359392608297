import React, { Dispatch, memo, useEffect, useState } from 'react';
import { EHDetails } from '@components/EHDetails';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { EHActionButton, EHPivot } from '@components';
import { Stack } from '@fluentui/react';
import { useSelectWriteOperationEnabled } from '@features/context';
import { TaskDetailsActivity } from './activity';
import { WorkItemDetailNotes } from './notes';
import { useWorkItemDetailsCounts } from './hooks';
import { IComment, IWorkItemResponse } from '../api';

export enum TabStatus {
  NOTES = '1',
  DOCUMENTS = '2',
  ACTIVITIES = '3',
}

export interface ITaskDetails {
  readonly item: IWorkItemResponse | undefined;
  readonly setItem: Dispatch<IWorkItemResponse | undefined>;
  readonly setEditItem: Dispatch<IWorkItemResponse | undefined>;
}

// eslint-disable-next-line max-lines-per-function
export const TaskDetails = memo(({ item, setItem, setEditItem }: ITaskDetails): JSX.Element => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(TabStatus.NOTES);
  const detailsCounts = useWorkItemDetailsCounts(item?.id);
  const isWriteOperationEnabled = useSelectWriteOperationEnabled();
  const [counts, setCounts] = useState(detailsCounts);

  useEffect(() => {
    setTab(TabStatus.NOTES);
  }, [item?.id]);

  const updateNotesCount = (notes: IComment[]): void =>
    setCounts({ updatesCount: counts?.updatesCount ?? 0, commentsCount: notes.length });

  const headerContent = (
    <Stack horizontal>
      {/* <EHActionButton
        disabled
        iconProps={{ iconName: 'Completed' }}
        key="markascomplete"
        text={t('ActionItems.MarkAsComplete')}
      /> */}
      <EHActionButton
        disabled={item === undefined || !isWriteOperationEnabled}
        iconProps={{ iconName: 'Edit' }}
        key="edittaskdetails"
        onClick={(): void => {
          setEditItem(item);
        }}
        text={t('ActionItems.EditTaskDetails')}
      />
      {/* <EHActionButton
        disabled
        iconProps={{ iconName: 'Trash' }}
        key="delete"
        text={t('ActionItems.Delete')}
      /> */}
    </Stack>
  );

  const items = [
    { label: t('ActionItems.Status'), text: item?.status || '' },
    { label: t('ActionItems.Severity'), text: item?.severity || '' },
    { label: t('ActionItems.Assigned'), text: item?.assignedTo || '' },
    // { label: t('ActionItems.Assigned'), text: item?.assignedTo || '' },
    {
      label: t('ActionItems.LastUpdate'),
      text: dayjs(item?.updatedOn).format('L LTS'),
    },
    { label: t('ActionItems.Type'), text: item?.type || '' },
  ];

  const pivotItems = [
    {
      itemKey: TabStatus.NOTES,
      headerText: t('ActionItems.Notes', { countTxt: counts?.commentsCount ?? '0' }),
    },
    {
      itemKey: TabStatus.ACTIVITIES,
      headerText: t('ActionItems.ActivityCount', { countTxt: detailsCounts?.updatesCount ?? '0' }),
    },
  ];

  return (
    <EHDetails
      description={item?.description || ''}
      headerContent={headerContent}
      headerText={item?.title || ''}
      items={items}
      onDismiss={(): void => setItem(undefined)}
      open={!!item}>
      <>
        <EHPivot
          items={pivotItems}
          onItemSelect={(key: string): void => setTab(key as TabStatus)}
          selectedKey={tab}
        />
        {tab === TabStatus.NOTES && (
          <WorkItemDetailNotes onNotesLoaded={updateNotesCount} workItemId={item?.id} />
        )}
        {tab === TabStatus.ACTIVITIES && <TaskDetailsActivity workItemId={item?.id} />}
      </>
    </EHDetails>
  );
});
TaskDetails.displayName = 'TaskDetails';
