import React, { useState } from 'react';
import { ElxShell } from '@elixir/fx';
import { darkTheme, lightTheme } from '@elixir/styling';
import { BusinessReviewModule } from '@pages';
import { useOneDS } from '@utils/oneDS';
import { EHRoutes } from '@utils';
import { AdminModule } from '@pages/Admin/config';
import { useSagaContext } from '@utils/store';
import { ShellLogo } from './components/Logo';
import { ShellActions } from './components/Actions';

export const EHShell = (): JSX.Element => {
  useOneDS();
  const context = useSagaContext();
  const [darkMode, setDarkMode] = useState<boolean>(false);

  return (
    <ElxShell
      defaultPath={EHRoutes.operationalReview}
      modules={[BusinessReviewModule(), AdminModule()]}
      sagaMiddlewareOptions={{ context }}
      theme={darkMode ? darkTheme : lightTheme}>
      <ElxShell.Branding
        applicationName="Microsoft"
        onRenderLogo={(): JSX.Element => <ShellLogo />}
      />
      <ElxShell.Actions>
        <ShellActions darkMode={darkMode} setDarkMode={setDarkMode} />
      </ElxShell.Actions>
    </ElxShell>
  );
};
