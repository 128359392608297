/* eslint-disable max-lines-per-function */
import { ElxPrimaryButton, ElxSecondaryButton } from '@elixir/components';
import { Stack, useTheme } from '@fluentui/react';
import React, { Dispatch } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { clickTracking /*, defaultTimeZones*/ } from '@utils';
import { /*EHDateTimePicker,*/ EHForm, EHTextField } from '@components';
// import { EHPeoplePicker, IEHPeoplePickerItem } from '@components/EHForms/EHPeoplePicker';
import { EHTextEditor, editorWarningStyles } from '@components/EHForms/EHRichTextEditor';
import { useInsertLinkFix } from '@components/EHRichTextEditor/hook';
import { AssignedToDropdown } from './assignedToDropdown';
import { WorkItemTypeSelection, workItemTypeSelectionSchema } from './workItemTypeSelection';
import { useTaskTypes } from '../hooks';
import { IWorkItemTypes } from '../api';

export type ISeverityField = 'Critical' | 'High' | 'Medium' | 'Low' | '';
export interface ICreateTaskFields {
  id: string;
  title: string;
  description: string;
  severity: ISeverityField;
  estimatedCompletionDate?: string;
  assignedTo?: string;
  workItemType: string;
  state: string;
}

const initialValues: ICreateTaskFields = {
  id: '',
  title: '',
  description: '',
  severity: '',
  // estimatedCompletionDate: '',
  assignedTo: '',
  workItemType: '',
  state: '',
};

export const schema = (
  t: TFunction,
  workItemTypes: IWorkItemTypes[],
): yup.ObjectSchema<AnyObject> => {
  return yup
    .object()
    .shape({
      title: yup
        .string()
        .required(t('ActionItems.ErrorTaskNameRequired'))
        .max(100, t('ReviewLanding.ErrorMaxCharAllowed')),
      assignedTo: yup
        .string()
        .required(t('ActionItems.AssignedToRequired'))
        .max(100, t('ActionItems.AssignedToRequired')),
    })
    .concat(workItemTypeSelectionSchema(t, workItemTypes));
};

// eslint-disable-next-line max-lines-per-function
export const TaskForm = ({
  onSubmit,
  onCancel,
  setError,
  fieldValues,
}: {
  onSubmit: (values: ICreateTaskFields) => void;
  onCancel: () => void;
  setError: Dispatch<boolean>;
  fieldValues?: ICreateTaskFields | undefined;
}): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { data: taskTypes, isLoading } = useTaskTypes(setError);
  const isNew = fieldValues ? false : true;
  useInsertLinkFix();

  return (
    <EHForm
      initialValues={fieldValues ?? initialValues}
      onSubmit={onSubmit}
      style={{ height: '100%' }}
      validationSchema={schema(t, taskTypes ?? [])}>
      {(formik): JSX.Element => {
        return (
          <Stack style={{ height: '100%' }}>
            <Stack tokens={{ childrenGap: 16, padding: 24 }}>
              <EHTextField
                id="CreateTask-Title"
                label={t('ActionItems.TaskName')}
                name="title"
                placeholder={t('ActionItems.EnterTaskName')}
                required
              />
              <EHTextEditor
                description={t('OperationalReview.DisclaimerText')}
                id="CreateTask-Description"
                label={t('ActionItems.Description')}
                multiline={true}
                name="description"
                placeholder={t('ActionItems.DescribeTheTask')}
                styles={editorWarningStyles(theme)}
              />
              {/* <EHFieldDropdown
                disabled
                id="CreateTask-Cloud"
                label={t('ActionItems.Cloud')}
                name="cloud"
                options={[]}
                placeholder={t('ActionItems.SelectCloud')}
              /> */}
              {/* <EHFieldDropdown
                disabled
                id="CreateTask-Technology"
                label={t('ActionItems.Technology')}
                name="technology"
                options={[]}
                placeholder={t('ActionItems.SelectTechnology')}
              /> */}
              <WorkItemTypeSelection
                formikContext={formik}
                isLoading={isLoading}
                isNew={isNew}
                workItemTypes={taskTypes ?? []}
              />
              {/* <EHDateTimePicker
                disabled
                displayMode={ElxDateTimePickerDisplayMode.DateOnly}
                id="CreateTask-EstimatedCompletionDate"
                label={t('ActionItems.EstimatedCompletionDate')}
                name="estimatedCompletionDate"
                timeZones={defaultTimeZones}
              /> */}
              <AssignedToDropdown />
            </Stack>
            <Stack.Item style={{ marginTop: 'auto' }} tokens={{ padding: 24 }}>
              <Stack horizontal tokens={{ childrenGap: 8 }}>
                <ElxPrimaryButton
                  disabled={!formik.dirty || !formik.isValid}
                  onClick={(): void =>
                    clickTracking(
                      'businessreview-actionitem-submitform',
                      isNew ? 'Create Task' : 'Edit Task',
                    )
                  }
                  text={isNew ? t('ActionItems.CreateTask') : t('ActionItems.SaveTask')}
                  type="submit"
                />
                <ElxSecondaryButton
                  onClick={(): void => {
                    clickTracking('businessreview-actionitem-cancelform', 'Cancel');
                    onCancel();
                  }}
                  text={t('ActionItems.Cancel')}
                />
              </Stack>
            </Stack.Item>
          </Stack>
        );
      }}
    </EHForm>
  );
};
