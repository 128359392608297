import { EHRemoteModule } from '@engagehub/core';
import { ReviewParameterKeys } from '@features/reviews/constants';
import { useReview } from '@features/reviews/store/hook';
import { useCurrentLocale } from '@locales/hooks';
import { IReviewContext, ReviewContext } from '@pages';
import React, { useContext } from 'react';

export const openCanvasModuleName = './OpenCanvasReport';
export const openCanvasScopeName = 'engagehub_opencanvas';
export const openCanvasModulePath = 'servicesHub/remoteEntry.js';

export const OpenCanvasDashboard = (): JSX.Element => {
  const currentLocale = useCurrentLocale();
  const ctx = useContext<IReviewContext>(ReviewContext);
  const review = useReview(ctx);

  return (
    <EHRemoteModule
      componentProps={{
        workloadId: review?.reviewDataParameters[ReviewParameterKeys.WorkloadId],
        reportId: review?.reviewDataParameters[ReviewParameterKeys.ReportId],
        locale: currentLocale,
      }}
      moduleName={openCanvasModuleName}
      modulePath={openCanvasModulePath}
      scope={openCanvasScopeName}
    />
  );
};
