import { ITheme, mergeStyleSets } from '@fluentui/react';

interface INoteStyles {
  persona: string;
  container: string;
  card: string;
  date: string;
}

export const getNoteStyles = (theme: ITheme): INoteStyles => {
  return mergeStyleSets({
    persona: {
      paddingTop: theme.spacing.s2,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing.m,
    },
    card: {
      border: `1px solid ${theme.semanticColors.disabledBorder}`,
      borderRadius: theme.spacing.s1,
      marginLeft: theme.spacing.m,
      padding: theme.spacing.m,
      width: '100%',
    },
    date: {
      paddingLeft: theme.spacing.m,
    },
  });
};
