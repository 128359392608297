import React, { memo, useEffect } from 'react';
import { Providers } from '@features/providers';
import { clearCustomerIdFromSessionStorage, configureSHInterception } from '@engagehub/core';
import { containerStyles } from './styles';
import { IAppOverrides } from './types';
import { tokenAllowList } from './constants';

// !IMPORTANT: Avoid breaking changes and make additional props optional.
export interface IRemoteModuleProps {
  customerId?: string;
  locale?: string;
  overrides?: IAppOverrides;
  tokenHeader?: string;
}

export interface ISHIntegratorProps extends IRemoteModuleProps {
  children: JSX.Element;
}

export const SHIntegrator = memo(({ children, ...props }: ISHIntegratorProps): JSX.Element => {
  useEffect(() => {
    configureSHInterception(tokenAllowList);
  }, []);

  useEffect(() => {
    if (!props.customerId) clearCustomerIdFromSessionStorage();
  }, [props.customerId]);

  return (
    <Providers {...props} useIntegration={true}>
      <div className={containerStyles}>{children}</div>
    </Providers>
  );
});

SHIntegrator.displayName = 'SHIntegrator';
