import { EHContainer, breadCrumbStyle, useContainerScope } from '@components';
import { Separator, mergeStyles } from '@fluentui/react';
import React from 'react';
import { DashboardPreview, RenderBreadcrumbHeader, useSelectCustomerName } from '@features';
import { IReviewContext, ReviewContext } from '@pages';
import { ReviewCreate } from '@features/reviews/create';

export enum ReviewStatus {
  CREATE = '0',
  EDIT = '1',
  VIEW = '2',
}

const pageStyle = mergeStyles({
  minWidth: '360px',
});

export const CreateReviewPage = (): JSX.Element => {
  const customerName = useSelectCustomerName();
  const containerCtx = useContainerScope();
  const context: IReviewContext = {
    ...containerCtx,
  };

  return (
    <>
      <RenderBreadcrumbHeader />
      <EHContainer
        actions={containerCtx.actions}
        className={pageStyle}
        headerText={customerName}
        scope={containerCtx.scope}
        styles={breadCrumbStyle}>
        <ReviewContext.Provider value={context}>
          <ReviewCreate />
          <Separator />
          <DashboardPreview />
        </ReviewContext.Provider>
      </EHContainer>
    </>
  );
};
