import { ElxHtml } from '@elixir/fx/lib/components/Html/ElxHtml';
import React from 'react';

export const content = (): JSX.Element => {
  return (
    // copied from https://review.learn.microsoft.com/en-us/services-hub/unified/health/ir-azure?branch=digital-mirp-preview
    <ElxHtml
      content={`<h1 id="microsoft-azure-incident-readiness" style="box-sizing: inherit; outline-color: inherit; margin: -10px 0px 0px; font-size: clamp(1.875rem, 22.1053px + 1.64474vw, 2.5rem); padding: 0px; font-weight: 600; overflow-wrap: break-word; word-break: break-word; line-height: 1.3; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; background-color: rgb(255, 255, 255);">Microsoft Azure incident readiness</h1>
      <div class="display-flex justify-content-space-between align-items-center flex-wrap-wrap page-metadata-container" style="box-sizing: inherit; outline-color: inherit; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255); display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;">
      <div class="margin-right-xxs" style="box-sizing: inherit; outline-color: inherit; margin-inline-end: 0.5rem;">&nbsp;</div>
      </div>
      <p style="box-sizing: inherit; outline-color: inherit; margin: 1rem 0px 0px; padding: 0px; overflow-wrap: break-word; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);">Prepare for the unexpected. Learn how you can mitigate impact and stay informed.</p>
      <div class="heading-wrapper" style="box-sizing: inherit; outline-color: inherit; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);" data-heading-level="h4">
      <h4 id="what-exactly-is-a-service-incident" class="heading-anchor" style="box-sizing: inherit; outline-color: inherit; margin: 36px 0px 6px; padding: 0px; font-size: clamp(1.125rem, 13.2632px + 0.986842vw, 1.5rem); font-weight: 600; line-height: 1.3; margin-inline-start: -1.875rem; padding-inline-start: 1.875rem;">What exactly is a &ldquo;service incident&rdquo;?</h4>
      </div>
      <p style="box-sizing: inherit; outline-color: inherit; margin: 1rem 0px 0px; padding: 0px; overflow-wrap: break-word; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);"><strong style="box-sizing: inherit; outline-color: inherit; font-weight: 600;">Service incident</strong>&nbsp;is the term that Microsoft uses to refer to an event (or series of events) that typically causes customers to have a degraded experience with one or more of our services.</p>
      <p style="box-sizing: inherit; outline-color: inherit; margin: 1rem 0px 0px; padding: 0px; overflow-wrap: break-word; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);">When an Azure incident is declared,&nbsp;<strong style="box-sizing: inherit; outline-color: inherit; font-weight: 600;">we communicate updates</strong>&nbsp;to impacted subscriptions or tenants via the Service Issues blade in Azure Service Health (within the Azure portal).</p>
      <div class="heading-wrapper" style="box-sizing: inherit; outline-color: inherit; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);" data-heading-level="h4">
      <h4 id="why-use-service-health-instead-of-the-public-status-page" class="heading-anchor" style="box-sizing: inherit; outline-color: inherit; margin: 36px 0px 6px; padding: 0px; font-size: clamp(1.125rem, 13.2632px + 0.986842vw, 1.5rem); font-weight: 600; line-height: 1.3; margin-inline-start: -1.875rem; padding-inline-start: 1.875rem;">Why use Service Health instead of the public Status page?</h4>
      </div>
      <p style="box-sizing: inherit; outline-color: inherit; margin: 1rem 0px 0px; padding: 0px; overflow-wrap: break-word; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);">Many customers check our publicly-accessible status pages (like&nbsp;<strong style="box-sizing: inherit; outline-color: inherit; font-weight: 600;"><a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word; background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://azure.status.microsoft/" data-linktype="external">azure.status.microsoft</a></strong>) at the first signs of potential issues, to see if there are known issues with our cloud services. These pages only show widespread issues that meet&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://learn.microsoft.com/azure/service-health/azure-status-overview#when-does-azure-publish-communications-to-the-status-page" data-linktype="external">certain criteria</a>, not smaller incidents that impact fewer customers.</p>
      <p style="box-sizing: inherit; outline-color: inherit; margin: 1rem 0px 0px; padding: 0px; overflow-wrap: break-word; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);"><strong style="box-sizing: inherit; outline-color: inherit; font-weight: 600;">Azure Service Health</strong>&nbsp;(within the Azure portal) knows which subscriptions you manage, so it shows a much more accurate view of any known issues impacting your resources. It also lets you configure alerts, so that you can be notified automatically.</p>
      <div class="heading-wrapper" style="box-sizing: inherit; outline-color: inherit; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);" data-heading-level="h4">
      <h4 id="when-do-i-need-to-open-a-microsoft-support-case" class="heading-anchor" style="box-sizing: inherit; outline-color: inherit; margin: 36px 0px 6px; padding: 0px; font-size: clamp(1.125rem, 13.2632px + 0.986842vw, 1.5rem); font-weight: 600; line-height: 1.3; margin-inline-start: -1.875rem; padding-inline-start: 1.875rem;">When do I need to open a Microsoft support case?</h4>
      </div>
      <p style="box-sizing: inherit; outline-color: inherit; margin: 1rem 0px 0px; padding: 0px; overflow-wrap: break-word; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);">If the issue is already being communicated via&nbsp;<strong style="box-sizing: inherit; outline-color: inherit; font-weight: 600;">Service health</strong>, all the latest information will be provided here, and there is no need to open a support request.</p>
      <p style="box-sizing: inherit; outline-color: inherit; margin: 1rem 0px 0px; padding: 0px; overflow-wrap: break-word; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);">If you believe you&rsquo;re impacted by a service incident, but do not see the issue represented in the Service health page, please open a&nbsp;<strong style="box-sizing: inherit; outline-color: inherit; font-weight: 600;">support request</strong>.</p>
      <div class="heading-wrapper" style="box-sizing: inherit; outline-color: inherit; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);" data-heading-level="h2">
      <h2 id="before-an-incident" class="heading-anchor" style="box-sizing: inherit; outline-color: inherit; margin: 32px 0px 12px; padding: 0px; font-size: clamp(1.59375rem, 18.7895px + 1.39803vw, 2.125rem); font-weight: 600; line-height: 1.3; margin-inline-start: -1.875rem; padding-inline-start: 1.875rem;">Before an incident</h2>
      </div>
      <ol style="box-sizing: inherit; outline-color: inherit; margin: 0px; padding: 0px; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);">
      <li style="box-sizing: inherit; outline-color: inherit; margin: 0px; padding: 0px; outline-width: 0px; list-style-type: decimal;">Use the Well-Architected&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://www.aka.ms/WellArchitected/Reliability" data-linktype="external">Framework</a>&nbsp;and&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://www.aka.ms/WellArchitected/Review" data-linktype="external">Review</a>&nbsp;to optimize the reliability of critical workloads. Please follow the Azure's&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://azure.microsoft.com/blog/tag/advancing-reliability/" data-linktype="external">Advancing Reliability Blog</a>&nbsp;to stay up-to-date with Azure efforts on continuous resiliency efforts.</li>
      <li style="box-sizing: inherit; outline-color: inherit; margin: 0px; padding: 0px; outline-width: 0px; list-style-type: decimal;">Get familiar with&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://www.aka.ms/ash-acom" data-linktype="external">Azure Service Health</a>&nbsp;in the Azure portal &ndash; your &lsquo;go to&rsquo; place in case of issues.</li>
      <li style="box-sizing: inherit; outline-color: inherit; margin: 0px; padding: 0px; outline-width: 0px; list-style-type: decimal;">Configure&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://www.aka.ms/ash-alerts" data-linktype="external">Service Health alerts</a>&nbsp;to notify you about any issues &ndash; by email, SMS, webhook, etc.</li>
      <li style="box-sizing: inherit; outline-color: inherit; margin: 0px; padding: 0px; outline-width: 0px; list-style-type: decimal;">Consider using&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://docs.microsoft.com/azure/service-health/resource-health-overview" data-linktype="external">Resource Health alerts</a>&nbsp;or&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://docs.microsoft.com/azure/virtual-machines/windows/scheduled-events" data-linktype="external">Scheduled Events</a>&nbsp;to stay informed about resource-specific issues.</li>
      </ol>
      <div class="heading-wrapper" style="box-sizing: inherit; outline-color: inherit; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);" data-heading-level="h2">
      <h2 id="during-an-incident" class="heading-anchor" style="box-sizing: inherit; outline-color: inherit; margin: 32px 0px 12px; padding: 0px; font-size: clamp(1.59375rem, 18.7895px + 1.39803vw, 2.125rem); font-weight: 600; line-height: 1.3; margin-inline-start: -1.875rem; padding-inline-start: 1.875rem;">During an incident</h2>
      </div>
      <ol style="box-sizing: inherit; outline-color: inherit; margin: 0px; padding: 0px; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);">
      <li style="box-sizing: inherit; outline-color: inherit; margin: 0px; padding: 0px; outline-width: 0px; list-style-type: decimal;">Review&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://www.aka.ms/ash-portal" data-linktype="external">Azure Service Health</a>&nbsp;within the Azure portal for the latest updates from our engineers.</li>
      <li style="box-sizing: inherit; outline-color: inherit; margin: 0px; padding: 0px; outline-width: 0px; list-style-type: decimal;">If there are issues accessing the portal, check the public&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://status.azure.com/" data-linktype="external">Azure status page</a>&nbsp;as a backup.</li>
      <li style="box-sizing: inherit; outline-color: inherit; margin: 0px; padding: 0px; outline-width: 0px; list-style-type: decimal;">If there are ever issues with the Status page, check for any updates via&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="http://www.twitter.com/AzureSupport" data-linktype="external">@AzureSupport</a>&nbsp;on Twitter.</li>
      <li style="box-sizing: inherit; outline-color: inherit; margin: 0px; padding: 0px; outline-width: 0px; list-style-type: decimal;">If your impact/issues don&rsquo;t match the incident (or if these persist after mitigation)&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://www.aka.ms/AzurePortalSupportRequest" data-linktype="external">contact support</a>.</li>
      </ol>
      <div class="heading-wrapper" style="box-sizing: inherit; outline-color: inherit; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);" data-heading-level="h2">
      <h2 id="microsoft-azure-security-incidents" class="heading-anchor" style="box-sizing: inherit; outline-color: inherit; margin: 32px 0px 12px; padding: 0px; font-size: clamp(1.59375rem, 18.7895px + 1.39803vw, 2.125rem); font-weight: 600; line-height: 1.3; margin-inline-start: -1.875rem; padding-inline-start: 1.875rem;">Microsoft Azure security incidents</h2>
      </div>
      <p style="box-sizing: inherit; outline-color: inherit; margin: 1rem 0px 0px; padding: 0px; overflow-wrap: break-word; color: rgb(22, 22, 22); font-family: 'Segoe UI', SegoeUI, 'Helvetica Neue', Helvetica, Arial, sans-serif; font-size: 16px; background-color: rgb(255, 255, 255);">With the growing presence and sophistication of online threats like cloud viruses, ransomware, etc., it&rsquo;s important that you&nbsp;<a target="_blank" style="box-sizing: inherit; outline-color: inherit; color: var(--theme-hyperlink); cursor: pointer; overflow-wrap: break-word;  background-color: rgba(0, 0, 0, 0); outline-width: 0px;" href="https://learn.microsoft.com/azure/service-health/stay-informed-security" data-linktype="external">stay informed about Azure security incidents</a>&nbsp;and take steps to protect your environment.</p>`}
    />
  );
};
