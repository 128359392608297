import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IEngageHubOptions } from './types';
import { setEnableLinkToEngageHubUX, setEngageHubOptions } from './store';

export interface IEngageHubOptionsProviderProps {
  options?: IEngageHubOptions;
  enableLinkToEngageHubUX?: boolean;
  children: React.ReactNode;
}
export const EngageHubOptionsProvider = (props: IEngageHubOptionsProviderProps): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.options) dispatch(setEngageHubOptions(props.options));
  }, [props.options]);

  useEffect(() => {
    dispatch(setEnableLinkToEngageHubUX(!!props.enableLinkToEngageHubUX));
  }, [props.enableLinkToEngageHubUX]);

  return <>{props.children}</>;
};
