import React from 'react';
import { ITheme, Label, Stack, Text, mergeStyleSets, useTheme } from '@fluentui/react';

export interface IEHItem {
  readonly label: string;
  readonly text: string | number | JSX.Element | undefined;
}

interface IEHItems {
  readonly items: IEHItem[];
  readonly labelWidth?: number;
}

interface IStyles {
  container: string;
  item: string;
  label: string;
  text: string;
}

const getStyles = (theme: ITheme, labelWidth = 100): IStyles => {
  return mergeStyleSets({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    item: {
      width: '50%',
      padding: theme.spacing.s2,
      selectors: {
        [`@media(max-width: 768px)`]: {
          width: '100%',
        },
      },
    },
    label: {
      width: labelWidth,
    },
    text: {
      alignSelf: 'center',
    },
  });
};

export const EHItems = ({ items, labelWidth }: IEHItems): JSX.Element => {
  const styles = getStyles(useTheme(), labelWidth);

  return (
    <div className={styles.container}>
      {items.map(({ label, text }: IEHItem, i: number) => (
        <Stack className={styles.item} horizontal key={i}>
          <Label className={styles.label}>{label}</Label>
          <Text className={styles.text}>{`: ${text}`}</Text>
        </Stack>
      ))}
    </div>
  );
};
