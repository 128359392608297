import { defaultLanguage, i18n } from '@locales';
import { useEffect, useState } from 'react';

export const useCurrentLocale = (): string => {
  const [locale, setLocale] = useState<string>(defaultLanguage);
  const currentLocale = i18n.getCurrentLocale();

  useEffect(() => {
    setLocale(currentLocale);
  }, [currentLocale]);

  return locale;
};
