import { fetchAsync } from '@engagehub/core';
import { AxiosError } from 'axios';
import { UseMutationResult, useMutation } from 'react-query';
import { adminApiVersion } from '@utils/store/constants';
import { getCustomerId } from '@utils/scope';
import { IConfigureOpenCanvasFields } from './configureOpenCanvas';

export const useConfigureOpenCanvas = (): UseMutationResult<
  unknown,
  AxiosError,
  IConfigureOpenCanvasFields,
  unknown
> => {
  return useMutation((values: IConfigureOpenCanvasFields) =>
    fetchAsync(`/br-admin/datasource/opencanvas`, {
      method: 'POST',
      headers: {
        'x-eh-scope': values.targetScope,
      },
      params: {
        'api-version': adminApiVersion,
      },
      data: {
        ...values,
        createdBy: 'Microsoft@microsoft.com',
      },
    }),
  );
};

export interface ICreateOpenCanvasReportRequest {
  targetScope: string;
  templateType: 'MIRP';
}

export interface ICreateOpenCanvasReportResponse {
  reportId: string;
}

export const useCreateOpenCanvasReport = (): UseMutationResult<
  ICreateOpenCanvasReportResponse,
  AxiosError,
  ICreateOpenCanvasReportRequest,
  unknown
> => {
  return useMutation((req: ICreateOpenCanvasReportRequest) =>
    fetchAsync<ICreateOpenCanvasReportResponse>(`/oc/report/create`, {
      method: 'POST',
      headers: {
        'x-eh-scope': req.targetScope,
        'x-ms-Scope': 'Customer',
        'x-ms-ScopeId': getCustomerId(req.targetScope),
        'x-ms-TemplateType': req.templateType,
      },
    }),
  );
};
