import { IPagedResult } from './types';

export interface ITableSort {
  fieldName?: string;
  isSorted?: boolean;
  isSortedDescending?: boolean;
}

export interface IPaginationFilter {
  take: number;
  skip: number;
  orderBy?: string;
  desc?: boolean;
}

export function GetNextPageParamFn<T>(
  lastPage: IPagedResult<T>,
  allPages: Array<IPagedResult<T>>,
  pageSize: number,
): IPaginationFilter | undefined {
  const initialValue = 0;
  const totalFetched = allPages.reduce(
    (accumulator, current) => accumulator + current.values.length,
    initialValue,
  );

  return totalFetched < lastPage.totalCount ? { take: pageSize, skip: totalFetched } : undefined;
}
