import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IElixirState } from '@elixir/fx';
import { businessReviewSelector } from '@utils/store/selector';
import { IEngageHubOptions } from './types';

export interface INavigationState {
  options?: IEngageHubOptions;
  enableLinkToEngageHubUX?: boolean;
}

export const initialNavigationState: INavigationState = {
  options: undefined,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: initialNavigationState,
  reducers: {
    setEngageHubOptions: (state: INavigationState, action: PayloadAction<IEngageHubOptions>) => ({
      ...state,
      options: action.payload,
    }),
    setEnableLinkToEngageHubUX: (state: INavigationState, action: PayloadAction<boolean>) => ({
      ...state,
      enableLinkToEngageHubUX: action.payload,
    }),
  },
});

const { actions, reducer } = navigationSlice;

export const { setEngageHubOptions, setEnableLinkToEngageHubUX } = actions;

export const navigationReducer = reducer;

export const selectEngageHubOptions = (state: IElixirState): IEngageHubOptions | undefined =>
  businessReviewSelector(state).navigation.options;

export const selectEnableLinkToEngageHubUX = (state: IElixirState): boolean =>
  !!businessReviewSelector(state).navigation.enableLinkToEngageHubUX;
