import { fetchAsync } from '@engagehub/core';
import { IReviewTemplate } from '@features/reviews';
import { ISetResult } from '@utils';
import { UseQueryResult, useQuery } from 'react-query';

export interface IConfigureAdoFields {
  targetScope: string;
  reviewType: string;
  organizationId: string;
  projectId: string;
  areaPath: string;
  workItemTypes: string[];
  isProcessExclusive: boolean;
}

export interface IConfigureTemplateFields extends IConfigureAdoFields {
  templateId: string;
}

export const useTemplates = (targetScope?: string): Partial<UseQueryResult<IReviewTemplate[]>> => {
  const { ...rest } = useQuery(
    ['template', targetScope],
    () =>
      fetchAsync<ISetResult<IReviewTemplate>>(`/br/templates/query`, {
        method: 'POST',
        headers: {
          'x-eh-scope': targetScope!,
        },
        data: {},
      }).then(resp => resp.values),
    {
      enabled: !!targetScope,
      staleTime: Infinity,
    },
  );

  return { ...rest };
};
