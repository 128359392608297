import React from 'react';
import { ElxRichTextEditor } from '@elixir/fx/lib/components/Editor/RichText/ElxRichTextEditor';
import { IElxRichTextEditorProps } from '@elixir/fx/lib/components/Editor/RichText/ElxRichTextEditor.types';
import { ElxRichTextCommandBarButtonTypes } from '@elixir/fx/lib/components/Editor/RichText/plugins/commandbar/ElxRichTextCommandBar.types';

const editorStyles = {
  editor: {
    height: '63px',
    resize: 'vertical',
  },
};

export const isRTEditorEmpty = (value: string): boolean =>
  value.replace(/<(.|\n)*?>/g, '').trim().length === 0 &&
  !value.includes('<img') &&
  !value.includes('<table');

const richTextButtonOptionsExcluded = [
  ElxRichTextCommandBarButtonTypes.BgColor,
  ElxRichTextCommandBarButtonTypes.FontColor,
];
const richTextButtonOptions = Object.values(ElxRichTextCommandBarButtonTypes).filter(
  x => !richTextButtonOptionsExcluded.includes(x as ElxRichTextCommandBarButtonTypes),
) as Array<ElxRichTextCommandBarButtonTypes>;

export const EHRichTextEditor = (props: IElxRichTextEditorProps): JSX.Element => {
  return (
    <ElxRichTextEditor
      {...props}
      buttonItems={richTextButtonOptions}
      styles={Object.assign(editorStyles, props.styles)}
    />
  );
};
