import React, { MutableRefObject, useContext, useEffect, useMemo } from 'react';
import { Stack } from '@fluentui/react';
import { useTemplates } from '@features/admin/hooks';
import { EHFieldDropdown, EHForm, EHTextField } from '@components';
import { AdminPageContext, IAdminPageContext } from '@pages/Admin';
import { FormikProps } from 'formik';
import { reviewTypeOptions } from '@utils/store/constants';
import { ActionItemContext, IActionItemContext } from '../actionItems';
import { useUpdatePreconfiguredActionItems } from '../hook';

export interface ITemplateActionItems {
  targetScope: string;
  templateId: string;
}

export const TemplateForm = (): JSX.Element => {
  const { createActionItem } = React.useContext<IAdminPageContext>(AdminPageContext);
  const { targetScope, setTargetScope, setTemplateId, setReviewType } =
    useContext<IActionItemContext>(ActionItemContext);
  const createOrUpdate = useUpdatePreconfiguredActionItems();
  const { data, isFetching } = useTemplates(targetScope);
  const options = useMemo(
    () => data?.map(i => ({ key: i.id, text: i.name?.split(' ')[0] })) || [],
    [data],
  );

  return (
    <Stack tokens={{ childrenGap: 16, padding: 24 }}>
      <EHForm
        initialValues={{ targetScope: '', templateId: '' }}
        innerRef={
          createActionItem.innerRef as MutableRefObject<FormikProps<ITemplateActionItems> | null>
        }
        onReset={(): void => setTemplateId('')}
        onSubmit={(): void => createOrUpdate()}>
        {({ values, setFieldValue }): JSX.Element => {
          useEffect(() => {
            setFieldValue('templateId', '');
            setTargetScope(values.targetScope);
          }, [values.targetScope]);
          useEffect(() => {
            setTemplateId(values.templateId);
          }, [values.templateId]);
          useEffect(() => {
            setReviewType(values.reviewType);
          }, [values.reviewType]);
          return (
            <>
              <EHTextField
                aria-label={'targetScope'}
                id="targetScope-field"
                label="Target Scope"
                name="targetScope"
                placeholder="Enter Target Scope"
                required
              />
              <EHFieldDropdown
                aria-label={'reviewType'}
                id="reviewType-field"
                label="Review Type"
                name="reviewType"
                options={reviewTypeOptions}
                placeholder="Select Review Type"
              />
              <EHFieldDropdown
                disabled={!data}
                id="customer-emplateid-field"
                isLoading={isFetching}
                label="Template"
                name={'templateId'}
                options={options}
                placeholder="Select Template Type"
              />
            </>
          );
        }}
      </EHForm>
    </Stack>
  );
};
