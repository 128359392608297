/* eslint-disable max-lines-per-function */
import { useContext, useEffect } from 'react';
import { IResult, usePush } from '@utils';
import { UseMutateFunction, useMutation, useQueryClient } from 'react-query';
import {
  ICreateReviewResponse,
  IDataParamValue,
  IReviewTemplate,
  ReviewParameterKeys,
  useSelectUserContext,
} from '@features';
import { IReviewContext, ReviewContext } from '@pages';
import { useMessaging } from '@utils/messaging';
import * as routing from '@utils/routing';
import { useDispatch } from 'react-redux';
import { fetchAsync, useSelectCustomerId } from '@engagehub/core';
import { actionItemQueryKey } from '@features/actionItems/constants';
import { AxiosError } from 'axios';
import { constructCustomerScope } from '@utils/scope';
import { IEditReviewFields } from '../helper';
import { getReview } from '../store/store';
import { useReviewTemplates } from '../view/hooks';
import { invalidateReviewQueries } from '../query';

export const useCreateReview = function (): UseMutateFunction<
  string,
  unknown,
  IEditReviewFields,
  unknown
> {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { setLoading, setMessage } = useContext<IReviewContext>(ReviewContext);
  const push = usePush();
  const customerId = useSelectCustomerId();
  const user = useSelectUserContext();
  const { standardErrorMessage, onError } = useMessaging();
  const message = {
    ...standardErrorMessage,
    onDismiss: (): void => setMessage(undefined),
  };
  const { data: reviewData } = useReviewTemplates();

  const {
    data: createdReviewId,
    isLoading,
    isError,
    mutate,
  } = useMutation(
    async ({
      name,
      startDate,
      endDate,
      description,
      meetingDate,
      templateId,
      workloadId,
    }: IEditReviewFields) =>
      fetchAsync<IResult<ICreateReviewResponse>>('/br/processes/reviews', {
        method: 'POST',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
        data: {
          name,
          startDate,
          endDate,
          description,
          meetingDate,
          createdBy: user?.upn,
          templateId,
          workloadId,
          reviewDataParameters: getReviewDataParameters(reviewData ?? [], templateId, workloadId),
        },
      }).then(pagedResult => pagedResult?.value),
    {
      onSuccess: data => {
        invalidateReviewQueries(queryClient, customerId);
        queryClient.invalidateQueries(actionItemQueryKey);
        dispatch(getReview(data));
      },
      onError: error => {
        onError(error as AxiosError);
      },
    },
  );

  useEffect(() => {
    setLoading(isLoading);
    if (!isLoading && !!createdReviewId) {
      push(routing.getReviewLandingRoute(createdReviewId), undefined, true);
    }
  }, [isLoading, createdReviewId]);

  useEffect(() => {
    isError && setMessage(message);
  }, [isError]);

  return mutate;
};

export const getReviewDataParameters = (
  reviewData: IReviewTemplate[],
  templateId: string,
  workloadId?: string,
): Record<string, IDataParamValue> => {
  const parameters: Record<string, IDataParamValue> = {};
  const template = reviewData.find(i => i.id == templateId);
  const reviewDataParameters = template?.reviewDataParameters;
  const hasWorkloadDataParamter = reviewDataParameters?.find(
    i => i.name == ReviewParameterKeys.WorkloadId,
  );
  if (!!hasWorkloadDataParamter && !!workloadId) {
    parameters.WorkloadId = workloadId;
  }
  return parameters;
};

// export const useCreateRecurringReview = function (): UseMutateFunction<
//   string,
//   unknown,
//   IEditReviewFields,
//   unknown
// > {
//   const queryClient = useQueryClient();
//   const { t } = useTranslation();
//   const { setLoading, setMessage } = useContext<IReviewContext>(ReviewContext);
//   const push = usePush();
//   const customerId = useSelectCustomerId();
//   const user = useSelectUserContext();
//   const { standardErrorMessage } = useMessaging();
//   const message = {
//     ...standardErrorMessage,
//     onDismiss: (): void => setMessage(undefined),
//   };

//   const {
//     data: createdReviewId,
//     isLoading,
//     isError,
//     mutate,
//   } = useMutation(
//     async (data: IEditReviewFields) =>
//       fetchAsync<IResult<ICreateReviewResponse>>('/br/processes/reviews/schedule', {
//         method: 'POST',
//         params: {
//           customerId,
//         },
//         data: GetRecurringReviewRequest(data, user),
//       }).then(pagedResult => pagedResult?.value),
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries(['reviews']);
//         setMessage({
//           type: ElxMessageBarType.success,
//           message: t('ReviewLanding.SuccessSaveAsDraftMessage'),
//           onDismiss: (): void => setMessage(undefined),
//           actions: (
//             <EHMessageBarButton
//               onClick={(): void => push(EHRoutes.operationalReview)}
//               text={t('ReviewLanding.GoToOperationalReviews')}
//             />
//           ),
//           isMultiline: false,
//         });
//       },
//     },
//   );

//   useEffect(() => {
//     setLoading(isLoading);
//     if (!isLoading && !!createdReviewId) {
//       push(EHRoutes.operationalReview);
//     }
//   }, [isLoading, createdReviewId]);

//   useEffect(() => {
//     isError && setMessage(message);
//   }, [isError]);

//   return mutate;
// };

// const GetRecurringReviewRequest = (
//   data: IEditReviewFields,
//   user: IUserContext | undefined,
// ): any => {
//   const RecurrenceType = getEnumDisplayMap(RecurrencePatternType);
//   const DayOfTheWeek = getEnumDisplayMap(DayOfWeek);
//   return {
//     templateId: 'tid',
//     name: data.name,
//     createdBy: user?.upn,
//     reviewStartDateOffset: data.reviewStartDateOffset,
//     reviewPeriod: data.reviewPeriod,
//     recurrencePattern: {
//       patternType: RecurrenceType[data.recurrenceType],
//       weekly: {
//         dayOfTheWeek: DayOfTheWeek[data.dayOfTheWeek],
//         interval: data.weeklyInterval,
//       },
//       monthly: {
//         dayOfTheMonth: data.dayOfTheMonth,
//         interval: data.monthlyInterval,
//       },
//     },
//     daysToPrepare: data.daysToPrepare,
//     description: data.description,
//   };
// };
