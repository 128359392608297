/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext } from 'react';
import { Form, Formik, FormikConfig, FormikContextType } from 'formik';
import './styles.scss';

export interface IName {
  readonly name: string;
}

export interface ILoading {
  readonly isLoading?: boolean;
}

export interface IFormContext {
  fieldsConfiguration?: Record<string, any>;
}

interface IForm extends FormikConfig<any>, IFormContext {
  children: ((props: FormikContextType<any>) => JSX.Element) | JSX.Element;
  style?: React.CSSProperties;
}

export const FormContext = createContext<IFormContext>({});

export const EHForm = ({ fieldsConfiguration, children, ...props }: IForm): JSX.Element => {
  const context = { fieldsConfiguration };
  const isFunction = typeof children === 'function';

  return (
    <Formik {...props}>
      {(formicProps: FormikContextType<any>): JSX.Element => (
        <Form aria-label={'form'} noValidate style={props.style}>
          <FormContext.Provider value={context}>
            {isFunction ? children({ ...formicProps }) : children}
          </FormContext.Provider>
        </Form>
      )}
    </Formik>
  );
};
