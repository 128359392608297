import { EHLayout } from '@components/EHLayout';
import { DayOfWeek, IChoiceGroupOption, IChoiceGroupStyles } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EHFieldDropdown } from '@components/EHForms';
import {
  MaxAllowedFrequency,
  MaxAllowedOffset,
  MaxAllowedPeriod,
  MaxDaysInMonth,
  MinAllowedValue,
  RecurrencePatternType,
  getEnumDisplayMap,
} from '@features/reviews/types';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { useFormikContext } from 'formik';
import { IEditReviewFields } from '@features/reviews/helper';
import { EHCheckbox } from '@components/EHForms/EHCheckbox';
import { EHFieldNumber } from '@components/EHForms/EHFieldNumber';
import { EHFieldChoiceGroup } from '@components/EHForms/EHFieldChoiceGroup';
import './styles.scss';

export interface IRecurringInput {
  isRecurring: boolean;
  handleIsRecurringChanged: () => void;
}

export interface IMeetingInfo {
  meetingDate: string;
  reviewStartDate: string;
}

const RecurrenceType = getEnumDisplayMap(RecurrencePatternType);
const DayOfTheWeek = getEnumDisplayMap(DayOfWeek);

/*eslint max-lines-per-function: ["error", 200]*/
export const Recurring = function (props: IRecurringInput): JSX.Element {
  const { t } = useTranslation();
  const recurrencePatternOptions = useRecurrencePatternOptions();
  const daysOptions = useDayOfTheWeekOptions();
  const onDayOptions = useOnDaysOfTheMonth();
  const { values } = useFormikContext<IEditReviewFields>();
  const disableRecurringFields = values ? !values.isRecurring : false;
  // by default show weekly review fields
  const showWeeklyFields =
    values === undefined || RecurrenceType[values.recurrenceType] === RecurrencePatternType.Weekly;
  const showMonthlyFields =
    values !== undefined && RecurrenceType[values.recurrenceType] === RecurrencePatternType.Monthly;

  const dayOfWeekChoiceStyle: Partial<IChoiceGroupStyles> = { flexContainer: { display: 'flex' } };

  const fields = [
    <div className="recurring-box-width" key="8">
      <label
        className="ms-Label elx-label root-234"
        htmlFor="IsRecurringCheckbox"
        id="IsRecurringCheckbox-label">
        {t('ReviewLanding.RecurringEvery') + ' '}
      </label>
      <div className="recurring-rows recurring-margin-top">
        <div className="recurring-row">
          <EHCheckbox
            aria-label="isRecurring-label"
            checked={props.isRecurring}
            defaultChecked={props.isRecurring}
            id="IsRecurringCheckbox"
            name={'isRecurring'}
            onChange={props.handleIsRecurringChanged}
          />
        </div>
        <div className="recurring-row">
          {showWeeklyFields && (
            <EHFieldNumber
              arid-label={'weeklyInterval'}
              disabled={disableRecurringFields}
              id="weekly interval"
              max={MaxAllowedFrequency}
              min={MinAllowedValue}
              name={'weeklyInterval'}
            />
          )}
          {showMonthlyFields && (
            <EHFieldNumber
              arid-label={'monthlyInterval'}
              disabled={disableRecurringFields}
              id="monthly interval"
              max={MaxAllowedFrequency}
              min={MinAllowedValue}
              name={'monthlyInterval'}
            />
          )}
        </div>
        <div className="recurring-row">
          <EHFieldDropdown
            aria-label={t('ReviewLanding.RecurrencePattern')}
            disabled={disableRecurringFields}
            name={'recurrenceType'}
            options={recurrencePatternOptions}
            placeholder={t('ReviewLanding.SelectRecurrencePattern')}
          />
        </div>
      </div>
      <div className="recurring-rows">
        <div className="recurring-row">
          {showMonthlyFields && (
            <EHFieldChoiceGroup
              aria-label={t('ReviewLanding.MonthlyDaysMode')}
              disabled={disableRecurringFields}
              name={'monthlyDaysMode'}
              options={onDayOptions}
            />
          )}
        </div>
        <div className="recurring-row recurring-margin-top">
          {showMonthlyFields && (
            <EHFieldNumber
              arid-label={'dayOfTheMonth'}
              disabled={disableRecurringFields}
              max={MaxDaysInMonth}
              min={MinAllowedValue}
              name={'dayOfTheMonth'}
              placeholder={t('ReviewLanding.EnterDayOfTheMonth')}
            />
          )}
        </div>
      </div>
      <div className="recurring-rows">
        {showWeeklyFields && (
          <EHFieldChoiceGroup
            aria-label={t('ReviewLanding.DayOfTheWeek')}
            disabled={disableRecurringFields}
            id="dayOfTheWeek"
            name={'dayOfTheWeek'}
            options={daysOptions}
            styles={dayOfWeekChoiceStyle}
          />
        )}
      </div>
      <div className="recurring-rows">
        <div className="recurring-row ">
          <EHFieldNumber
            arid-label={'reviewStartDateOffset'}
            disabled={disableRecurringFields}
            label={t('ReviewLanding.ReviewStartDateOffset')}
            max={MaxAllowedOffset}
            min={0}
            name={'reviewStartDateOffset'}
          />
        </div>
        <div className="recurring-row recurring-margin-left">
          <EHFieldNumber
            arid-label={'reviewPeriod'}
            disabled={disableRecurringFields}
            label={t('ReviewLanding.ReviewPeriod')}
            max={MaxAllowedPeriod}
            min={0}
            name={'reviewPeriod'}
          />
        </div>
        <div className="recurring-row recurring-margin-left">
          <EHFieldNumber
            arid-label={'daysToPrepare'}
            disabled={disableRecurringFields}
            label={t('ReviewLanding.DaysToPrepare')}
            max={MaxAllowedPeriod}
            min={0}
            name={'daysToPrepare'}
          />
        </div>
      </div>
      <span>{getSummaryTextForRecurrence(values)}</span>
    </div>,
  ];

  return <EHLayout internal={true} items={fields} />;
};

const getSummaryTextForRecurrence = (values: IEditReviewFields): string => {
  const { t } = useTranslation();
  if (!values || !values.isRecurring) return t('ReviewLanding.DoesNotRepeat');
  const recurrenceType = values.recurrenceType;

  switch (RecurrenceType[recurrenceType]) {
    case RecurrencePatternType.Weekly:
      if (!values.dayOfTheWeek) return t('ReviewLanding.DoesNotRepeat');
      const weeklyMeeting = getSummaryTextForWeeklyPattern(values);

      return t('ReviewLanding.WeeklyReviewSummary', {
        weeklyInterval: values.weeklyInterval,
        dayOfTheWeek: values.dayOfTheWeek,
        meetingDate: weeklyMeeting.meetingDate,
        reviewStartDate: weeklyMeeting.reviewStartDate,
      });
    case RecurrencePatternType.Monthly:
      if (!values.dayOfTheMonth) return 'ReviewLanding.DoesNotRepeat';
      const monthlyMeeting = getSummaryTextForMonthlyPattern(values);

      return t('ReviewLanding.MonthlyReviewSummary', {
        monthlyInterval: values.monthlyInterval,
        dayOfTheMonth: values.dayOfTheMonth,
        meetingDate: monthlyMeeting.meetingDate,
        reviewStartDate: monthlyMeeting.reviewStartDate,
      });
    /* istanbul ignore next */
    default:
      return t('ReviewLanding.DoesNotRepeat');
  }
};

//Occurs every Monday starting 1/1/2023
const getSummaryTextForWeeklyPattern = (values: IEditReviewFields): IMeetingInfo => {
  const meetingDate = getNextOccurrence(values);
  const reviewStartDate = new Date(meetingDate);
  // get review start date
  reviewStartDate.setDate(reviewStartDate.getDate() - values.reviewStartDateOffset);

  const meetingInfo: IMeetingInfo = {
    meetingDate: meetingDate.toLocaleDateString(),
    reviewStartDate: reviewStartDate.toLocaleDateString(),
  };
  return meetingInfo;
};

const getSummaryTextForMonthlyPattern = (values: IEditReviewFields): IMeetingInfo => {
  const current = new Date();
  let year = current.getFullYear();
  let month = current.getMonth();
  let day = values.dayOfTheMonth;

  // if the recurrence's day of the month is less than start date, then we add interval months to get the month and year.
  if (day < current.getDay()) {
    const newDate = new Date(year, month, 1);
    newDate.setMonth(newDate.getMonth() + values.monthlyInterval);
    month = newDate.getMonth();
    year = newDate.getFullYear();
  }

  // if the recurrence's day is greater than the total days of the starting month, then set the start date to the end of this starting month
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  if (day > daysInMonth) {
    day = daysInMonth;
  }

  const meetingDate = new Date(year, month, day);
  const reviewStartDate = new Date(meetingDate);
  // get review start date
  reviewStartDate.setDate(reviewStartDate.getDate() - values.reviewStartDateOffset);

  const meetingInfo: IMeetingInfo = {
    meetingDate: meetingDate.toLocaleDateString(),
    reviewStartDate: reviewStartDate.toLocaleDateString(),
  };
  return meetingInfo;
};

const getNextOccurrence = (values: IEditReviewFields): Date => {
  const day = new Date();
  const dayOfToday = day.getDay();
  const addsToAdd = (DayOfTheWeek[values.dayOfTheWeek] - dayOfToday + 7) % 7;
  day.setDate(day.getDate() + addsToAdd);
  return day;
};

export const useRecurrencePatternOptions = (): IDropdownOption[] => {
  const { t } = useTranslation();
  return [
    {
      key: 'Weekly',
      text: t('ReviewLanding.Week'),
    },
    {
      key: 'Monthly',
      text: t('ReviewLanding.Month'),
    },
  ];
};

export const useOnDaysOfTheMonth = (): IChoiceGroupOption[] => {
  const { t } = useTranslation();
  return [
    {
      key: 'OnDay',
      text: t('ReviewLanding.OnDay') + '\u00A0\u00A0',
    },
  ];
};

export const useDayOfTheWeekOptions = (): IChoiceGroupOption[] => {
  const { t } = useTranslation();
  return [
    {
      key: 'Sunday',
      text: t('ReviewLanding.Sun') + '\u00A0\u00A0',
    },
    {
      key: 'Monday',
      text: t('ReviewLanding.Mon') + '\u00A0\u00A0',
    },
    {
      key: 'Tuesday',
      text: t('ReviewLanding.Tue') + '\u00A0\u00A0',
    },
    {
      key: 'Wednesday',
      text: t('ReviewLanding.Wed') + '\u00A0\u00A0',
    },
    {
      key: 'Thursday',
      text: t('ReviewLanding.Thur') + '\u00A0\u00A0',
    },
    {
      key: 'Friday',
      text: t('ReviewLanding.Fri') + '\u00A0\u00A0',
    },
    {
      key: 'Saturday',
      text: t('ReviewLanding.Sat') + '\u00A0\u00A0',
    },
  ];
};
