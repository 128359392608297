import React, { useEffect } from 'react';
import { fetchAsync } from '@engagehub/core';
import { AdminPageContext, IAdminPageContext } from '@pages/Admin';
import { ElxMessageBarType } from '@elixir/components';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { IPagedResult } from '@utils';
import { adminApiVersion } from '@utils/store/constants';
import { ActionItemContext, IActionItemContext } from './actionItems';
import { getErrorFromAxiosError, successMessage } from '../message';

export interface IPreconfiguredActionItem {
  readonly title: string;
  readonly description: string;
  readonly workItemSeverity: string;
  readonly assignedTo: string;
  readonly workItemType: string;
}

export interface IActionItemWrapper {
  readonly defaultWorkItemsDto: IPreconfiguredActionItem[];
}

export const useQueryPreconfiguredActionItems = (): IPreconfiguredActionItem[] | undefined => {
  const { createActionItem, ...adminCtx } = React.useContext<IAdminPageContext>(AdminPageContext);
  const { targetScope, templateId, reviewType } =
    React.useContext<IActionItemContext>(ActionItemContext);

  const { data, isLoading, error } = useQuery(
    ['actionItems', templateId, reviewType],
    () =>
      fetchAsync<IPagedResult<IPreconfiguredActionItem>>(
        `/br-admin/template/${templateId}/review-type/${reviewType}/operations/action-items`,
        {
          method: 'GET',
          headers: {
            'x-eh-scope': targetScope,
          },
          params: {
            'api-version': adminApiVersion,
          },
        },
      ).then(resp => resp.values.map(item => ({ ...item, severity: item.workItemSeverity }))),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => adminCtx.setIsLoading(isLoading), [isLoading]);

  useEffect(() => {
    if (error) {
      createActionItem.setMessage({
        message: getErrorFromAxiosError(error as AxiosError),
        type: ElxMessageBarType.error,
      });
    } else {
      createActionItem.setMessage(undefined);
    }
  }, [error]);

  return data;
};

export const useUpdatePreconfiguredActionItems = (): UseMutateFunction<
  unknown,
  unknown,
  void,
  unknown
> => {
  const queryClient = useQueryClient();
  const { createActionItem, ...adminCtx } = React.useContext<IAdminPageContext>(AdminPageContext);
  const { targetScope, templateId, reviewType, items } =
    React.useContext<IActionItemContext>(ActionItemContext);

  const {
    mutate: create,
    isSuccess,
    isLoading,
    error,
  } = useMutation(
    () => {
      return fetchAsync(`/br-admin/template/${templateId}/operations/action-items`, {
        method: 'POST',
        headers: {
          'x-eh-scope': targetScope,
        },
        params: {
          'api-version': adminApiVersion,
        },
        data: {
          defaultWorkItemsDto: items,
          reviewType: reviewType,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['actionItems']);
      },
    },
  );

  useEffect(() => adminCtx.setIsLoading(isLoading), [isLoading]);

  useEffect(() => {
    if (isSuccess) {
      createActionItem.setMessage(successMessage);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      createActionItem.setMessage({
        message: getErrorFromAxiosError(error as AxiosError),
        type: ElxMessageBarType.error,
      });
    } else {
      createActionItem.setMessage(undefined);
    }
  }, [error]);

  return create;
};
