import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';

export const staleTime = 10000;

const queryClient = new QueryClient();

export const UseQueryProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};
