import { useEffect, useState } from 'react';

export const useWindowWidth = (): number => {
  const [resize, setResize] = useState(0);
  useEffect(() => {
    const handleResize = (): void => setResize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return resize;
};
