import * as yup from 'yup';
import React, { MutableRefObject, useEffect } from 'react';
import { EHForm } from '@components';
import { FormikProps } from 'formik';
import { ElxMessageBarType } from '@elixir/components';
import { getErrorFromAxiosError, successMessage } from '@features';
import { AdminPageContext, IAdminPageContext } from '@pages/Admin';
import { useConfigureOpenCanvas } from './hooks';
import { OpenCanvasForm } from './form';

export interface IConfigureOpenCanvasFields {
  targetScope: string;
  reviewType: string;
  reportId: string;
  templateId: string;
}
const initialValues: IConfigureOpenCanvasFields = {
  targetScope: '',
  reviewType: '',
  reportId: '',
  templateId: '',
};

const schema = yup.object().shape({
  targetScope: yup.string().required('Target Scope is required'),
  reviewType: yup.string().required('Review Type is required'),
  reportId: yup.string().required('Report Id is required'),
  templateId: yup.string().required('Template Id is required'),
});

export const ConfigureOpenCanvas = (): JSX.Element => {
  const { mutate: create, isLoading: isSubmitLoading, isSuccess, error } = useConfigureOpenCanvas();
  const { configureOpenCanvas, ...adminCtx } =
    React.useContext<IAdminPageContext>(AdminPageContext);
  useEffect(() => adminCtx.setIsLoading(isSubmitLoading), [isSubmitLoading]);
  useEffect(() => {
    if (isSuccess) configureOpenCanvas.setMessage(successMessage);
  }, [isSuccess]);
  useEffect(() => {
    if (error) {
      configureOpenCanvas.setMessage({
        message: getErrorFromAxiosError(error),
        type: ElxMessageBarType.error,
      });
    } else configureOpenCanvas.setMessage(undefined);
  }, [error]);

  return (
    <EHForm
      initialValues={initialValues}
      innerRef={
        configureOpenCanvas.innerRef as MutableRefObject<FormikProps<IConfigureOpenCanvasFields> | null>
      }
      onSubmit={(values): void => create(values)}
      style={{ height: '100%' }}
      validationSchema={schema}>
      {(): JSX.Element => {
        return <OpenCanvasForm />;
      }}
    </EHForm>
  );
};
