import { i18n } from '@locales';
import { useEffect, useState } from 'react';

export const useLocale = (locale: string | undefined): boolean => {
  const [initialized, SetInitialized] = useState(false);

  useEffect(() => {
    i18n.initializeAsync().then(() => SetInitialized(true));
  }, []);

  useEffect(() => {
    if (initialized && locale && locale != i18n.getCurrentLocale()) {
      i18n.changeLocale(locale);
    }
  }, [initialized, locale]);

  return initialized;
};
