import React, { useMemo } from 'react';
import { HorizontalBarChart, IChartDataPoint, IChartProps } from '@fluentui/react-charting';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { IMetricProps } from '.';

export const HorizontalBar = ({ data }: IMetricProps): JSX.Element => {
  const chartData: IChartProps[] = [];
  const byCountDesc = useMemo(
    () =>
      data
        ?.map(result => ({
          label: result.label,
          value: result.outagesResultItems[0]?.value,
        }))
        ?.sort((curr, next) => next.value - curr.value)
        ?.slice(0, 10),
    [data],
  );
  const maxValue = useMemo(
    () => Math.max(...(byCountDesc?.map(item => item.value) ?? [])),
    [byCountDesc],
  );
  byCountDesc?.forEach(item => {
    const row = {
      chartTitle: item.label,
      chartData: [] as IChartDataPoint[],
    };
    row.chartData.push({
      color: DefaultPalette.blue,
      horizontalBarChartdata: { x: item.value, y: maxValue },
      xAxisCalloutData: item.label,
      yAxisCalloutData: item.value.toString(),
      legend: item.label,
    });
    chartData.push(row);
  });

  return <HorizontalBarChart data={chartData} />;
};
