/* istanbul ignore file */
import React from 'react';
import { EHFieldDropdown } from '@components/EHForms';
import { useTranslation } from 'react-i18next';
import { IDropdownOption } from '@fluentui/react';
import { useEHConfig } from '@engagehub/core';
import { useWorkloadOptions } from './hooks';

export interface IWorkloadInput {
  isOptional: boolean;
}

export const WorkloadTypeDropdown = (props: IWorkloadInput): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading, data } = useWorkloadOptions(true);
  const config = useEHConfig();
  const criticalWorkloadUrl = config?.serviceshubUrl + '/critical-workload';
  const workloadOptions = (data ?? [])
    .map(i => ({ key: i.id, text: i.name }))
    .concat([
      {
        key: 'createNewWorkload',
        text: t('ReviewLanding.CreateNewWorkload'),
      },
    ]);

  return (
    <EHFieldDropdown
      aria-label={'workload'}
      description={t('ReviewLanding.WorkloadCreateDescription')}
      id="EditReview-Edit-Workload"
      isLoading={isLoading}
      label={props.isOptional ? t('ReviewLanding.OptionalWorkload') : t('ReviewLanding.Workload')}
      name="workloadId"
      onChanged={(option?: IDropdownOption): void => {
        /* istanbul ignore next */
        if (option?.key == 'createNewWorkload') window.open(criticalWorkloadUrl, '_blank');
      }}
      options={workloadOptions}
      placeholder={t('ReviewLanding.SelectWorkload')}
      required={!props.isOptional}
    />
  );
};
