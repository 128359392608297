import { Localization } from '@serviceshub/x-localization';
import { ICultureItem } from '@serviceshub/x-types';
import { initReactI18next } from 'react-i18next';
import type { i18n as i18next } from 'i18next';
import { getLanguageCultures } from './language-data';
import { loadResourceBundleAsync } from './helper';
import type { Resources } from './resources/en-US/';

export type SupportedCulturesType = Record<string, ICultureItem>;
export type LocalizedStringKeys = keyof typeof Resources;

export const defaultLanguage = 'en-US';
export const translationNamespace = 'Resources';
export class EHLocalization extends Localization<LocalizedStringKeys> {
  constructor() {
    super(defaultLanguage, translationNamespace);
    this.i18nextInstance.use(initReactI18next);
  }

  public async loadSupportedCultures(): Promise<SupportedCulturesType> {
    return await getLanguageCultures(defaultLanguage);
  }

  public async loadResourceBundleAsync(locale: string): Promise<void> {
    await loadResourceBundleAsync(
      this.i18nextInstance as unknown as i18next,
      locale,
      this.translationNamespace,
    );
  }
}

export const i18n = new EHLocalization();
