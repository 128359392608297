import { EHContainer, IContainerContext, breadCrumbStyle, useContainerScope } from '@components';
import { Separator, mergeStyles } from '@fluentui/react';
import React, { CSSProperties, Dispatch, createContext, useEffect, useState } from 'react';
import {
  ReviewDetailsPanel,
  ReviewEdit,
  ReviewTabs,
  ReviewView,
  useSelectContext,
  useSelectCustomerName,
} from '@features';
import { ReviewCreate } from '@features/reviews/create';
import { ReviewStatus } from '@pages/CreateReview';
import { useDispatch } from 'react-redux';
import { getReview } from '@features/reviews/store/store';
import { useReview } from '@features/reviews/store/hook';
import { useSelectCustomerId } from '@engagehub/core';
import { NavigationHeader } from '@features/navigation/header';
import { useReviewId } from '../../features/reviews/hooks';

const pageStyle = mergeStyles({
  minWidth: '360px',
});

export interface IReviewContext extends IContainerContext {
  setStatus?: Dispatch<ReviewStatus>;
  status?: ReviewStatus;
}

export const ReviewContext = createContext<IReviewContext>({} as IReviewContext);

const propertyStyles: CSSProperties = {
  margin: '0',
  padding: '0',
};

export const ReviewLandingPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const containerCtx = useContainerScope();
  const customerId = useSelectCustomerId();
  const reviewId = useReviewId();
  const review = useReview(containerCtx);
  const customerName = useSelectCustomerName();
  const contextState = useSelectContext();
  const [status, setStatus] = useState(!!reviewId ? ReviewStatus.VIEW : ReviewStatus.CREATE);

  useEffect(() => {
    if (reviewId && customerId && review?.id != reviewId) {
      dispatch(getReview(reviewId));
    }
  }, [reviewId, customerId, review]);

  useEffect(() => {
    containerCtx.setLoading(!!contextState?.isLoading, 'ctx');
  }, [contextState?.isLoading]);

  const context: IReviewContext = {
    ...containerCtx,
    setStatus,
    status,
  };

  return (
    <>
      <NavigationHeader />
      <EHContainer
        actions={containerCtx.actions}
        className={pageStyle}
        headerText={customerName}
        scope={containerCtx.scope}
        styles={breadCrumbStyle}>
        {/* this container adds styling with padding to be removed when very narrow screen */}
        <ReviewContext.Provider value={context}>
          {status === ReviewStatus.CREATE && <ReviewCreate />}
          {status === ReviewStatus.EDIT && <ReviewEdit review={review} />}
          {status === ReviewStatus.VIEW && review && <ReviewView review={review} />}
          <ReviewDetailsPanel ctx={containerCtx} />
          <Separator style={propertyStyles} />
          {review && <ReviewTabs />}
        </ReviewContext.Provider>
      </EHContainer>
    </>
  );
};
