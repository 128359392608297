import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { fetchAsync, useSelectCustomerId, useSelectUserContext } from '@engagehub/core';
import { MaskedUserValues, useSelectIsInternalUser } from '@features/context';
import { constructCustomerScope } from '@utils/scope';
import { ICreateTaskFields } from '../form';
import { IWorkItemResponse } from '../api';
import { actionItemQueryKey } from '../constants';

export const useSubmitTask = (
  reviewId?: string,
): UseMutationResult<IWorkItemResponse, unknown, ICreateTaskFields, unknown> => {
  const customerId = useSelectCustomerId();
  const queryClient = useQueryClient();
  const isInternalUser = useSelectIsInternalUser();
  const userContext = useSelectUserContext();

  return useMutation(
    (values: ICreateTaskFields) =>
      fetchAsync<IWorkItemResponse>(`/br/processes/reviews/${reviewId}/work-items`, {
        method: 'POST',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
        data: {
          ...values,
          assignedTo: useAssignedToMaskedUserValue(
            values.assignedTo,
            isInternalUser,
            userContext?.upn,
          ),
          reviewId,
        },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(actionItemQueryKey),
    },
  );
};

export const useAssignedToMaskedUserValue = (
  value: string | undefined,
  isInternal: boolean | undefined,
  upn: string | undefined,
): string | undefined => {
  if (value === MaskedUserValues.Microsoft && isInternal && upn) return upn;
  if (value === MaskedUserValues.Microsoft || value == MaskedUserValues.Customer) return value;
  else return undefined;
};
