import type { i18n } from 'i18next';

// eslint-disable-next-line max-lines-per-function
export async function loadResourceBundleAsync(
  instance: i18n,
  locale: string,
  namespace: string,
): Promise<void> {
  let bP;

  switch (locale) {
    case 'af-ZA':
      bP = import(/* webpackChunkName: 'af-ZA-strings' */ './resources/af-ZA/');
      break;
    case 'ar-EG':
      bP = import(/* webpackChunkName: 'ar-EG-strings' */ './resources/ar-EG/');
      break;
    case 'ar-LY':
      bP = import(/* webpackChunkName: 'ar-LY-strings' */ './resources/ar-LY/');
      break;
    case 'ar-SA':
      bP = import(/* webpackChunkName: 'ar-SA-strings' */ './resources/ar-SA/');
      break;
    case 'bg-BG':
      bP = import(/* webpackChunkName: 'bg-BG-strings' */ './resources/bg-BG/');
      break;
    case 'bn-BD':
      bP = import(/* webpackChunkName: 'bn-BD-strings' */ './resources/bn-BD/');
      break;
    case 'bn-IN':
      bP = import(/* webpackChunkName: 'bn-IN-strings' */ './resources/bn-IN/');
      break;
    case 'bs-Latn-BA':
      bP = import(/* webpackChunkName: 'bs-Latn-BA-strings' */ './resources/bs-Latn-BA/');
      break;
    case 'ca-AD':
      bP = import(/* webpackChunkName: 'ca-AD-strings' */ './resources/ca-AD/');
      break;
    case 'cs-CZ':
      bP = import(/* webpackChunkName: 'cs-CZ-strings' */ './resources/cs-CZ/');
      break;
    case 'cy-GB':
      bP = import(/* webpackChunkName: 'cy-GB-strings' */ './resources/cy-GB/');
      break;
    case 'da-DK':
      bP = import(/* webpackChunkName: 'da-DK-strings' */ './resources/da-DK/');
      break;
    case 'de-AT':
      bP = import(/* webpackChunkName: 'de-AT-strings' */ './resources/de-AT/');
      break;
    case 'de-CH':
      bP = import(/* webpackChunkName: 'de-CH-strings' */ './resources/de-CH/');
      break;
    case 'de-DE':
      bP = import(/* webpackChunkName: 'de-DE-strings' */ './resources/de-DE/');
      break;
    case 'el-GR':
      bP = import(/* webpackChunkName: 'el-GR-strings' */ './resources/el-GR/');
      break;
    case 'en-AU':
    case 'en-US':
    case 'en-CA':
    case 'en-GB':
    case 'en-NZ':
      bP = import(/* webpackChunkName: 'en-US-strings' */ './resources/en-US/');
      break;
    case 'es-AR':
    case 'es-BO':
    case 'es-CL':
    case 'es-CO':
    case 'es-CR':
    case 'es-DO':
    case 'es-EC':
    case 'es-ES':
    case 'es-GT':
    case 'es-HN':
    case 'es-MX':
    case 'es-NI':
    case 'es-PA':
    case 'es-PE':
    case 'es-PR':
    case 'es-UY':
    case 'es-VE':
    case 'et-EE':
      bP = import(/* webpackChunkName: 'es-ES-strings' */ './resources/es-ES/');
      break;
    case 'fa-IR':
      bP = import(/* webpackChunkName: 'fa-IR-strings' */ './resources/fa-IR/');
      break;
    case 'fi-FI':
      bP = import(/* webpackChunkName: 'fi-FI-strings' */ './resources/fi-FI/');
      break;
    case 'fil-PH':
      bP = import(/* webpackChunkName: 'fil-PH-strings' */ './resources/fil-PH/');
      break;
    case 'fr-BE':
    case 'fr-CA':
    case 'fr-CH':
    case 'fr-DZ':
    case 'fr-FR':
    case 'fr-MA':
    case 'fr-PF':
    case 'fr-TN':
      bP = import(/* webpackChunkName: 'fr-FR-strings' */ './resources/fr-FR/');
      break;
    case 'he-IL':
      bP = import(/* webpackChunkName: 'he-IL-strings' */ './resources/he-IL/');
      break;
    case 'hi-IN':
      bP = import(/* webpackChunkName: 'hi-IN-strings' */ './resources/hi-IN/');
      break;
    case 'hr-HR':
      bP = import(/* webpackChunkName: 'hr-HR-strings' */ './resources/hr-HR/');
      break;
    case 'hu-HU':
      bP = import(/* webpackChunkName: 'hu-HU-strings' */ './resources/hu-HU/');
      break;
    case 'id-ID':
      bP = import(/* webpackChunkName: 'id-ID-strings' */ './resources/id-ID/');
      break;
    case 'is-IS':
      bP = import(/* webpackChunkName: 'is-IS-strings' */ './resources/is-IS/');
      break;
    case 'it-IT':
      bP = import(/* webpackChunkName: 'it-IT-strings' */ './resources/it-IT/');
      break;
    case 'ja-JP':
      bP = import(/* webpackChunkName: 'ja-JP-strings' */ './resources/ja-JP/');
      break;
    case 'ko-KR':
      bP = import(/* webpackChunkName: 'ko-KR-strings' */ './resources/ko-KR/');
      break;
    case 'lt-LT':
      bP = import(/* webpackChunkName: 'lt-LT-strings' */ './resources/lt-LT/');
      break;
    case 'lv-LV':
      bP = import(/* webpackChunkName: 'lv-LV-strings' */ './resources/lv-LV/');
      break;
    case 'mg-MG':
      bP = import(/* webpackChunkName: 'mg-MG-strings' */ './resources/mg-MG/');
      break;
    case 'ms-MY':
      bP = import(/* webpackChunkName: 'ms-MY-strings' */ './resources/ms-MY/');
      break;
    case 'mt-MT':
      bP = import(/* webpackChunkName: 'mt-MT-strings' */ './resources/mt-MT/');
      break;
    case 'nb-NO':
      bP = import(/* webpackChunkName: 'nb-NO-strings' */ './resources/nb-NO/');
      break;
    case 'nl-BE':
      bP = import(/* webpackChunkName: 'nl-BE-strings' */ './resources/nl-BE/');
      break;
    case 'nl-NL':
      bP = import(/* webpackChunkName: 'nl-NL-strings' */ './resources/nl-NL/');
      break;
    case 'pl-PL':
      bP = import(/* webpackChunkName: 'pl-PL-strings' */ './resources/pl-PL/');
      break;
    case 'pt-BR':
      bP = import(/* webpackChunkName: 'pt-BR-strings' */ './resources/pt-BR/');
      break;
    case 'pt-PT':
      bP = import(/* webpackChunkName: 'pt-PT-strings' */ './resources/pt-PT/');
      break;
    case 'ro-RO':
      bP = import(/* webpackChunkName: 'ro-RO-strings' */ './resources/ro-RO/');
      break;
    case 'ru-RU':
      bP = import(/* webpackChunkName: 'ru-RU-strings' */ './resources/ru-RU/');
      break;
    case 'sk-SK':
      bP = import(/* webpackChunkName: 'sk-SK-strings' */ './resources/sk-SK/');
      break;
    case 'sl-SI':
      bP = import(/* webpackChunkName: 'sl-SI-strings' */ './resources/sl-SI/');
      break;
    case 'sr_Latn_RS':
      bP = import(/* webpackChunkName: 'sr_Latn_RS-strings' */ './resources/sr_Latn_RS/');
      break;
    case 'sv-SE':
      bP = import(/* webpackChunkName: 'sv-SE-strings' */ './resources/sv-SE/');
      break;
    case 'sw-TZ':
      bP = import(/* webpackChunkName: 'sw-TZ-strings' */ './resources/sw-TZ/');
      break;
    case 'ta-IN':
      bP = import(/* webpackChunkName: 'ta-IN-strings' */ './resources/ta-IN/');
      break;
    case 'te-IN':
      bP = import(/* webpackChunkName: 'te-IN-strings' */ './resources/te-IN/');
      break;
    case 'th-TH':
      bP = import(/* webpackChunkName: 'th-TH-strings' */ './resources/th-TH/');
      break;
    case 'to-TO':
      bP = import(/* webpackChunkName: 'to-TO-strings' */ './resources/to-TO/');
      break;
    case 'tr-TR':
      bP = import(/* webpackChunkName: 'tr-TR-strings' */ './resources/tr-TR/');
      break;
    case 'uk-UA':
      bP = import(/* webpackChunkName: 'uk-UA-strings' */ './resources/uk-UA/');
      break;
    case 'ur-IN':
      bP = import(/* webpackChunkName: 'ur-IN-strings' */ './resources/ur-IN/');
      break;
    case 'vi-VN':
      bP = import(/* webpackChunkName: 'vi-VN-strings' */ './resources/vi-VN/');
      break;
    case 'zh-CN':
      bP = import(/* webpackChunkName: 'zh-CN-strings' */ './resources/zh-CN/');
      break;
    case 'zh-HK':
      bP = import(/* webpackChunkName: 'zh-HK-strings' */ './resources/zh-HK/');
      break;
    case 'zh-TW':
      bP = import(/* webpackChunkName: 'zh-TW-strings' */ './resources/zh-TW/');
      break;
    default:
      bP = import('./resources/en-US/');
  }
  const strings = (await bP).Resources;

  instance.addResourceBundle(locale, namespace, {
    ...strings,
  });
}
