/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { ElxDateTimePickerDisplayMode, IElxDateTimePickerTimeZone } from '@elixir/components';
import { ITheme, Stack, mergeStyles, useTheme } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { ScreenWidthMinXLargePlus } from '@utils/styling';
import { EHDateTimePicker, EHLayout, EHTextField } from '@components';
import { useFormikContext } from 'formik';
import { EHTextEditor, editorWarningStyles } from '@components/EHForms/EHRichTextEditor';
import { ReviewParameterKeys, ReviewTags } from '@features';
import { ReviewTypeDropdown } from './reviewType';
import { Recurring } from './recurring';
import { WorkloadTypeDropdown } from './workload';
import { DateGroup } from './dateGroup';
import { IEditReviewFields } from '../helper';
import { useReviewTemplates } from '../view/hooks';

export const timeZones: IElxDateTimePickerTimeZone[] = [
  {
    symbol: 'UTC',
    utcOffsetMinutes: 0,
  },
];

const dateStyle = (theme: ITheme): string =>
  mergeStyles({
    width: `calc(50% - ${theme.spacing.s1})`,
    selectors: {
      [`@media(max-width: ${ScreenWidthMinXLargePlus}px)`]: {
        width: '100%',
      },
    },
  });

const textFieldStyle = (theme: ITheme): string =>
  mergeStyles({
    selectors: {
      '& input[type=text]::selection': {
        backgroundColor: theme.semanticColors.primaryButtonBackground,
        color: theme.semanticColors.bodyBackground,
      },
    },
  });

export const ReviewForm = (): JSX.Element => {
  const { t } = useTranslation();
  const { values, setValues } = useFormikContext<IEditReviewFields>();
  const newReview = values && values.isRecurring; // useReviewId() ? false : true;
  const [IsRecurring, SetIsRecurring] = useState(newReview ?? false);
  const theme = useTheme();
  const { data: reviewData } = useReviewTemplates();
  const templateSelected = reviewData?.find(i => i.id == values?.templateId);
  const workloadDropdownEnabled = !!templateSelected?.reviewDataParameters?.find(
    i => i.name == ReviewParameterKeys.WorkloadId,
  );
  const optionalWorkloadDropdownEnabled = !!templateSelected?.reviewDataParameters?.find(
    i => i.name == ReviewParameterKeys.WorkloadId && i.isOptional,
  );
  const hideReviewDateRange = templateSelected?.tags[ReviewTags.HideReviewDateRange] === 'true';
  const hideReviewMeetingDate = templateSelected?.tags[ReviewTags.HideReviewMeetingDate] === 'true';

  useEffect(() => {
    const newValues = { ...values };
    if (hideReviewDateRange) {
      // TODO: Do not hardcode dates!!
      const currentDate = new Date(Date.now());
      newValues.startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1,
      ).toISOString();
      newValues.endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        2,
      ).toISOString();
    } else {
      newValues.startDate = '';
      newValues.endDate = '';
    }
    if (hideReviewMeetingDate) {
      newValues.meetingDate = new Date(Date.now() + 86400000).toISOString().slice(0, 10);
    } else {
      newValues.meetingDate = '';
    }
    setValues(newValues);
  }, [hideReviewDateRange, hideReviewMeetingDate]);

  return (
    <EHLayout
      items={[
        <Stack key="container" tokens={{ childrenGap: 16 }}>
          <EHTextField
            aria-label={'name'}
            className={textFieldStyle(theme)}
            id="EditReview-Edit-ReviewName"
            key="name"
            label={t('ReviewLanding.ReviewName')}
            name="name"
            placeholder={t('ReviewLanding.EnterReviewName')}
            required
          />
          <ReviewTypeDropdown key="type" />
          {workloadDropdownEnabled ? (
            <WorkloadTypeDropdown isOptional={optionalWorkloadDropdownEnabled} key="workload" />
          ) : (
            <></>
          )}
          {!hideReviewDateRange &&
            (newReview ? (
              <Recurring
                handleIsRecurringChanged={(): void => SetIsRecurring(!IsRecurring)}
                isRecurring={IsRecurring}
                key="recurring"></Recurring>
            ) : (
              <div key="date-range">{IsRecurring === false && <DateGroup />}</div>
            ))}
          <div key="meeting-date">
            {!hideReviewMeetingDate && IsRecurring === false && (
              <EHDateTimePicker
                aria-label={'meetingDate'}
                className={dateStyle(theme)}
                displayMode={ElxDateTimePickerDisplayMode.DateOnly}
                id="EditReview-Edit-MeetingDate"
                label={t('ReviewLanding.MeetingDate')}
                name="meetingDate"
                required={!values?.id}
                timeZones={timeZones}
              />
            )}
          </div>
        </Stack>,

        <EHTextEditor
          aria-label={'description'}
          className={textFieldStyle(theme)}
          description={t('OperationalReview.DisclaimerText')}
          id="EditReview-Edit-Description"
          key="description"
          label={t('ReviewLanding.Description')}
          multiline
          name="description"
          placeholder={t('ReviewLanding.EnterDescription')}
          styles={editorWarningStyles(theme)}
        />,
      ]}
      responsiveBrakePoint={ScreenWidthMinXLargePlus}
    />
  );
};
