import { EHTable } from '@components/EHTable';
import React, { useContext, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { IPagedResult } from '@utils';
import { actions, columns } from './columns';
import { useActionItemTableFilter } from './hooks';
import { NoActionItemsToDisplay } from './noActionItemsToDisplay';
import { useActionItemList, useTaskTypes } from '../hooks';
import { ActionItemsContext, IActionItemsContext } from '../component';
import { TaskDetails } from '../taskDetails';
import { IWorkItemResponse } from '../api';
import { EditTask } from '../editTask';

export interface ITaskListProps {
  useTasksQuery: UseQueryResult<IPagedResult<IWorkItemResponse>, unknown>;
}

export const TaskList = (): JSX.Element => {
  const { setStandardError: setError, setLoading } =
    useContext<IActionItemsContext>(ActionItemsContext);
  const { data: workItemTypes } = useTaskTypes(setError);
  const { tableFilterProps, queryFilterValue } = useActionItemTableFilter(workItemTypes);
  const {
    data: taskData,
    fetchNextPage,
    isError,
    isLoading,
    isFetchingNextPage,
  } = useActionItemList(queryFilterValue);

  useEffect(() => {
    setLoading(isLoading || isFetchingNextPage, 'tasklist');
  }, [isLoading, isFetchingNextPage]);

  useEffect(() => {
    setError(isError, 'TaskList:isError');
  }, [isError]);

  const [item, setItem] = useState<IWorkItemResponse | undefined>(undefined);
  const [editItem, setEditItem] = useState<IWorkItemResponse | undefined>(undefined);

  const getTableSpacing = (): JSX.Element => {
    return <div style={{ padding: '8px 28px' }}></div>;
  };

  const items =
    taskData?.pages?.reduce(
      (acummulator, currentValue) => acummulator.concat(currentValue.values),
      [] as Array<IWorkItemResponse>,
    ) ?? [];

  useEffect(() => {
    if (item) {
      const updated = items.find(i => i.id === item.id);
      if (updated) setItem(updated);
    }
  }, [items]);

  return (
    <>
      {editItem ? (
        <EditTask data={editItem} setEditItem={setEditItem} />
      ) : (
        <TaskDetails item={item} setEditItem={setEditItem} setItem={setItem} />
      )}
      <EHTable
        actions={actions({ setEditItem })}
        columns={columns({ setItem })}
        ehFilterProps={tableFilterProps}
        isFixedHeader={true}
        items={items}
        maxHeight={450}
        maxListSize={taskData?.pages?.[0]?.totalCount}
        onChange={(): void => {
          fetchNextPage();
        }}
        tableSpacing={getTableSpacing()}
      />
      {!isLoading && !items.length && <NoActionItemsToDisplay />}
    </>
  );
};
