import { ElxMasterDetailContainer, ElxTabControlOrientation } from '@elixir/components';
import { AdminPageContext, IAdminPageContext } from '@pages/Admin';
import React from 'react';
import { useTabs } from './tabs';

export * from './ado';
export * from './create';
export * from './insights';
export * from './message';
export * from './actionItems';
export * from './tabs';

export const AdminContainer = (): JSX.Element => {
  const tabs = useTabs();
  const { isLoading } = React.useContext<IAdminPageContext>(AdminPageContext);

  return (
    <ElxMasterDetailContainer
      headerText="Business Review Admin Page"
      isLoading={isLoading}
      maxDisplayActions={5}
      showNumeric={false}
      tabs={tabs}
      tabsOrientation={ElxTabControlOrientation.Vertical}
    />
  );
};
