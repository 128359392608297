import { EHFieldDropdown } from '@components';
import { IWorkItemTypes } from '@features/actionItems/api';
import { FormikContextType } from 'formik';
import React, { useEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface IWorkItemTypeSelectionProps {
  isNew: boolean;
  workItemTypes: IWorkItemTypes[];
  isLoading: boolean;
  formikContext: FormikContextType<{ severity: string; workItemType: string; state: string }>;
}

export const workItemTypeSelectionSchema = (
  t: TFunction,
  workItemTypes: IWorkItemTypes[],
): yup.AnyObjectSchema => {
  const workItemTypesWithSev = workItemTypes.filter(x => x.severityValues?.length).map(x => x.name);

  return yup.object().shape({
    severity: yup.string().when('workItemType', {
      is: (workItemType: string): boolean => workItemTypesWithSev.includes(workItemType),
      then: yup.string().required(t('ActionItems.ErrorSeverityRequired')),
    }),
    workItemType: yup.string().required(t('ActionItems.TypeRequired')),
  });
};

export function WorkItemTypeSelection(props: IWorkItemTypeSelectionProps): JSX.Element {
  const { t } = useTranslation();
  const { formikContext, isLoading, isNew, workItemTypes } = props;
  const workItemType = props.workItemTypes.find(i => i.name === formikContext.values.workItemType);

  useEffect(() => {
    const values = props.formikContext.values;
    if (values && values?.severity && !workItemType?.severityValues.includes(values.severity)) {
      formikContext.setFieldValue('severity', formikContext.initialValues.severity);
    }
  }, [workItemType?.name]);

  return (
    <>
      <EHFieldDropdown
        aria-label={t('ActionItems.Type')}
        disabled={!isNew}
        id="CreateTask-Type"
        isLoading={isLoading}
        label={t('ActionItems.Type')}
        name="workItemType"
        options={workItemTypes.map(i => ({ key: i.name, text: i.name })) || []}
        placeholder={t('ActionItems.SelectType')}
        required
      />
      {!isNew && (
        <EHFieldDropdown
          id="EditTask-Status"
          isLoading={isLoading}
          label={t('ActionItems.Status')}
          name="state"
          options={workItemType?.states?.map(i => ({ key: i, text: i })) || []}
          placeholder={t('ActionItems.SelectStatus')}
          required
        />
      )}
      {workItemType?.severityValues && workItemType.severityValues.length > 0 && (
        <EHFieldDropdown
          id="CreateTask-Severity"
          isLoading={isLoading}
          label={t('ActionItems.Severity')}
          name="severity"
          options={workItemType.severityValues.map(i => ({ key: i, text: i })) || []}
          placeholder={t('ActionItems.SelectSeverity')}
          required
        />
      )}
    </>
  );
}
