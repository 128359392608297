import { EHForm } from '@components/EHForms/EHForm';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@fluentui/react';
import { IReviewDetailsResponse } from '@features';
import { ReviewContext, ReviewStatus } from '@pages';
import { FormikContextType } from 'formik';
import { useEditReview } from './hooks';
import { useReviewEditActions } from './actions';
import { IEditReviewFields, schema } from '../helper';
import { ReviewForm } from '../form';
import { DialogCancel } from '../dialogCancel';

interface IReviewEdit {
  readonly review?: IReviewDetailsResponse;
}

export const ReviewEdit = ({ review }: IReviewEdit): JSX.Element => {
  const { t } = useTranslation();
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const { setStatus } = useContext(ReviewContext);
  const mutate = useEditReview();

  const editFieldsConfiguration = {
    templateId: {
      disabled: true,
    },
    workloadId: {
      disabled: true,
    },
    startDate: {
      disabled: true,
    },
    endDate: {
      disabled: true,
    },
    meetingDate: {
      disabled: true,
    },
  };

  return (
    <EHForm
      enableReinitialize
      fieldsConfiguration={editFieldsConfiguration}
      initialValues={review}
      onSubmit={(r: IEditReviewFields): void => mutate(r)}
      validationSchema={schema()}>
      {({ submitForm, isValid, dirty }: FormikContextType<unknown>): JSX.Element => (
        <>
          {useReviewEditActions({ setCancelConfirm, submitForm, isValid, dirty })}
          <DialogCancel
            onCancel={(): void => setCancelConfirm(false)}
            onConfirm={(): void => setStatus?.(ReviewStatus.VIEW)}
            open={cancelConfirm}
          />
          <Text>{t('ReviewLanding.ReviewSummary')}</Text>
          <ReviewForm />
        </>
      )}
    </EHForm>
  );
};
