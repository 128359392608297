import { useSelector } from 'react-redux';
import { IElixirState } from '@elixir/fx';
import {
  IAppContextState,
  IUserContext,
  engageHubSelector,
  selectUserPermissions,
} from '@engagehub/core';

enum CustomerClaims {
  CanManageBusinessReviews = 'CanManageBusinessReviews',
  CanViewBusinessReviews = 'CanViewBusinessReviews',
  CanAdminBusinessReviewProcesses = 'CanAdminBusinessReviewProcesses',
}

export enum MaskedUserValues {
  Microsoft = 'Microsoft',
  Customer = 'Customer',
}

export const useSelectCustomerName = (): string | undefined => {
  return useSelector<IElixirState, string | undefined>(x => {
    return engageHubSelector(x)?.context?.token?.customer?.name;
  });
};

export const useSelectIsInternalUser = (): boolean | undefined => {
  return useSelector<IElixirState, boolean | undefined>(x => {
    return engageHubSelector(x)?.context?.isInternalUser;
  });
};

export const useSelectUserContext = (): IUserContext | undefined => {
  return useSelector<IElixirState, IUserContext | undefined>(x => {
    return engageHubSelector(x)?.context?.token?.user;
  });
};

export const useDoesUserHaveAnyAccess = (): boolean => {
  return useSelector<IElixirState, boolean>(x => {
    const permissions = selectUserPermissions(x) ?? [];

    return (
      permissions.includes(CustomerClaims.CanManageBusinessReviews) ||
      permissions.includes(CustomerClaims.CanViewBusinessReviews)
    );
  });
};

export const useSelectWriteOperationEnabled = (): boolean => {
  return useSelector<IElixirState, boolean>(x => {
    return (
      engageHubSelector(x)?.context.token?.permissions?.includes(
        CustomerClaims.CanManageBusinessReviews,
      ) ?? true
    );
  });
};

export const useSelectMaskedUser = (): string | undefined => {
  return useSelector<IElixirState, string | undefined>(x => {
    return engageHubSelector(x)?.context?.isInternalUser
      ? MaskedUserValues.Microsoft
      : MaskedUserValues.Customer;
  });
};

export const useSelectContext = (): IAppContextState | undefined => {
  return useSelector<IElixirState, IAppContextState | undefined>(x => {
    return engageHubSelector(x)?.context;
  });
};
