import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { fetchAsync, useSelectCustomerId, useSelectUserContext } from '@engagehub/core';
import { useSelectIsInternalUser } from '@features/context';
import { useReviewId } from '@features/reviews';
import { constructCustomerScope } from '@utils/scope';
import { ICreateTaskFields } from '../form';
import { IWorkItemResponse } from '../api';
import { useAssignedToMaskedUserValue } from '../createTask/hooks';

export const useEditTask = (): UseMutationResult<
  IWorkItemResponse,
  unknown,
  ICreateTaskFields,
  unknown
> => {
  const customerId = useSelectCustomerId();
  const queryClient = useQueryClient();
  const isInternalUser = useSelectIsInternalUser();
  const userContext = useSelectUserContext();
  const reviewId = useReviewId();

  return useMutation(
    (values: ICreateTaskFields) =>
      fetchAsync<IWorkItemResponse>(`/br/processes/reviews/${reviewId}/work-items/${values.id}`, {
        method: 'PUT',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
        data: {
          workItemId: values.id,
          title: values.title,
          severity: values.severity,
          assignedTo: useAssignedToMaskedUserValue(
            values.assignedTo,
            isInternalUser,
            userContext?.upn,
          ),
          state: values.state,
          description: values.description,
        },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(['tasks']),
    },
  );
};
