import {
  FilterOption,
  FilterOptionPillMode,
  FilterProps,
  FilterSelection,
} from '@elixir/components';
import { clickTracking } from '@utils';
import { useEffect, useState } from 'react';
import { useReviewSelector } from '@features/reviews/store/hook';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getReviewLandingRoute } from '@utils/routing';
import { ReviewTags, useReviewId, useReviewTypeInfo } from '@features/reviews';
import { IWorkItemTypes } from '../api';
import { useTaskTypeFilter } from '../hooks';

const severity = 'Severity';
const status = 'Status';
const workItemType = 'WorkItemType';
const timeFrame = 'TimeFrame';
export interface IActionItemFilter {
  readonly reviewId?: string;
  readonly templateId?: string;
  readonly status?: string;
  readonly severity?: string;
  readonly workItemTypes?: string[];
}
export const defaultActionItemFilter: IActionItemFilter = {};

// eslint-disable-next-line max-lines-per-function
export const useActionItemTableFilter = (
  workItemTypes?: IWorkItemTypes[],
): { tableFilterProps: FilterProps; queryFilterValue: IActionItemFilter } => {
  const { t } = useTranslation();
  const review = useReviewSelector();
  const reviewId = useReviewId();
  const { pathname } = useLocation();
  const reviewInfo = useReviewTypeInfo();

  const evaluateShouldFilterByTemplate = (): boolean =>
    reviewInfo?.tags[ReviewTags.FilterWorkItemsByTemplate] === 'true';

  const evaluateShouldFilterByReview = (): boolean => {
    if (pathname !== getReviewLandingRoute(reviewId ?? '')) return false;
    if (!review) return false;

    return true;
  };

  const [actionItemTableFilter, setActionItemTableFilter] =
    useState<IActionItemFilter>(defaultActionItemFilter);

  useEffect(() => {
    const shouldFilterByTemplate = evaluateShouldFilterByTemplate();
    const filterByReview = !shouldFilterByTemplate && evaluateShouldFilterByReview();

    setActionItemTableFilter({
      reviewId: filterByReview ? review?.id : undefined,
      templateId: shouldFilterByTemplate ? review?.templateId : undefined,
    });
  }, [reviewInfo, pathname, review]);

  const [filterSelections, setFilterSelections] = useState<Array<FilterSelection>>([]);
  const { states: taskStates, severities: taskSeverity } = useTaskTypeFilter(
    workItemTypes,
    actionItemTableFilter.workItemTypes,
  );

  const handleFilterChange = (
    selections: Array<FilterSelection>,
    changedSelection: FilterSelection,
  ): void => {
    clickTracking(`businessreview-actionitemsfilter-${selections[0]?.field || 'clear'}`, 'Filter');
    setFilterSelections(selections);
    switch (changedSelection.field) {
      case timeFrame:
        setActionItemTableFilter({
          ...actionItemTableFilter,
          reviewId:
            changedSelection.values !== undefined
              ? (changedSelection.values as unknown as string[])[0]
              : undefined,
        });
        break;
      case workItemType:
        setActionItemTableFilter({
          ...actionItemTableFilter,
          workItemTypes:
            changedSelection.values !== undefined
              ? (changedSelection.values as unknown as string[])
              : undefined,
        });
        break;
      case severity:
        setActionItemTableFilter({
          ...actionItemTableFilter,
          severity:
            changedSelection.values !== undefined
              ? (changedSelection.values as unknown as string[])[0]
              : undefined,
        });
        break;
      case status:
        setActionItemTableFilter({
          ...actionItemTableFilter,
          status:
            changedSelection.values !== undefined
              ? (changedSelection.values as unknown as string[])[0]
              : undefined,
        });
        break;
    }
  };

  const actionItemFilters: FilterOption[] = [
    {
      pillMode: FilterOptionPillMode.Static,
      field: workItemType,
      label: t('ActionItems.Type'),
      values: workItemTypes?.map(types => ({ value: types.name, label: types.name })) ?? [],
      multiselect: true,
      search: false,
    },
    {
      pillMode: FilterOptionPillMode.Static,
      field: status,
      label: t('ActionItems.Status'),
      values: taskStates?.map(x => ({ value: x, label: x })) ?? [],
      multiselect: false,
      search: false,
    },
    {
      pillMode: FilterOptionPillMode.Static,
      field: severity,
      label: t('ActionItems.Severity'),
      values: taskSeverity?.map(x => ({ value: x, label: x })) ?? [],
      multiselect: false,
      search: false,
    },
  ];

  return {
    queryFilterValue: actionItemTableFilter,
    tableFilterProps: {
      selections: filterSelections,
      options: actionItemFilters,
      onChange: handleFilterChange,
    },
  };
};
