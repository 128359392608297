/* eslint-disable max-lines-per-function */
import React, { useContext, useEffect } from 'react';
import { EHForm, EHPanel, EHTextField } from '@components';
import * as yup from 'yup';
import { Stack } from '@fluentui/react';
import { AssignedToDropdown } from '@features/actionItems/form/assignedToDropdown';
import { ElxPrimaryButton, PanelSize } from '@elixir/components';
import {
  WorkItemTypeSelection,
  workItemTypeSelectionSchema,
} from '@features/actionItems/form/workItemTypeSelection';
import { TFunction } from 'i18next';
import { IWorkItemTypes } from '@features/actionItems';
import { useTaskTypes } from '@features/actionItems/hooks';
import { useTranslation } from 'react-i18next';
import { EHTextEditor } from '@components/EHForms/EHRichTextEditor';
import { IPreconfiguredActionItem } from '../hook';
import { ActionItemContext, IActionItemContext } from '../actionItems';

const initialValues: IPreconfiguredActionItem = {
  title: '',
  description: '',
  workItemSeverity: '',
  assignedTo: '',
  workItemType: '',
};

const schema = (t: TFunction, workItemTypes: IWorkItemTypes[]): yup.AnyObjectSchema =>
  yup
    .object()
    .shape({
      title: yup.string().required('Title is required'),
      assignedTo: yup.string().required('Assigned is required'),
    })
    .concat(workItemTypeSelectionSchema(t, workItemTypes));

export const ActionItemForm = (): JSX.Element => {
  const { items, setItems, editIndex, setEditIndex } =
    useContext<IActionItemContext>(ActionItemContext);
  const { data, isLoading } = useTaskTypes();
  const { t } = useTranslation();

  return (
    <>
      <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 16, padding: 24 }}>
        <ElxPrimaryButton
          onClick={(): void => setEditIndex(items.length)}
          text={'Create action item'}
        />
      </Stack>
      <EHForm
        initialValues={initialValues}
        onSubmit={(values: IPreconfiguredActionItem): void => {
          items[editIndex] = values;
          setItems([
            ...items.map(item => ({
              ...item,
              workItemSeverity:
                item.workItemSeverity || (item as unknown as { severity: string }).severity,
            })),
          ]);
          setEditIndex(-1);
        }}
        validationSchema={schema(t, data ?? [])}>
        {(formik): JSX.Element => {
          useEffect(() => {
            if (editIndex >= 0 && editIndex < items.length) {
              formik.setValues(items[editIndex]);
            } else if (editIndex == items.length) {
              formik.setValues({ ...initialValues });
            }
          }, [editIndex]);

          return (
            <EHPanel
              actions={[
                {
                  key: '1',
                  text: editIndex == items.length ? 'Create' : 'Update',
                  primary: true,
                  disabled: !formik.isValid,
                  onClick: (): void => formik.handleSubmit(),
                },
              ]}
              headerText={'Create or Update'}
              isOpen={editIndex >= 0}
              onDismiss={(): void => setEditIndex(-1)}
              size={PanelSize.medium}>
              <Stack tokens={{ childrenGap: 16, padding: 24 }}>
                <EHTextField
                  id="action-item-title-field"
                  label="Title"
                  name="title"
                  placeholder="Enter Title"
                  required
                />
                <EHTextEditor
                  id="action-item-description-field"
                  label="Description"
                  multiline={true}
                  name="description"
                  placeholder="Enter Description"
                />
                <WorkItemTypeSelection
                  formikContext={formik}
                  isLoading={isLoading}
                  isNew={true}
                  workItemTypes={data ?? []}
                />
                <AssignedToDropdown />
              </Stack>
            </EHPanel>
          );
        }}
      </EHForm>
    </>
  );
};
