/* eslint-disable react/no-multi-comp */
import { IModule } from '@elixir/fx';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { EHRoutes } from '@utils';
import { AdminPage } from '..';

export const AdminModule = (): IModule => {
  const { t } = useTranslation();

  return {
    name: 'Admin',
    env: 'development',
    route: {
      index: 'admin',
      name: t('Admin'),
      iconProps: {
        iconName: 'Admin',
      },
      path: EHRoutes.admin,
      isRouteable: true,
      exact: true,
      inSidebar: false,
      component: (): JSX.Element => <AdminPage />,
      subRoutes: [],
    },
  };
};
