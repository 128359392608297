import React from 'react';
import { EHContainer, IContainerContext, containerStyles } from '@components';
import { ActionItemsContext, useActionItemActions } from '@features';
import { DashboardViewEngineSwitch } from './viewEngineSwitch';

export interface IDashboardProps {
  containerCtx: IContainerContext;
}

export const Dashboard = ({ containerCtx }: IDashboardProps): JSX.Element => {
  const defaultSubActions = useActionItemActions();

  return (
    <ActionItemsContext.Provider value={containerCtx}>
      <EHContainer styles={containerStyles.subContainer} subActions={[...defaultSubActions]}>
        <DashboardViewEngineSwitch />
      </EHContainer>
    </ActionItemsContext.Provider>
  );
};
