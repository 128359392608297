import { ITheme, Icon, Label, Stack, mergeStyles, useTheme } from '@fluentui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const getLabelStyles = (theme: ITheme): string =>
  mergeStyles({ fontSize: theme.fonts.xLarge.fontSize });

const getIconStyles = (): string =>
  mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
  });

const styles = {
  stack: { margin: 'auto', marginTop: 30 },
};

export const NoActionItemsToDisplay = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack horizontalAlign="center" style={styles.stack} tokens={{ childrenGap: 30 }}>
      <Icon className={getIconStyles()} iconName="EditNote" />
      <Label className={getLabelStyles(theme)}>{t('ActionItems.NoActionItemsToDisplay')}</Label>
    </Stack>
  );
};
