import { LocationDescriptor, Path } from 'history';
import { useHistory } from 'react-router-dom';

export const usePush = (): ((
  pathName: string,
  queryParams?: Record<string, string>,
  retainQueryParams?: boolean,
) => void) => {
  const h = useHistory();

  return (
    pathName: string,
    queryParams?: Record<string, string>,
    retainQueryParams?: boolean,
  ): void => {
    let search = retainQueryParams ? h.location.search : '';
    if (queryParams) {
      const params = new URLSearchParams(search);
      Object.keys(queryParams).forEach(k => params.append(k, queryParams[k]));
      search = params.toString();
    }

    h.push({ pathname: pathName, search: search });
  };
};

export const useReplace = (): ((
  pathName: string,
  queryParams?: Record<string, string>,
  retainQueryParams?: boolean,
) => void) => {
  const h = useHistory();

  return (location: Path | LocationDescriptor<unknown>, state?: unknown) =>
    h.replace(location, state);
};
