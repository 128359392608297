import { IContainerAction, IMessage } from '@elixir/components';
import {
  IScope,
  useScope,
  uxAddContainerMessageAction,
  uxClearContainerMessageAction,
  uxSetContainerLoadingAction,
} from '@elixir/fx';
import { useMessaging } from '@utils/messaging';
import React, { Dispatch, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Text, useTheme } from '@fluentui/react';
import { getMessageStyles } from './styles';

export interface IContainerContext {
  scope: IScope;
  setActions: Dispatch<IContainerAction[]>;
  setLoading: (isLoading: boolean, name?: string) => void;
  setMessage: Dispatch<IMessage | undefined>;

  /*
   * Any scope can be passed in.
   * By default will use container scope name.
   * Error message will only be cleared when all errors are not enabled.
   */
  setStandardError: (isError: boolean, name?: string) => void;
}

export interface IUseContainerScope extends IContainerContext {
  actions: IContainerAction[];
}

const handleStateChange = (
  isEnabled: boolean,
  state: Record<string, boolean>,
  key: string,
  onEnable: () => void,
  onDisable: () => void,
): void => {
  const currentValue = state[key];

  if (!!currentValue == !!isEnabled) return;

  state[key] = isEnabled;

  if (isEnabled) onEnable();

  if (!isEnabled && !Object.keys(state).find(key => !!state[key])) onDisable();
};

export const useContainerScope = (): IUseContainerScope => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { standardErrorMessage } = useMessaging();
  const containerScope = useScope();
  const [errors] = useState<Record<string, boolean>>({});
  const [actions, setActions] = useState<IContainerAction[]>([]);
  const [loading] = useState<Record<string, boolean>>({});

  const clearContainerMessage = (): void => {
    dispatch(uxClearContainerMessageAction(containerScope));
  };

  const setContainerMessage = (message: IMessage): void => {
    const accessibleMessage = (
      <span aria-live="assertive" role="alert">
        <Text className={getMessageStyles(theme).message}>{message.message}</Text>
      </span>
    );

    dispatch(uxClearContainerMessageAction(containerScope));
    dispatch(
      uxAddContainerMessageAction(
        { ...message, message: message ? accessibleMessage : '' },
        containerScope,
      ),
    );
  };

  const setStandardError = (isEnabled: boolean, name?: string): void => {
    const key = name ?? containerScope.name;
    const onEnable = (): void => setContainerMessage(standardErrorMessage);
    const onDisable = (): void => clearContainerMessage();
    handleStateChange(isEnabled, errors, key, onEnable, onDisable);
  };

  const setLoading = (isEnabled: boolean, name?: string): void => {
    const key = name ?? containerScope.name;

    const onEnable = (): void => {
      dispatch(uxSetContainerLoadingAction(true, '', containerScope));
    };
    const onDisable = (): void => {
      dispatch(uxSetContainerLoadingAction(false, '', containerScope));
    };
    handleStateChange(isEnabled, loading, key, onEnable, onDisable);
  };

  return {
    scope: containerScope,
    setStandardError,
    setActions,
    actions,
    setLoading,
    setMessage: message => (message ? setContainerMessage(message) : clearContainerMessage()),
  };
};
