import React from 'react';
import { Stack, Text } from '@fluentui/react';
import { useWorkloadOptions } from '@features/reviews/form/workload/hooks';
import { IDataParamValue } from '@features/reviews/types';

interface IReviewWorkload {
  readonly reviewDataParameters?: Record<string, IDataParamValue>;
}

export const ShowWorkloadColumn = ({ reviewDataParameters }: IReviewWorkload): JSX.Element => {
  const { data: workloadData } = useWorkloadOptions(true);
  const text =
    !!reviewDataParameters?.WorkloadId &&
    !!workloadData?.find(i => i.id === reviewDataParameters.WorkloadId)
      ? workloadData?.find(i => i.id === reviewDataParameters?.WorkloadId)?.name
      : 'N/A';
  return (
    <Stack>
      <Text variant="medium">{text}</Text>
    </Stack>
  );
};
