import { Stack } from '@fluentui/react';
import React from 'react';
import { TaskList } from './component';

const listStyles = { height: '100%' };

export const TaskListContainer = (): JSX.Element => {
  return (
    <Stack style={listStyles}>
      <TaskList />
    </Stack>
  );
};
