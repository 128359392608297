import React, { useContext } from 'react';
import { FieldHelperProps, FieldMetaProps } from 'formik';
import {
  ElxDateTimePicker,
  IElxDateTimePickerProps,
  IElxDateTimePickerStyles,
  InputMessageTypes,
} from '@elixir/components';
import { EHFieldWrapper } from '../EHFieldWrapper';
import { FormContext, IFormContext, IName } from '../EHForm';

type IEHDateTimePicker = IElxDateTimePickerProps & IName;

interface IEHDateTimePickerProps extends IElxDateTimePickerProps {
  readonly meta: FieldMetaProps<unknown>;
  readonly helpers: FieldHelperProps<unknown>;
  readonly value: string;
}

const styles: Partial<IElxDateTimePickerStyles> = {
  calendarIcon: {
    fontSize: '13px',
  },
  textFieldContainer: {
    lineHeight: '16px',
  },
};

export const EHDateTimePicker = ({ name, message, ...props }: IEHDateTimePicker): JSX.Element => {
  const { fieldsConfiguration } = useContext<IFormContext>(FormContext);

  return (
    <EHFieldWrapper name={name}>
      {({ meta, helpers, ...field }: IEHDateTimePickerProps): JSX.Element => {
        return (
          <ElxDateTimePicker
            {...props}
            {...field}
            message={
              message ||
              (meta.error && { content: meta.error as string, type: InputMessageTypes.Error }) ||
              undefined
            }
            onChange={(dateTime?: string): void => {
              dateTime && helpers.setValue(dateTime);
            }}
            styles={styles}
            {...fieldsConfiguration?.[name]}
          />
        );
      }}
    </EHFieldWrapper>
  );
};
