import React from 'react';
import { ElxDropdown, IElxDropdownProps } from '@elixir/components';
import { IDropdownOption } from '@fluentui/react';
import { FieldHelperProps, FieldMetaProps } from 'formik';
import { EHFieldShimmer } from '../EHFieldShimmer';
import { EHFieldWrapper } from '../EHFieldWrapper';
import { FormContext, IFormContext, ILoading, IName } from '../EHForm';

type IEHFieldDropdown = IElxDropdownProps & IName & ILoading;

interface ISHFieldDropdownProps extends IElxDropdownProps {
  readonly meta: FieldMetaProps<unknown>;
  readonly helpers: FieldHelperProps<unknown>;
  readonly value: string;
}

export const EHFieldDropdown = ({ name, isLoading, ...props }: IEHFieldDropdown): JSX.Element => {
  const { fieldsConfiguration } = React.useContext<IFormContext>(FormContext);
  return (
    <EHFieldWrapper name={name}>
      {({ meta, helpers, ...field }: ISHFieldDropdownProps): JSX.Element => {
        return (
          (isLoading && <EHFieldShimmer />) || (
            <ElxDropdown
              {...props}
              {...field}
              errorMessage={meta.error}
              onChange={(_, option?: IDropdownOption): void => {
                /* istanbul ignore next */
                helpers.setValue(option?.key || '');
              }}
              selectedKey={meta.value}
              {...fieldsConfiguration?.[name]}
            />
          )
        );
      }}
    </EHFieldWrapper>
  );
};
