import { QueryClient } from 'react-query';

export const getReviewsQueryKey = (customerId?: string): (string | undefined)[] => [
  'reviews',
  customerId,
];

export const getReviewFiltersQueryKey = (customerId?: string): (string | undefined)[] => [
  'reviewfilters',
  customerId,
];

export const invalidateReviewQueries = (queryClient: QueryClient, customerId?: string): void => {
  queryClient.invalidateQueries(getReviewsQueryKey(customerId));
  queryClient.invalidateQueries(getReviewFiltersQueryKey(customerId));
};
