import { mergeStyles } from '@fluentui/react';
import { getResponsiveBreakPointsStyle } from '@utils/styling';

export const containerStyles = mergeStyles(
  getResponsiveBreakPointsStyle({
    minWidthSmall: {
      width: '100%',
    },
    minWidthLarge: {
      width: '750px',
    },
    minWidthXLarge: {
      width: '970px',
    },
    minWidthXXLarge: {
      width: '85%',
    },
  }),
  {
    margin: '0 auto',
    maxWidth: '1630px',
    paddingLeft: '15px',
    paddingRight: '15px',
    '& .elx-container': {
      position: 'unset',
    },
    '*, *::before, *::after': {
      boxSizing: 'unset',
    },
  },
);
