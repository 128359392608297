import { DetailsContext, IDetailsContext } from '@components/EHDetails';
import { fetchAsync, useSelectCustomerId } from '@engagehub/core';
import { IComment, ICreateCommentRequest } from '@features';
import { IPagedResult } from '@utils';
import { constructCustomerScope } from '@utils/scope';
import { useContext, useEffect } from 'react';
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query';

export const useGetWorkItemComments = (
  workItemId: string | undefined,
  reviewId: string | undefined,
): {
  data: IComment[] | undefined;
  isLoading: boolean;
} => {
  const customerId = useSelectCustomerId();
  const { setError, setLoading } = useContext<IDetailsContext>(DetailsContext);

  const { isFetching, isError, data } = useQuery(
    ['workItemComments', { workItemId }],
    () =>
      fetchAsync<IPagedResult<IComment>>(
        `/br/processes/reviews/${reviewId}/work-items/${workItemId}/comments`,
        {
          method: 'GET',
          headers: {
            'x-eh-scope': constructCustomerScope(customerId!),
          },
          params: {
            customerId,
          },
        },
      ).then(resp => resp.values),
    {
      enabled: !!workItemId,
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    setLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    setError(isError);
  }, [isError]);

  return { data, isLoading: isFetching };
};

export const useCreateWorkItemComments = (
  workItemId: string | undefined,
  reviewId: string | undefined,
): UseMutateFunction<
  void,
  unknown,
  { request: ICreateCommentRequest; resetForm: VoidFunction },
  unknown
> => {
  const customerId = useSelectCustomerId();
  const { setError, setLoading } = useContext<IDetailsContext>(DetailsContext);
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation(
    ({ request, resetForm }: { request: ICreateCommentRequest; resetForm: VoidFunction }) =>
      fetchAsync<void>(`/br/processes/reviews/${reviewId}/work-items/${workItemId}/comments`, {
        method: 'POST',
        headers: {
          'x-eh-scope': constructCustomerScope(customerId!),
        },
        params: {
          customerId,
        },
        data: {
          createdBy: request.createdBy,
          content: request.content,
        },
      }).then(() => resetForm()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['workItemComments']);
        queryClient.invalidateQueries(['taskActivities', { workItemId }]);
        queryClient.invalidateQueries(['taskDetailsCounts', { workItemId }]);
      },
    },
  );

  useEffect(() => {
    setLoading?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setError?.(isError);
  }, [isError]);

  return mutate;
};
